const state = {
    news: 0,
    list: 0
}
const mutations = {
    toggleSlick(state, data) {
        switch (data.payload) {
            case 'left':
                state[data.name] > 0 ? state[data.name] -= 1 : '';
                break;
            case 'right':
                state[data.name] + 1 !== data.items ? state[data.name] += 1 : ''
                break;
            default:
                state[data.name] = data.payload;
                break;
        }
    },
    resetSlicker(state, data) {
        state[data] = 0;
    }
}
const actions = {
    toggleSlicker({ commit }, data) {
        commit('toggleSlick', data)
    },
    resetSlicker({ commit }, data) {
        commit('resetSlicker', data)
    }
}
const getters = {
    slickers: (state) => {
        return {
            news: state.news,
            list: state.list
        }
    }
}


export default {
    state,
    mutations,
    actions,
    getters
}