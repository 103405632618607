<template>
    <div class="step">
        <div class="step-body">
            <div class="step-body_item">
                <div class="step-body_item_inner" :class="{'step-body_item_inner_auth': $store.getters.isAuth}">
                    <component :is="'Step' + index"></component>
                </div>
            </div>
            <p v-html="$content(`landing_step${ index }`)"></p>
        </div>
        <div class="step-arrow" v-if="!isLast">
            <b-icon icon="chevron-right"></b-icon>
        </div>
    </div>
</template>

<script>
import Step1 from "@/components/SVG/Step1";
import Step2 from "@/components/SVG/Step2";
import Step3 from "@/components/SVG/Step3";
import Step4 from "@/components/SVG/Step4";
export default {
    name: "Step",
    components: {Step4, Step3, Step2, Step1},
    props: ['index', 'isLast', 'color']
}
</script>

<style lang="scss" scoped>
    @import "../styles/mixins";

    .step {
        display: flex;

        @include only-xs {
            flex-direction: column;
        }

        &-arrow {
            margin: 55px 30px 0 30px;
            font-size: 30px;
            color: var(--main-app);

            @include only-sm {
                margin: 45px 15px 0 15px;
            }

            & svg {
                color: var(--main-titles);

                @include only-xs {
                    transform: rotate(90deg);
                }
            }

            @include only-xs {
                display: flex;
                justify-content: center;
                margin: 30px 0;
            }
        }

        &-body {

            & p {
                margin-top: 40px;
                font-size: 16px;
                font-family: Montserrat-Medium, sans-serif;
                color: var(--main-dark-paper);
                text-align: center;
            }

            &_item {
                height: 160px;
                border-radius: 200px;
                width: 160px;
                background: var(--main-light-silver);
                display: flex;
                align-items: center;
                justify-content: center;

                @include only-sm {
                    height: 130px;
                    width: 130px;
                }

                &_inner {
                    height: 115px;
                    border-radius: 200px;
                    width: 115px;
                    border: 1px solid silver;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include only-sm {
                       height: 100px;
                        width: 100px;
                    }

                    &_auth {

                        & svg {
                            fill: var(--main-light-green) !important;
                        }
                    }

                    & svg {
                        height: 38px;
                        fill: var(--main-titles);
                    }
                }
            }
        }
    }
</style>