<template>
    <div class="page-footer">
        <div class="page-footer_partial">
            <div>
                <img :src="this.imageSelector($store.getters.siteSettings.logo_url)">
            </div>
        </div>
        <div class="page-footer_links">
            <router-link :to="item.url" :key="`footer-link-${item.title}`" v-for="item in $utils.getMenu('footer_menu')">
                <span>{{ item.title }}</span>
            </router-link>
        </div>
        <div class="page-footer_partial2">
            <span v-html="$content('footer_rights')"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss" scoped>
    @import "../styles/variables";
    @import "../styles/mixins";

    .page-footer {
        height: $standartHeaderHeight;
        width: 100%;
        background: #fffffff0;
        bottom: 0;
        display: flex;
        font-family: Montserrat-Medium, sans-serif;
        padding: 0 50px;
        border-top: 1px solid #efefef;
        font-weight: bold;

        @include only-md {
            padding: 20px 25px;
            flex-direction: column;
            align-items: center;
        }

        @include only-sm {
            padding: 20px 25px;
            flex-direction: column;
            align-items: center;
        }

        @include only-xs {
            flex-direction: column;
            height: unset;
            padding: 25px 15px;
        }

        &_fixed {
            position: fixed;

            @include only-xs {
                position: static;
            }
        }

        &_links {
            flex: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            @include only-md {
                order: 1;
                width: 100%;
                justify-content: space-around;
            }

            @include only-sm {
                order: 1;
                width: 100%;
                justify-content: space-around;
            }

            @include only-xs {
                flex-direction: column;
                flex: unset;
            }

            & a {
                color: var(--main-dark);
                font-size: 15px;
                margin: 0 15px;
                text-transform: uppercase;

                @include only-xs {
                    font-size: 14px !important;
                    margin: 0 0 10px 0;
                }
            }
        }

        &_partial {
            width: $headerPartialMain;
            display: flex;
            align-items: center;

            @include only-lg-down {
                display: none;
            }

            & img {
                height: 30px;
            }
        }

        &_partial2 {
            width: $headerPartialMain;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-family: Montserrat-Light, sans-serif;
            font-size: 11px;
            color: var(--main-dark-paper);

            @include only-lg-down {
                order: 3;
                justify-content: center;
            }

            @include only-xs {
                font-size: 9px;
                width: 100%;
            }
        }
    }
</style>