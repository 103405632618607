<template>
    <Page style="background: #f3f3f3">
        <div class="articles-page" @click="getArticle">

            <div class="h-100">
                <FullVwContainer class="h-100" :first="true">
                    <div class="rules">

                        <div ref="text" class="rules-text" v-show="article.title && article.body">
                            <h1 class="rules-text_title" v-html="article.title"></h1>
                            {{ article.body }}
                        </div>

                    </div>
                </FullVwContainer>
            </div>


        </div>
        <Footer class="hide-on-xs" />
    </Page>
</template>

<script>
import Page from "../../components/Page";
import FullVwContainer from "../../components/Wrappers/FullVwContainer";
import Footer from "../../components/Footer";
export default {
    name: "Rules",
    components: {Footer, FullVwContainer, Page},
    data() {
        return {
            article: {
                title: '',
                body: ''
            }
        }
    },
    methods: {
        getArticle() {
            this.$http('content').getArticle(this.$route.params.name)
                .then(res => {
                    if (this.$payloadHandler(res).body && this.$payloadHandler(res).title) {
                        this.article.title = this.$payloadHandler(res)['title'];
                        this.article.body = this.$payloadHandler(res)['body'];
                        this.$refs.text.scrollTop = 0;
                    } else {
                        this.$router.push('/')
                    }
                })
        }
    },
    watch: {
        '$route.path'() {
            this.getArticle();
        }
    },
    created() {
        this.getArticle();
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";
    @import "../../styles/variables";

    .articles-page {
        height: calc(100% - #{$standartHeaderHeight});
    }

    .rules {
        font-family: Montserrat-Medium, sans-serif;
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding: 50px 0;

        @include only-xs {
            flex-direction: column;
            height: unset;
            padding: unset;
        }

        &-text {
            overflow-y: auto;
            padding-right: 60px;
            font-family: Montserrat-Light, sans-serif;
            font-size: 16px;
            font-weight: bold;
            width: 100%;

            @include only-xs {
                padding: 20px;
            }

            &::-webkit-scrollbar {
                width: 7px;
                height: 9px;
            }

            &::-webkit-scrollbar-thumb {
                background: #e4e4e4;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: silver;
            }

            &::-webkit-scrollbar-track {
                background: #efefef;
                border-radius: 10px;
                box-shadow: none;
            }

            &_title {
                font-family: Montserrat-Medium, sans-serif;
                font-size: 28px;
                color: var(--main-dark);
                font-weight: bold;
                margin-bottom: 25px;
            }
        }

        &-list {
            width: 350px;
            background: white;
            border-radius: 10px;
            overflow: hidden;
            height: 500px;
            position: relative;

            &_dd {
                width: 100%;
                height: 80px;
                background: var(--main-app);
                position: fixed;
                align-items: center;
                padding: 0 15px;
                justify-content: space-between;
                font-family: Montserrat-Medium, sans-serif;
                color: white;
                display: none;

                @include only-xs {
                    display: flex;
                }

                &_head {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }

                & h4 {
                    font-size: 19px;
                    margin-bottom: 0;
                }

                &_body {
                    display: none;
                }
            }

            @include only-xs {
                display: none;
            }

            @include only-lg {
                width: 300px;
            }

            @include only-lg-down {
                width: 300px;
            }

            @include only-xs {
                width: 100%;
            }

            &_item {
                font-size: 18px;
                height: 67px;
                padding-left: 30px;
                color: var(--main-dark-paper);
                display: flex;
                align-items: center;
                cursor: pointer;

                @include only-lg-down {
                    font-size: 16px;
                    padding: 0 10px;
                }

                &:hover {
                    background: var(--main-app);
                    color: white;
                }
            }
        }
    }
</style>