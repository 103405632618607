<template>
    <div class="pagination-dots">
        <b-icon
            @click="$emit('dotClicked', index)"
            :class="{'active-dot': active === index}"
            v-for="(dot, index) in items"
            :key="`dot_${dot}`"
            icon="circle-fill" />
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: ['items', 'active']
}
</script>

<style lang="scss" scoped>
    .active-dot {
        color: #fff !important;
    }
    .pagination-dots {
        display: flex;

        & svg {
            height: 6px;
            color: rgba(255,255,255, 0.4);
            width: 6px;
            margin-left: 4px;
            cursor: pointer;
        }
    }
</style>