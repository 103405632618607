<template>
    <div class="exchange">

        <div class="exchange-leverage" v-if="leverageDisplay">
            <ExchangeLeverage @close="toggleLeverage" :id="exchange.id" :amount="amount" />
        </div>


        <div v-touch:swipe="swipeHandler" class="exchange-header exchange-partial">

            <div class="exchange-header_available" v-if="type === 'available'">
                <div class="exchange-header_available_time">
                    <h1 v-html="$content('platform_process_timer_title1')"></h1>
                    <p v-html="$content('platform_process_timer_title2') + ' ' + $store.getters.ex_update"></p>
                </div>
                <ExchangeLeverageButton :exchange="exchange" @clicked="toggleLeverage" />
            </div>

            <div class="exchange-header_expired" v-if="type === 'expired'">
                <div class="exchange-header_summary">
                    <h1>{{ $content('platform_process_timer_title2') }} {{ $store.getters.ex_update }}</h1>

                    <div class="exchange-header_summary_body">
                        <span>{{ exchange.time.status ? $content('ex-will-finish') : $content('ex-finished') }} {{ finished_at }}</span>
                        <span v-if="exchange.time.status">{{ getDurationString(exchange.time.duration) }}</span>
                        <span v-if="!exchange.time.status">{{ getDurationString(exchange.time.duration) + getActualDuration(exchange.time.real_duration) }}</span>
                    </div>
                </div>
            </div>

            <div class="exchange-header_fail" v-if="type === 'fail'">
                <div class="exchange-header_summary">
                    <h1>{{ $content('platform_process_timer_title2') }} {{ update_at }}</h1>
                    <div class="exchange-header_summary_body">
                        <span>{{ $content('ex-finished') }} {{ finished_at }}</span>
                        <span>{{ getDurationString(exchange.time.duration) }}</span>
                    </div>
                </div>
            </div>

            <div class="exchange-header_processing" v-if="type === 'processing'">
                <div class="exchange-header_summary">
                    <h1>{{ $content('platform_process_timer_title2') }} {{ update_at }}</h1>
                    <div class="exchange-header_summary_body">
                      <span></span>
<!--                        <span>{{ $content('ex-will-finish') }} {{ finished_at }}</span>-->
                        <span>{{ getDurationString(exchange.time.duration) }}</span>
                    </div>
                </div>
            </div>

            <div class="exchange-header_processing" v-if="type === 'success'">
                <div class="exchange-header_summary">
                    <h1>{{ $content('platform_process_timer_title2') }} {{ update_at }}</h1>
                    <div class="exchange-header_summary_body">
                        <span>{{ $content('ex-finished') }} {{ finished_at }}</span>
                        <span>{{ getDurationString(exchange.time.duration) + getActualDuration(exchange.time.real_duration) }}</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="exchange-chain">
            <div class="exchange-chain_list">
                <div class="exchange-chain_list_wrapper">
                    <div class="exchange-chain_list_container">
                        <ExchangeChainPair
                            :type="type"
                            :index="i"
                            :chain="chain"
                            :depositChanged="customDeposit"
                            v-for="(pair, i) in chain" :pair="pair"></ExchangeChainPair>
                    </div>
                </div>
            </div>
        </div>
        <div v-touch:swipe="swipeHandler" class="exchange-footer exchange-partial">

            <div class="exchange-footer_available" v-if="type === 'available'">

                <div class="exchange-input">
                    <div class="exchange-input_label">
                        <img :src="this.imageSelector(chain[0].ps_from.logo_url)" :alt="chain[0].ps_from.alt" />
                    </div>
                    <div class="exchange-input_enter">
                        <p v-html="$content('ex-amount_title')"></p>
                        <input :class="{'exchange-input_errored': errors && errors.amount}" v-model="amount" :placeholder="$content('ex-amount_placeholder')" />
                    </div>
                    <div class="exchange-input_errors" v-if="errors && errors.amount">
                        <p v-for="item in errors.amount" :key="item">{{ item }}</p>
                    </div>
                </div>

                <div class="exchange-input">
                    <div class="exchange-input_label">
                        <img
                            :src="this.imageSelector(customDeposit ? chain[chain.length - 1].ps_to.logo_url : chain[chain.length - 2].ps_to.logo_url)"
                            :alt="customDeposit ? chain[chain.length - 1].ps_to.alt : chain[chain.length - 2].ps_to.alt" />
                    </div>
                    <div class="exchange-input_enter">
                        <p v-html="$content('ex-recieve_title')"></p>
                        <input readonly v-model="amount_potential" :placeholder="$content('ex-amount_placeholder')" />
                    </div>
                </div>


                <button
                    @click="invest"
                    class="exchange-submit"
                    :class="{'exchange-submit_blocked': $store.getters.ex_process}"
                    :disabled="$store.getters.ex_process">
                    <img src="/assets/img/reload.png" alt="submit">
<!--                    <span v-html="$content('ex-start')"></span>-->
                </button>
            </div>

            <div class="exchange-footer_available" v-if="type === 'processing'">
                <div class="exchange-input">
                    <div class="exchange-input_label">
                        <img :src="this.imageSelector(chain[0].ps_from.logo_url)" :alt="chain[0].ps_from.alt" />
                    </div>
                    <div class="exchange-input_enter">
                        <p v-html="$content('ex-amount_title')"></p>
                        <input :value="exchange.payouts.initial" readonly />
                    </div>
                    <div class="exchange-input_errors" v-if="errors && errors.amount">
                        <p v-for="item in errors.amount" :key="item">{{ item }}</p>
                    </div>
                </div>
                <div class="exchange-input">
                    <div class="exchange-input_label">
                        <img :src="this.imageSelector(chain[chain.length - 1].ps_to.logo_url)" :alt="chain[chain.length - 1].ps_to.alt" />
                    </div>
                    <div class="exchange-input_enter">
                        <p v-html="$content('ex-recieve_title')"></p>
                        <input readonly :value="exchange.payouts.potential" />
                    </div>
                </div>
            </div>

            <div class="exchange-footer_result exchange-footer_expired" v-if="type === 'expired'">

                <div class="exchange-footer_result_ps">
                    <div class="exchange-footer_result_ps_label">
                        <img :src="this.imageSelector(exchange.chain[0].ps_from.logo_url)" :alt="exchange.chain[0].ps_from.alt" />
                    </div>
                    <div class="exchange-footer_result_ps_label">
                        <img :src="this.imageSelector(exchange.chain[exchange.chain.length - 1].ps_to.logo_url)" />
                    </div>
                    <div class="exchange-footer_result_ps_summary">
                        <p>{{ $content(`probably-${exchange.state}_title`) }}</p>
                        <h3>{{ result }}</h3>
                    </div>
                </div>

                <div class="exchange-footer_result_leverage">
                    <h3>{{ $content('leverage-payout') }}</h3>
                    <p>${{ exchange.payouts.potential_leverage }}</p>
                </div>

            </div>

            <div class="exchange-footer_result exchange-footer_expired" v-if="type === 'success'">

                <div class="exchange-footer_result_ps">
                    <div class="exchange-footer_result_ps_label">
                        <img :src="this.imageSelector(exchange.chain[0].ps_from.logo_url)" :alt="exchange.chain[0].ps_from.alt" />
                    </div>
                    <div class="exchange-footer_result_ps_label">
                        <img :src="this.imageSelector(exchange.chain[exchange.chain.length - 1].ps_to.logo_url)" />
                    </div>
                    <div class="exchange-footer_result_ps_summary">
                        <p>{{ $content(`probably-${exchange.state}_title`) }}</p>
                        <h3>{{ result }}</h3>
                    </div>
                </div>

                <div class="exchange-footer_result_leverage" v-if="exchange.payouts.potential_leverage">
                    <h3>{{ $content('leverage-payout') }}</h3>
                    <p>${{ exchange.payouts.potential_leverage }}</p>
                </div>

            </div>

            <div class="exchange-footer_result exchange-footer_fail" v-if="type === 'fail'">
                <div class="exchange-footer_result_ps">
                    <div class="exchange-footer_result_ps_label">
                        <img :src="this.imageSelector(exchange.chain[0].ps_from.logo_url)" :alt="exchange.chain[0].ps_from.alt" />
                    </div>
                    <div class="exchange-footer_result_ps_label">
                        <img :src="this.imageSelector(exchange.chain[exchange.chain.length - 1].ps_to.logo_url)" />
                    </div>
                    <div class="exchange-footer_result_ps_summary">
                        <p>{{ $content(`probably-${exchange.state}_title`) }}</p>
                        <h3>{{ result }}</h3>
                    </div>
                </div>
                <div class="exchange-footer_result_error">
                    <p v-html="$content('ex-fail-status')"></p>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import ExchangeLeverageButton from "./ExchangeLeverageButton";
import ExchangeChainPair from "./ExchangeChainPair";
import DealFormInput from "../Deals/DealFormInput";
import moment from "moment";
import ExchangeLeverage from "./ExchangeLeverage";
export default {
    name: "Exchange",
    components: {ExchangeLeverage, DealFormInput, ExchangeChainPair, ExchangeLeverageButton},
    props: ['exchange'],
    data() {
        return {
            amount: '',
            amount_potential: '',
            errors: null,
            leverageDisplay: false
        }
    },
    methods: {
        swipeHandler(e) {
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            if (vw < 1190) {
                this.leverageDisplay = false;
                switch (e) {
                    case 'left':
                        this.$emit('swipe', 'right');
                        break;
                    case 'right':
                        this.$emit('swipe', 'left');
                        break;
                }
            }
        },
        invest() {
            this.$store.dispatch('exchanges.invest', {
                amount: this.amount,
                chain_id: this.exchange.id
            })
        },
        toggleLeverage() {
            this.leverageDisplay = !this.leverageDisplay
        },
        getDurationString(duration) {
            return `${ this.$content('for-deal') } - ${ moment.utc(duration*1000).format('HH:mm:ss')} `
        },
        getActualDuration(duration) {
            return `(${ this.$content('needed') } - ${ moment.utc(duration*1000).format('HH:mm:ss') } )`
        },
        calc() {
            if (this.amount) {

                this.$store.dispatch('exchanges.calc', {chain_id: this.exchange.id, amount: this.amount})
                    .then(payload => {
                        switch (payload.status) {
                            case 'success':
                                this.errors = null;
                                this.amount_potential = this.amount ? payload.result.amount_potential : ''
                                break;
                            case 'error':
                                this.errors = this.amount ? payload.errors : ''
                                break;
                        }
                    })
            } else {
                this.errors = null;
                this.amount_potential = '';
            }
        }
    },
    computed: {
        customDeposit() {
            return this.amount < this.$store.getters.wallet.balances.deposit;
        },
        chain() {
            return this.exchange.chain
        },
        type() {
            return this.exchange.state
        },
        finished_at() {
            return moment.parseZone(this.exchange.time.end_time).local().format('HH:mm:ss')
        },
        update_at() {
            return this.$store.getters.ex_update
        },
        result() {
            switch (this.exchange.state) {
                case 'success':
                    return `$${this.exchange.payouts.final}`
                case 'expired':
                    return `$${this.exchange.payouts.potential}`
                case 'fail':
                    return `${this.exchange.payouts.lost}$`
            }
        }
    },
    created() {
        if (this.exchange.state === 'available') {
            this.amount = this.$store.getters.wallet.balances.deposit;
            this.$root.$on('dealsListToggle', () => {
                this.leverageDisplay = false;
            })
        }
    },
    watch: {
        amount() {
            this.leverageDisplay = false;
            this.calc();
        }
    }
}
</script>

<style lang="scss">
    @import "../../styles/mixins";
    @import "../../styles/z-indexes";

    .exchange {
        padding-top: 43px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        @include only-minimal-exchanges {
            padding-top: 10px;
        }

        &-leverage {
            width: 368px;
            position: absolute;
            right: 10px;
            z-index: 100;

            @include only-xs-exchanges {
                width: calc(100% - 10px);
                right: 0;
                left: 5px;
                max-width: 500px;
            }
        }

        &-input {
            width: 215px;
            height: 67px;
            background: white;
            border-radius: 200px;
            margin-right: 7px;
            display: flex;
            align-items: center;
            padding: 12px;
            position: relative;

            @include only-xs-exchanges {
                min-width: 115px;
                width: auto;
                height: unset;
                padding: 8px;
                margin-right: 2px;
            }

            &_errored {
                background: rgba(255,0,0,0.25);
            }

            &_errors {
                position: absolute;
                top: calc(100% + 5px);

                & p {
                    text-align: center;
                    font-family: Montserrat-Medium, sans-serif;
                    color: red;
                    font-size: 14px;
                    margin-bottom: 0;

                    @include only-xs-exchanges {
                        font-size: 10px;
                    }
                }
            }

            &_enter {
                font-family: Montserrat-Light, sans-serif;
                margin-left: 15px;

                @include only-xs {
                    margin-left: 5px;
                }

                & p {
                    margin-bottom: 0px;
                    font-size: 12px;
                    color: var(--main-dark-paper);

                    @include only-xs {
                        font-size: 10px;
                        line-height: 1;
                    }
                }

                & input {
                    width: 120px;
                    height: 25px;
                    font-size: 18px;
                    font-weight: bold;
                    border: none;
                    outline: none;
                    padding: 0 0 0 2px;
                    transition: 0.3s;

                    @include only-xs-exchanges {
                        width: calc(100% - 5px);
                        font-size: 15px;
                    }

                }
            }

            &_label {
                height: 50px;
                width: 50px;
                background: #f0f0f0;
                border-radius: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px;
                flex-shrink: 0;

                @include only-xs {
                    width: 35px;
                    height: 35px;
                }

                & img {
                    height: auto;
                    width: 100%;
                }
            }
        }

        &-submit {
            background: #26cd1f;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-family: Montserrat-Light, sans-serif;
            color: white;
            font-weight: bold;
            cursor: pointer;
            border-radius: 200px;
            border: none;
            transition: 0.3s;
            text-transform: uppercase;
            margin-left: 5px;
            padding: 0 15px;

            &_blocked {
                cursor: not-allowed;
                opacity: 0.3;
            }

            @include only-xs-exchanges {
                min-width: unset;
                width: 49px;
                height: 49px;
                margin: 0;
            }

            & img {

                @include only-xs-exchanges {
                    margin: 0;
                    height: 20px;
                }
            }

            &:hover {
                background: #0aae11;
            }
        }

        &-footer {

            @include only-xs-exchanges {
                margin-top: 25px;
                padding-bottom: 50px;
            }

            &_result {
                height: 67px;
                width: 100%;
                border-radius: 200px;
                display: flex;

                &_leverage {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;

                    @include only-xs-exchanges {
                        min-width: 130px;
                        width: 100%;
                        flex-direction: column;
                        padding: 0 5px;
                    }

                    & h3 {
                        margin-bottom: 0;
                        line-height: 1;
                        width: 250px;
                        font-family: Montserrat-Light, sans-serif;
                        font-size: 12px;
                        color: white;
                        text-align: right;
                        margin-right: 15px;

                        @include only-xs-exchanges {
                            width: auto;
                            margin-right: 5px;
                            font-size: 10px;
                            text-align: center;
                        }
                    }

                    & p {
                        font-family: Montserrat-Regular, sans-serif;
                        font-size: 20px;
                        margin-bottom: 0;
                        font-weight: bold;
                        color: white;
                        text-transform: uppercase;
                        text-align: center;
                        display: block;

                        @include only-xs-exchanges {
                            font-size: 15px;
                        }
                    }
                }

                &_error {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    & p {
                        font-family: Montserrat-Regular, sans-serif;
                        font-size: 16px;
                        margin-bottom: 0;
                        color: white;
                    }

                }

                &_ps {
                    width: 40%;
                    border-radius: 200px;
                    height: 100%;
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;

                    @include only-xs-exchanges {
                        flex: auto;
                        width: unset;
                    }

                    &_summary {
                        margin-left: 10px;

                        @include only-xs-exchanges {
                            margin-left: 5px;
                        }

                        & p {
                            font-family: Montserrat-Light, sans-serif;
                            font-size: 13px;
                            margin-bottom: 0;
                            color: white;

                            @include only-xs {
                                font-size: 10px;
                                line-height: 1;
                                margin-bottom: 2px;
                            }
                        }

                        & h3 {
                            font-family: Montserrat-Regular, sans-serif;
                            font-size: 18px;
                            margin-bottom: 0;
                            color: white;
                            font-weight: bold;

                            @include only-xs {
                                font-size: 14px;
                            }
                        }
                    }

                    &_label {
                        height: 50px;
                        width: 50px;
                        border-radius: 200px;
                        background: white;
                        margin-right: 4px;
                        padding: 10px;
                        flex-shrink: 0;

                        @include only-xs {
                            height: 35px;
                            width: 35px;
                            padding: 4px;
                        }

                        & img {
                            height: auto;
                            width: 100%;
                        }
                    }
                }
            }

            &_success {
                background: rgba(18,34,15,0.2);

                & .exchange-footer_result_ps {
                    background: rgba(57,213,28,1);
                }
            }

            &_processing {
                background: rgba(57,213,28,0.5);

                & .exchange-footer_result_ps {
                    background: rgba(57,213,28,1);
                }
            }

            &_expired {
                background: rgba(18,34,15,0.2);


                & .exchange-footer_result_ps {
                    background: rgba(57,213,28,1);
                }
            }

            &_fail {
                background: rgba(160,100,111,0.5);

                & .exchange-footer_result_ps {
                    background: rgba(160,100,111,1);
                }
            }

            &_available {
                display: flex;
                padding-top: 5px;
                justify-content: center;

                @include only-xs-exchanges {
                    justify-content: unset;
                    align-items: center;
                }
            }
        }

        &-header {

            &_summary {
                width: 100%;
                font-weight: bold;

                & h1 {
                    font-size: 22px;
                    color: white;
                    font-family: Montserrat-Regular, sans-serif;

                    @include only-xs-exchanges {
                        text-align: center;
                    }
                }

                &_body {
                    display: flex;
                    justify-content: space-between;
                    border-top: 1px solid rgba(255,255,255, 0.60%);
                    border-bottom: 1px solid rgba(255,255,255, 0.60%);
                    padding: 10px 0;
                    width: 100%;

                    @include only-xs-exchanges {
                        flex-direction: column;
                        align-items: center;
                        text-align: center !important;
                    }

                    & span {

                        &:first-child {
                            font-family: Montserrat-Light, sans-serif;
                            color: white;
                            font-size: 16px;
                        }

                        &:last-child {
                            font-family: Montserrat-Light, sans-serif;
                            color: #0e638a;
                            font-size: 16px;
                        }
                    }
                }
            }

            &_available {
                display: flex;
                justify-content: flex-end;

                @include only-xs-exchanges {
                    flex-direction: column;
                    align-items: center;
                }

                &_time {
                    text-align: end;
                    padding-right: 15px;
                    padding-top: 11px;
                    font-weight: bolder;
                    font-family: Montserrat-Light, sans-serif;

                    @include only-xs-exchanges {
                        margin-bottom: 15px;
                    }

                    & h1 {
                        font-size: 18px;
                        color: var(--main-dark);
                        margin-bottom: 0px;
                        font-weight: bolder;
                        letter-spacing: 0.4px;

                        @include only-xs-exchanges {
                            text-align: center;
                        }
                    }

                    & p {
                        font-size: 14px;
                        color: grey;
                        margin-bottom: 0;
                        font-weight: bolder;

                        @include only-xs-exchanges {
                            text-align: center;
                        }
                    }
                }
            }
        }

        &-chain {
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 15px;

            @include only-xs-exchanges {
                padding-left: 0;
            }

            &_list {
                display: flex;
                width: 100%;
                justify-content: center;

                &_container {
                    margin: 0 auto;
                    display: flex;
                }

                &_wrapper {
                    display: flex;
                    margin: 33px 0 29px 0;
                    //
                    //@include only-sm {
                    //    width: 100%;
                    //    overflow-x: auto;
                    //    height: 140px;
                    //    padding: 0 10px;
                    //    justify-content: center;
                    //}

                    @include only-xs-exchanges {
                        width: 100vw;
                        overflow-x: auto;
                        height: 130px;
                        margin: 0;
                    }
                }
            }
        }
    }
</style>