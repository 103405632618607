<template>
    <div class="referral-wrapper" :class="{'referral-wrapper_expanded': isInnerListLoaded}">
        <div class="referral">

            <div class="referral-card">
                <b-avatar variant="light" class="referral-card_avatar" :src="this.imageSelector(data.avatar)"></b-avatar>
                <img class="referral-card_icon" :src="this.imageSelector($store.getters.logo)" alt="icon"/>
                <span>{{ data.account_id }}</span>
            </div>

            <div class="referral-name">
                <div v-if="data.first_name && data.last_name">
                    <p>{{ data.first_name }}</p>
                    <p>{{ data.last_name }}</p>
                </div>
                <div v-else>
                    {{ data.email }}
                </div>
            </div>

            <div class="referral-stat">
                <div class="referral-stat_block1">
                    <div class="referral-stat_item">
                        <p v-html="$content('ref_user_personalTurnover')"></p>
                        <h4>${{ data.personal_turnover }}</h4>
                    </div>
                    <div class="referral-stat_item">
                        <p v-html="$content('ref_user_branchTurnover')"></p>
                        <h4>${{ data.team_turnover }}</h4>
                    </div>
                </div>
                <div class="referral-stat_block2">
                    <div class="referral-stat_item">
                        <p v-html="$content('ref_user_team')"></p>
                        <h4>{{ data.team_turnover }}</h4>
                    </div>
                    <div class="referral-stat_item">
                        <p v-html="$content('ref_user_invited')"></p>
                        <h4>{{ data.invited_count }}</h4>
                    </div>
                </div>
            </div>

            <b-icon :style="{'opacity': isInnerList ? '1' : '0'}" @click="onExpand" class="referral-collapse" :icon="isInnerListLoaded ? 'chevron-up' : 'chevron-down'"></b-icon>

            <router-link :to="{name: 'mail-body', params: {id: data.id}}">
                <img class="referral-mail" src="assets/icons/chat with friend.svg" alt="">
            </router-link>
            <router-link :to="{name: 'mail-body', params: {id: data.id}}">
                <div class="referral-mail_xs">
                    <span>{{ $content('msg-send') }}</span>
                </div>
            </router-link>

            <b-icon :style="{'opacity': isInnerList ? '1' : '0'}" @click="onExpand" class="referral-collapse_xs" :icon="isInnerListLoaded ? 'chevron-up' : 'chevron-down'"></b-icon>

        </div>

        <div class="referral-expand" v-if="isInnerListLoaded">
            <hr />
            <Referral v-for="referral in innerList" :data="referral" />
        </div>

    </div>
</template>

<script>
import ProjectButton from "@/components/Buttons/ProjectButton";
import Chat from "./SVG/Chat";

export default {
    name: "Referral",
    components: {Chat, ProjectButton},
    props: ['data'],
    data() {
        return {
            innerList: []
        }
    },
    computed: {
        isInnerList() {
            return !!this.data.invited_count
        },
        isInnerListLoaded() {
            return !!this.innerList.length
        }
    },
    methods: {
        onExpand() {
            if (this.isInnerList) {
                if (this.isInnerListLoaded) {
                    this.innerList = [];
                } else {
                    this.$store.dispatch('processToggle', true);
                    this.$http('referral').getInvitedList(this.data.invited_list)
                        .then(res => {
                            if (res.data.payload.result) {
                                this.innerList = res.data.payload.result;
                            }
                        })
                        .finally(() => this.$store.dispatch('processToggle', false))
                }
            }
        }
    },
}
</script>

<style lang="scss">
@import "../styles/mixins";

.referral {
    padding: 11px;
    display: flex;
    align-items: center;

    &-expand {

        & hr {
            background: var(--main-app-transparent);
            height: 2px;
            width: calc(100% - 30px);
            margin: 25px auto 25px auto;
            border-radius: 200px;
        }
    }

    &-wrapper {
        background: white;
        margin-bottom: 6px;
        border-radius: 5px;

        &_expanded {
            box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.12);
        }
    }

    &-collapse {
        margin-right: 15px;

        @include only-md-down {
            display: none !important;
        }

        &_xs {
            display: none !important;

            @include only-md-down {
                display: block !important;
                margin: 25px auto 5px auto;
            }
        }
    }

    &-mail {
        height: 50px;
        cursor: pointer;

        &_btn {
            margin-top: 10px;
            display: none;

            @include only-md-down {
                display: block;
            }
        }

        @include only-md-down {
            display: none;
        }
    }

    &-mail_xs {
        display: none;

        & span {
            color: white;
            font-family: Montserrat-Medium, sans-serif;
        }

        & svg {
            fill: white;
            height: 35px;
        }

        @include only-md-down {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
            border-radius: 5px;
            width: 100%;
            background: var(--main-app);
            padding: 10px 0;
        }
    }

    @include only-md-down {
        flex-direction: column;
        align-items: unset;
    }

    &-stat {
        display: flex;
        flex: auto;

        @include only-xs {
            flex-direction: column;
        }

        &_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 5px;

            @include only-xl {
                width: 215px;
            }

            @include only-lg {
                width: 170px;
            }

            @include only-md {
                width: 150px;
            }

            @include only-sm {
                width: 130px;
            }

            @include only-xs {
                width: 50%;
            }

            @include only-lg-down {
                text-align: center;
                flex: 1;
                margin: 5px 0;
            }

            & p {
                font-family: Montserrat-Medium, sans-serif;
                font-size: 14px;
                color: grey;
                margin-bottom: 0;

                @include only-md {
                    font-size: 13px;
                }
            }

            & h4 {
                margin-top: 5px;
                font-family: Montserrat-Medium, sans-serif;
                font-size: 20px;
                color: black;
                margin-bottom: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
                width: 100%;

                @include only-md {
                    font-size: 16px;
                }
            }
        }

        &_block1 {
            display: flex;
            flex: 1;

            & div {
                &:last-child {
                    border-left: 1px solid silver;

                    @include only-xs {
                        border: none;
                    }
                }
            }
        }

        &_block2 {
            display: flex;
            flex: 1;

            & div {
                &:first-child {
                    border-right: 1px solid silver;
                    border-left: 1px solid silver;

                    @include only-xs {
                        border: none;
                    }
                }
            }
        }
    }

    &-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 193px;

        @include only-md {
            width: 170px;
        }

        @include only-md-down {
            width: 100%;
            margin: 15px 0;
        }

        @include only-xs {
            flex-direction: row;
            justify-content: center;
            width: 100%;
        }

        & p {
            font-family: Montserrat-Medium, sans-serif;
            font-size: 20px;
            margin-bottom: 0;
            line-height: 1.1;

            @include only-xs {
                margin: 0 5px;
            }

            @include only-md-down {
                text-align: center;
            }
        }
    }

    &-card {
        display: flex;
        align-items: center;
        padding: 13px;
        background: var(--main-light-silver);
        border-radius: 200px;

        @include only-md-down {
            justify-content: center;
            margin: 0 auto;
        }

        @include only-sm {
            width: 300px;
        }

        @include only-xs {
            width: 100%;
            border-radius: 5px;
        }

        & span {
            font-family: Acrobat-Light, sans-serif;
            font-size: 24px;
            color: grey;

            @include only-md-down {
                font-size: 28px;
            }
        }

        &_avatar {
            height: 55px;
            width: 55px;
        }

        &_icon {
            height: 25px;
            width: 25px;
            margin: 0 10px;
        }
    }
}
</style>