import {RepositoryFactory} from "../../repository/RepositoryFactory";

function handleDialogResponse (res, state) {
    state.selectedDialogChat = res.data.payload.msgs;
    state.dialogs = res.data.payload.chat_list;
    state.selectedDialogRecipient = res.data.payload.recipient_account_id;
}

const state = {
    dialogs: [],
    chat_display_status: null,
    unread_chats: null,
    selectedDialog: null,
    selectedDialogChat: null,
    selectedDialogRecipient: null
}
const mutations = {
    updateDialogs(state, list) {
        state.dialogs = list;
    },
    toggleChatDisplayStatus(state, status) {
        state.chat_display_status = status;
    },
    refresh_unread_chats(state, data) {
        state.unread_chats = data;
    },
    selectDialog(state, id) {
        state.selectedDialog = id;
    },
    getSelectedDialog(state) {
        RepositoryFactory.get('mail').startChat(state.selectedDialog)
            .then(res => handleDialogResponse(res, state))
    },
    sendMsg(state, res) {
        handleDialogResponse(res, state)
    },
    removeSelectedDialog(state) {
        state.selectedDialog = null;
        state.selectedDialogChat = null;
    },
    getSupportDialog(state) {
        RepositoryFactory.get('support').getSupportChatHistory(0)
            .then(res => {
                state.selectedDialogDialog = 'support';
                state.selectedDialogRecipient = 'support';
                state.selectedDialogChat = res.data.payload;
            })
    },
    setDialogChat(state, chat) {
        state.selectedDialogChat = chat;
    }
}
const actions = {
    removeSelectedDialog({commit}) {
        commit('removeSelectedDialog')
    },
    sendMessage({state, commit}, msg) {

        return new Promise((resolve) => {
            RepositoryFactory.get('mail').send(state.selectedDialog, msg)
                .then(res => {
                    commit('sendMsg', res);
                    resolve();
                })
        })
    },
    toggleChatDisplayStatus({ commit }, status) {
        commit('toggleChatDisplayStatus', status);
    },
    refresh_counters({ commit }, data) {
        commit('refresh_unread_chats', data)
    },
    updateDialogs({commit}) {
        RepositoryFactory.get('mail').getChatList()
            .then(res => commit('updateDialogs', res.data.payload))
    },
    onDialogSelect({commit}, id) {
        commit('selectDialog', id);
        if (id === 'support') {
            commit('getSupportDialog')
        } else {
            commit('getSelectedDialog')
        }
    },
    sendMessageSupport({commit}, payload) {
        RepositoryFactory.get('support').sendMsgToSupport(payload)
            .then(res => commit('setDialogChat', res.data.payload))
    }
}
const getters = {
    dialogs: state => state.dialogs,
    chatStatus: state => state.chat_display_status,
    counters: state => state.unread_chats,
    selectedDialog: state => state.selectedDialog,
    selectedDialogChat: state => state.selectedDialogChat,
    selectedDialogRecipient: state => state.selectedDialogRecipient
}

export default {
    state,
    mutations,
    actions,
    getters
}