<template>
    <div class="leverage-button">
        <div class="leverage-button_time">
            <span>{{ timerTime }}</span>
        </div>
        <button @click="$emit('clicked')" v-html="$content('platform_process_timer_toogle')"></button>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "ExchangeLeverageButton",
    props: ['exchange'],
    data() {
        return {
            timer: ''
        }
    },
    computed: {
        ticker() {
            return this.$store.getters.ex_ticker
        },
        timerTime() {
            return moment.utc(this.timer*1000).format('HH:mm:ss')
        }
    },
    watch: {
        ticker() {
            if (this.timer > 0) {
                this.timer--;
            } else {
                this.$store.dispatch('exchanges.list')
            }
        }
    },
    created() {
        this.timer = this.exchange.time.left_availability_time;
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .leverage-button {
        border-radius: 200px;
        border: 2px solid rgba(0,0,0, 0.1);
        display: flex;
        height: 64px;
        padding: 4px 5px;

        @include only-xs-exchanges {
            width: 100%;
            max-width: 350px;
        }

        &_time {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Akrobat-Regular, sans-serif;
            color: #009700;
            font-size: 26px;
            padding-left: 10px;

            & span {
                display: block;
                width: 80px;
                margin-top: 3px;
                margin-left: 1px;
            }
        }

        & button {
            width: 248px;
            border: 2px solid white;
            border-radius: 200px;
            background: none;
            font-family: Myriad-Pro, sans-serif;
            font-size: 15px;
            letter-spacing: -0.05px;
            color: white;
            background: rgba(255, 255, 255, 0.1);
            margin-left: 3px;

            @include only-xs-exchanges {
                font-size: 13px;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.3);
            }
        }
    }
</style>