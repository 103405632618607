import Repository from "../Repository";
import store from "../../store";

export default {
    getExchangesList() {
        // return Repository.get(`/change/list?account_id=${store.getters.user.account_id}`)
        return Repository.get(`/change/list`)
    },
    invest(data) {
        let fd = new FormData();
        for (let key in data) {
            fd.append(key, data[key])
        }

        return Repository.post('/change/start', fd)
    },
    checkDeal(id) {
        return Repository.get(`/change/status/${ id }`)
    },
    calc(data) {
        return Repository.post(`/change/calc`, data)
    },
    getLeverageVariants(amount) {
        return Repository.post(`/change/leverage`, {amount: amount})
    },
    getHistory(config) {
        // return Repository.get(`/cashflow?account_id=${store.getters.user.account_id}`, {
        //     params: config
        // })
        return Repository.get(`/cashflow`, {
            params: config
        })
    }
}
