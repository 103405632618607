<template>
    <div class="lang-switcher" v-if="$store.getters.siteSettings">
        <div class="lang-switcher_body" @click="$store.dispatch('togglePopover', 'locale')">
            <span>{{ $store.getters.locale }}</span>
<!--            <b-icon icon="chevron-down"></b-icon>-->
            <img class="lang-switcher_arrow" src="/assets/img/dd.png" alt="">
        </div>
        <Popover name="locale">
            <Locales></Locales>
        </Popover>
    </div>
</template>

<script>
import Popover from "@/components/Popover";
import Locales from "@/components/Popovers/Locales";
export default {
    name: "LangSwitcher",
    components: {Locales, Popover}
}
</script>

<style lang="scss" scoped>
    @import "../styles/mixins";

    .lang-switcher {
        position: relative;

        &_arrow {
            margin-left: 10px;

            @include only-xs {
                margin-left: 5px;
            }
        }

        &_body {
            display: flex;
            align-items: center;
            //color: #b7b7b7;
            color: #464646;
            cursor: pointer;
            font-family: Montserrat-Medium, sans-serif;

            & span {
                font-size: 14px;
                color: inherit;
                text-transform: uppercase;
            }

            & svg {
                font-size: 10px;
                color: inherit;
                margin-left: 5px;
            }

            &:hover {
                color: black;
            }
        }
    }
</style>