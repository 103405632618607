import Repository from "../Repository";

export default {
    getMenu(arr) {
        return Repository.post('/content/menu', {
            list: arr
        })
    },
    getNews() {
        return Repository.get('/content/news')
    },
    getFAQ(limit) {
        return Repository.get('/content/faq', {
            params: {
                limit: limit
            }
        })
    },
    getLicenses() {
        return Repository.get('/content/licenses')
    },
    getArticle(name) {
        return Repository.get(`/article/${name}`)
    },
    getAgreetments() {
        return Repository.get('/content/contract')
    },
    suggestNews(data) {
        return Repository.post('/content/news/proposed', data)
    }
}
