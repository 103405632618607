import Vue from 'vue'
import store from '../store'
import { RepositoryFactory } from '../repository/RepositoryFactory'

Vue.prototype.$modal = {
    open: (name, params) => store.dispatch('openModal', {
        name: name,
        params: params
    }),
    notify: params => store.dispatch('openModal', {
        name: 'Notify',
        params: params
    }),
    close: () => store.dispatch('closeModal')
};
Vue.prototype.$http = (repository) => RepositoryFactory.get(repository);
Vue.prototype.$utils = {
    validateForm: form => new Promise((resolve) => {
        !Object.keys(form).some(k => !form[k]) ? resolve() : ''
    }),
    getMenu: key => store.getters.menus_list[key] ? store.getters.menus_list[key]['items'] : [],
    spacesAmount(x) {
        if(x || x == 0) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return parts.join(".");
        }

        return "0";
    }
}
Vue.prototype.$payloadHandler = (res) => res.data.payload
Vue.prototype.progressAPI = (repo, method) => {}

Vue.prototype.imageSelector = (url) => {
    // console.log(url, !url?.includes('blob:'));

    // if(url != undefined && url != 'undefined' && url != '' && !url?.includes('blob:')) {
    //     let url2api = 'http://localhost:5000' + url;
    //     if (imageExists(url2api)) {
    //         return url2api
    //     }
    // }

    return url;
}
function imageExists(image_url){
    let http = new XMLHttpRequest();

    try {
        http.open('HEAD', image_url, false);

        // http.withCredentials = true;

        http.send();
    } catch (e) {
        console.log(e)
    }

    return http.status != 404;
}