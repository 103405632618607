<template>
    <div class="exchainpair" v-if="display">

        <div class="exchainpair-container" v-if="isFirst">
            <div class="exchainpair-item exchainpair-item_active">
                <img :src="this.imageSelector(pair.ps_from.logo_url)" :alt="pair.ps_from.alt" />
            </div>
            <img class="exchainpair-item_rate" :src="this.imageSelector(rateArrow)" alt="">
            <div class="exchainpair-item">
                <img :src="this.imageSelector(pair.ps_to.logo_url)" :alt="pair.ps_to.alt" />
            </div>
        </div>

        <div class="exchainpair-container" v-else>
            <img class="exchainpair-item_rate" :src="this.imageSelector(rateArrow)" alt="">
            <div class="exchainpair-item" :class="{'exchainpair-item_active': isLast}">
                <img :src="this.imageSelector(pair.ps_to.logo_url)" :alt="pair.ps_to.alt" />
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "ExchangeChainPair",
    props: ['pair', 'type', 'index', 'depositChanged', 'chain'],
    computed: {
        isFirst() {
            return this.index === 0
        },
        isLast() {
            if (this.type === 'available' || this.type === 'processing') {
                if (this.depositChanged) {
                    if (this.pair.to_origin_ps) {
                        return true
                    }
                } else {
                    return this.index === this.chain.length - 2
                }
            } else {
                return this.index === this.chain.length - 1
            }
        },
        rateArrow() {
            return this.pair.rate > 1 ? `/assets/img/green-arrow.png` : '/assets/img/red-arrow.png'
        },
        display() {
            if (this.type === 'available') {
                if (this.depositChanged) {
                    return true
                } else {
                    return !this.pair.to_origin_ps
                }
            } else {
                return true
            }
            //
            // return !this.pair.to_origin_ps || this.pair.to_origin_ps && this.depositChanged && this.type === 'available' || this.type === 'processing'
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .exchainpair {
        display: flex;
        align-items: center;

        &-container {
            display: flex;
            align-items: center;
        }

        &-item {
            height: 54px;
            width: 54px;
            background: white;
            border-radius: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            overflow: hidden;


            @include only-lg {
                height: 50px;
                width: 50px;
            }

            @include only-md-exchanges {
                height: 50px;
                width: 50px;
            }

            &_rate {
                margin: 0 6px;
                height: 15px;
                transform: scale(1.3);

                @include only-lg {
                    height: 10px;
                }

                @include only-md-exchanges {
                    height: 10px;
                }

                @include only-xs {
                    height: 15px;
                }
            }

            &_active {
                height: 100px;
                width: 100px;
                position: relative;
                margin: 0 10px;
                padding: 15px !important;
                overflow: unset !important;

                @include only-lg {
                    height: 80px;
                    width: 80px;
                }

                @include only-xs {
                    height: 80px;
                    width: 80px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    height: calc(100% + 20px);
                    width: calc(100% + 20px);
                    border-radius: 200px;
                    background: rgba(255, 255, 255, 0.3);
                    z-index: -1;
                }
            }

            & img {
                height: auto;
                width: 100%;
            }
        }
    }
</style>