<template>
    <div class="auth-popover">
        <div class="auth-popover_header">
            <div class="auth-popover_header_body">
                <div class="auth-popover_header_back mb-1" @click="toggleForm('login')" v-show="form !== 'login'">
                    <b-icon icon="chevron-left"></b-icon>
                </div>
                <h3 v-html="$content(`header_${form}FormTitle`)"></h3>
            </div>
        </div>

        <div v-if="form === 'login'">
            <AuthInput
                @input="removeErrors"
                name="email"
                :warnings="errors.login"
                :submitted="isSubmitted"
                placeholder="header_loginForm_loginPlaceholder"
                v-model="payload.login.email"></AuthInput>
            <AuthInput
                @input="removeErrors"
                name="password"
                type="password"
                :warnings="errors.login"
                :submitted="isSubmitted"
                placeholder="header_loginForm_passPlaceholder"
                v-model="payload.login.password"></AuthInput>
            <span
                v-html="$content('header_loginFormForgotPass')"
                class="warn-text warn-text_link auth-popover_text_lined"
                @click="toggleForm('forgot_password')"></span>
            <ProjectButton
                @click.native="login"
                class="mt-3"
                variant="green"
                radius="10">
                <div v-html="$content('header_loginFormSubmit')"></div>
            </ProjectButton>
            <ProjectButton
                @click.native="toggleForm('registration')"
                variant="paper"
                radius="10"
                size="33">
                <div v-html="$content('header_loginFormRegisterButton')"></div>
            </ProjectButton>
        </div>

        <div v-if="form === 'registration'">
            <AuthInput
                @input="removeErrors"
                name="email"
                :warnings="errors.registration"
                :submitted="isSubmitted"
                placeholder="header_registerForm_loginPlaceholder"
                v-model="payload.registration.email"></AuthInput>
            <AuthInput
                @input="removeErrors"
                name="password"
                type="password"
                :warnings="errors.registration"
                :submitted="isSubmitted"
                placeholder="header_registerForm_passwordPlaceholder"
                v-model="payload.registration.password"></AuthInput>
            <AuthInput
                name="password_confirm"
                @input="removeErrors"
                type="password"
                :warnings="errors.registration"
                :submitted="isSubmitted"
                placeholder="header_registerForm_rePasswordPlaceholder"
                v-model="payload.registration.password_confirm">

            </AuthInput>
            <ProjectButton @click.native="register" radius="10" variant="green">
                <div v-html="$content('header_registerFormSubmit')"></div>
            </ProjectButton>
        </div>

        <div v-if="form === 'forgot_password'">
            <p class="auth-popover_text" v-html="$content('header_pass_recover_text')"></p>
            <AuthInput
                @input="removeErrors"
                name="email"
                :warnings="errors.forgot_password"
                v-model="payload.recover.email"
                :submitted="isSubmitted"
                placeholder="header_pass_recover_placeholder"></AuthInput>
            <ProjectButton @click.native="onRecover" variant="green" radius="10">
                <div v-html="$content('header_pass_recover_button')"></div>
            </ProjectButton>
        </div>

        <b-icon class="auth-popover_header_close" icon="x" @click="$store.dispatch('togglePopover', null)"></b-icon>

    </div>
</template>

<script>
import AuthInput from "@/components/Inputs/AuthInput";
import ProjectButton from "@/components/Buttons/ProjectButton";
import qs from 'qs'
export default {
    name: "Auth",
    components: {ProjectButton, AuthInput},
    data() {
        return {
            form: 'login',
            payload: {
                login: {
                    email: null,
                    password: null
                },
                registration: {
                    email: null,
                    password: null,
                    password_confirm: null
                },
                recover: {
                    email: ''
                }
            },
            isSubmitted: false,
            errors: {
                login: {},
                registration: {},
                forgot_password: {}
            }
        }
    },
    methods: {
        onRecover() {
            this.isSubmitted = true;
            this.$Progress.start();
            this.$http('auth').recoverPassword(this.payload.recover)
                .then(res => {

                })
                .catch(e => {
                    this.$Progress.fail();
                    this.errors[this.form] = e.response.data.response.errors;
                })
        },
        removeErrors() {
            Object.keys(this.errors).forEach(i => this.errors[i] = null);
        },
        toggleForm(name) {
            this.isSubmitted = false;
            this.form = name
        },
        login() {
            this.isSubmitted = true;
            this.$utils.validateForm(this.payload.login)
                .then(() => {
                    this.$Progress.start();
                    this.$http('auth').login(qs.stringify(this.payload.login))
                        .then(() => {
                            this.$Progress.finish();
                            window.scrollTo(0, 0);
                            window.location.reload();
                            // localStorage.setItem('vuex_state', JSON.stringify(this.$store.state));
                        })
                        .catch(e => {
                            this.$Progress.fail();
                            this.errors[this.form] = e.response.data.response.errors;
                        })
                })
        },
        register() {
            this.isSubmitted = true;
            this.$utils.validateForm(this.payload.registration)
                .then(() => {
                    this.$Progress.start();
                    this.$http('auth').signUp(qs.stringify(this.payload.registration))
                        .then(() => {
                            this.$Progress.finish()
                            setTimeout(()=>{
                              window.location.reload();
                            }, 1000);
                            // localStorage.setItem('vuex_state', JSON.stringify(this.$store.state));
                        })
                        .catch(e => {
                            this.$Progress.fail();
                            this.errors[this.form] = e.response.data.response.errors;
                        })
                })
        }
    },
    created() {
        if (this.$store.getters.activePopoverForm) {
            this.form = this.$store.getters.activePopoverForm
        }
    }
}
</script>

<style lang="scss">
    .auth-popover {
        width: 254px;
        padding: 33px 30px 22px 30px;
        position: relative;

        & input {
            background: var(--main-light-silver);
            box-shadow: none;
        }

        &_text {
            font-family: Montserrat-Medium, sans-serif;
            font-size: 15px;
            line-height: 1.2;
            color: var(--main-dark-paper);

            &_lined {
                text-decoration: underline;
            }
        }

        &_header {
            margin-bottom: 22px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &_body {
                display: flex;

                & h3 {

                }
            }

            &_close {
                font-size: 20px;
                position: absolute;
                color: var(--main-dark-paper);
                top: 10px;
                right: 10px;

                &:hover {
                    color: black;
                }
            }

            &_back {
                width: 20px;
                height: 20px;
                background: var(--main-paper);
                border-radius: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 11px;

                &:hover {
                    background: var(--main-dark-paper);

                    & svg {
                        color: white;
                    }
                }

                & svg {
                    font-size: 10px;
                    color: black;
                    margin-right: 2px;
                }
            }

            & h3 {
                font-size: 20px;
                font-family: Montserrat-SemiBold, sans-serif;
                color: var(--main-titles);
                margin: 0;
                flex: 1;
                text-transform: capitalize;
            }
        }
    }
</style>