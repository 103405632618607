<template>
    <div class="report" :class="{'report-opened': isOpened}">

        <div class="report-body" @click="onToggle">

            <div class="report-body_date">
                <p>{{ date }} {{ year }}</p>
            </div>

            <div class="report-body_operation">
                <div class="report-body_operation_amount_wrapper">
                    <div class="report-body_operation_amount" :style="{'background': profitBackground}">
                        <span :style="{'color': profitSymbolColor}" v-html="profitSymbol"></span><p v-html="profit"></p>
                    </div>
                </div>
                <h3 class="report-title" v-html="$content(`operation_${ report.source }`)"></h3>
            </div>

            <div class="report-body_balance">
                <h3 class="report-title" v-html="$content('report-balance') + ':'"></h3>
                <div class="report-body_operation_amount" :style="{'background': balanceBackground}">
                    <span style="color: white">$</span>
                    <p>{{ report.balance }}</p>
                </div>
            </div>

            <div class="report-body_chevron">
                <b-icon
                    :style="{color: isExtended ? 'white' : 'transparent'}"
                    :icon="isOpened ? 'chevron-up' : 'chevron-down'" />
            </div>

        </div>
        <div class="report-expand" v-if="isOpened && isExtended">
            <div class="report-expand_info">
                <div class="report-expand_label">
                    <img :src="this.imageSelector($store.getters.logo)" alt="">
                </div>
            </div>
            <div class="report-expand_ps">

                <h1 class="report-title" v-html="$content('report-exchange')"></h1>

                <div class="report-expand_ps_wrapper">
                    <div class="report-box">
                        <div class="report-expand_label">
                            <img :src="this.imageSelector(report.args.ps_from.logo_url)" :alt="report.args.ps_from.alt">
                        </div>
                        <span>$</span><p v-html="report.args.amount_initial"></p>
                    </div>
                    <img class="report-expand_symbol" src="/assets/img/reload.png" alt="">
                    <div class="report-box">
                        <div class="report-expand_label">
                            <img :src="this.imageSelector(report.args.ps_to.logo_url)" :alt="report.args.ps_to.alt">
                        </div>
                        <span>$</span><p v-html="report.args.amount_payout"></p>
                    </div>
                </div>

            </div>
        </div>



    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "Report",
    props: ['report'],
    data() {
        return {
            isOpened: false
        }
    },
    methods: {
        onToggle() {
            if (this.isExtended) {
                this.isOpened = !this.isOpened
            }
        }
    },
    computed: {
        profitSymbolColor() {
            return this.report.profit >= 0 ? 'var(--main-app)' : 'rgb(209 87 29)'
        },
        profit() {
            return String(this.report.profit).replace('-', '')
        },
        profitSymbol() {
            return this.report.profit >= 0 ? '+$' : '-$'
        },
        isExtended() {
            return this.report.source === 'change'
        },
        balanceBackground() {
            return  this.isOpened ? '#00EF00' : '#00D841';
        },
        profitBackground() {
            if (this.report.profit >= 0) {
                return  this.isOpened ? 'rgb(14,57,29, 40%)' : 'rgb(14,57,29, 20%)';
            } else {
                return  this.isOpened ? 'rgb(205,153,59, 100%)' : 'rgb(195, 157, 90, 1)'
            }
        },
        date() {
            return moment(this.report.date).format('HH:mm dd, DD MMMM')
        },
        year() {
            return moment.utc(this.report.date).format('YYYY')
        }
    },
    created() {
        moment.locale(this.$store.getters.locale);
    }
}
</script>

<style lang="scss" scoped>
    @import "../styles/mixins";

    .report {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: rgba(0,0,0, 0.1);
        border-radius: 10px;
        margin-bottom: 6px;
        cursor: pointer;

        &-opened {
            background: rgba(0,0,0, 0.2);

            &:hover {
                background: rgba(0,0,0, 0.2) !important;
            }
        }

        &-title {
            font-size: 20px;
            margin-bottom: 0;
            color: white;
            font-weight: bold;
            margin-left: 5px;
            //text-transform: capitalize;
            font-family: Montserrat-Light, sans-serif;

            @include only-lg {
                font-size: 16px;
            }
        }

        &:hover {
            background: rgba(0,0,0, 0.2);
        }

        &-body {
            display: flex;
            min-height: 64px;
            padding: 0 24px;

            &_chevron {
                color: white;
            }

            &_date {
                font-family: Montserrat-Light, sans-serif;
                font-size: 20px;
                display: flex;
                align-items: center;
                flex: 1;
                font-weight: bold;

                @include only-xl {
                    width: 264px;
                    flex: unset;
                }

                p {
                    font-size: inherit;
                    color: white;
                    margin-bottom: 0;
                    line-height: 1;

                    @include only-lg {
                        font-size: 16px;
                    }
                }

                & span {
                    font-size: inherit;
                    color: var(--main-dark);
                    margin-left: 8px;
                    line-height: 1;
                }
            }

            &_operation {
                flex: 2;
                display: flex;
                align-items: center;

                &_amount {
                    padding: 5px 15px 5px 5px;
                    border-radius: 5px;
                    display: inline-flex;
                    align-items: center;
                    font-weight: bold;
                    font-family: Montserrat-Light, sans-serif;
                    font-size: 20px;
                    margin-left: 15px;

                    &_wrapper {
                        min-width: 150px;
                    }

                    & span {
                        color: var(--main-app);
                    }

                    & p {
                        margin-bottom: 0;
                        line-height: 1;
                        color: white;
                        margin-left: 2px;
                    }
                }
            }

            &_balance {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 15px;
            }

            &_chevron {
                flex-shrink: 0;
                display: flex;
                align-items: center;
            }
        }

        &-box {
            display: flex;
            align-items: center;
            background: rgba(38,75,56, 0.1);
            padding: 10px;
            border-radius: 200px;
            min-width: 238px;
            font-family: Montserrat-Light, sans-serif;
            font-size: 20px;
            font-weight: bold;

            & span {
                color: var(--main-app);
                margin-left: 5px;
            }

            & p {
                margin-bottom: 0;
                color: white;
                margin-left: 3px;
            }
        }

        &-expand {
            padding: 18px 24px;
            display: flex;
            align-items: center;
            border-top: 2px solid rgba(23,67,46, 0.16);
            justify-content: space-between;

            &_symbol {
                margin: 0 10px;
            }

            &_ps {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                @include only-xl {
                    width: 100%;
                    padding-left: 15px;
                    justify-content: space-between;
                }

                &_wrapper {
                    display: flex;
                    align-items: center;
                    margin-left: 18px;

                    @include only-xl {
                        margin-left: 0;
                    }
                }
            }

            &_label {
                height: 54px;
                width: 54px;
                border-radius: 200px;
                background: white;
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: center;

                & img {
                    height: auto;
                    max-width: 100%;
                }
            }

            &_info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-shrink: 0;
                width: auto;

                @include only-xl {
                    width: 264px;
                }
            }
        }

    }
</style>