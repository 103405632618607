<template>
    <div class="deal-input_wrapper">
        <div class="deal-input">
            <div class="deal-input_ps">
                <img
                    :src="this.imageSelector(type === 'amount' ? ps_from.logo_url : ps_to.logo_url)"
                    :alt="type === 'amount' ? ps_from.alt : ps_to.alt"/>
            </div>

            <div class="deal-input_enter" v-if="type === 'amount'">
                <p v-html="$content('ex-amount_title')"></p>
                <input
                    :readonly="deal.state === 'processing'"
                    :value="value"
                    @input="update($event.target.value)"
                    :placeholder="$content('ex-amount_placeholder')"
                    type="text" />
            </div>

            <div class="deal-input_enter" v-if="type === 'result'">
                <p v-html="$content('ex-recieve_title')"></p>
                <input readonly :value="result" type="text" />
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "DealFormInput",
    props: ['deal', 'type', 'value', 'result'],
    methods: {
        update(value) {
            this.$emit('input', value)
        }
    },
    computed: {
        ps_from() {
            return this.deal.chain[0].ps_from
        },
        ps_to() {
            return this.deal.chain[this.deal.chain.length - 1].ps_to
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .deal-input {
        background: white;
        border-radius: 200px;
        display: flex;
        align-items: center;
        padding: 7px 10px;
        width: 100%;

        &_error {
            text-align: center;
            color: red;
            font-family: Montserrat-Light, sans-serif;
            font-weight: bold;
            margin-top: 5px;
            margin-bottom: 0;

            @include only-xs {
                font-size: 12px;
            }
        }

        &_wrapper {
            width: 215px;
            height: 0%;

            @include only-xs {
                flex: 1;
                max-width: 215px;
            }
        }

        &_enter {
            font-family: Montserrat-Light, sans-serif;
            margin-left: 10px;

            @include only-xs {
                margin-left: 5px;
            }

            & p {
                margin-bottom: 0px;
                font-size: 12px;
                color: var(--main-dark-paper);

                @include only-xs {
                    font-size: 10px;
                    line-height: 1;
                }
            }

            & input {
                width: 130px;
                height: 25px;
                font-size: 18px;
                font-weight: bold;
                border: none;
                outline: none;
                padding: 0;

                @include only-xs {
                    width: 100%;
                    font-size: 15px;
                }
            }
        }

        &_ps {
            height: 50px;
            width: 50px;
            background: #f0f0f0;
            border-radius: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px;
            flex-shrink: 0;

            @include only-xs {
                width: 35px;
                height: 35px;
            }

            & img {
                height: auto;
                width: 100%;
            }
        }
    }
</style>