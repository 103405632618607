<template>
    <div class="half-banner-container">
        <div class="half-banner" :class="{'half-banner_even': index % 2 === 0, 'half-banner_odd': index % 2 !== 0}">
            <div class="half-banner_img" :style="{background: `url(/assets/img/banner${ index }.png)`}"></div>
            <div class="half-banner_content">
                <div class="half-banner_content_text">
                    <h1 v-html="$content(`adv_banner${ index }_title`)"></h1>
                    <p v-html="$content(`adv_banner${ index }_info`)"></p>
                    <button
                        v-html="$content('adv_banner_button')"
                        @click="$modal.open('HalfBlockModal', {index: index, color: 'white', width: '650px'})"></button>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "HalfBanner",
    props: ['index']
}
</script>

<style lang="scss">
    @import "../styles/mixins";

    .half-banner {
        height: 500px;
        display: flex;
        position: relative;

        &_odd {
            flex-direction: row-reverse;

            & .half-banner_content {
                background-position: center right !important;
                left: 0;
                background: url('/assets/img/banner-text_left.png');

                @include only-lg-down {
                    background: none;
                }
            }
        }

        &_even {

            & .half-banner_content {
                right: 0;
                background: url('/assets/img/banner-text_right.png');

                @include only-lg-down {
                    background: none;
                }
            }
        }

        &_img {
            width: 53%;
            background-size: cover !important;

            @include only-lg-down {
                width: 100%;
                opacity: 0.2;
            }
        }

        &_content {
            position: absolute;
            width: 50%;
            height: 100%;
            background-repeat: no-repeat !important;
            display: flex;
            align-items: center;
            justify-content: center;

            @include only-lg-down {
                width: 100%;
            }

            &_text {
                //width: 380px;
                width: 417px;

                @include only-lg-down {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0 15px;
                    width: 100%;
                }

                & p {
                    font-family: Montserrat-Medium, sans-serif;
                    //color: #636363;
                    color: #595959;
                    padding-left: -42px;
                  margin-bottom: 0;

                    & ul {
                      //padding-left: 22px;
                      padding-left: 19px;
                      font-size: 18px;
                    }
                }

                & h1 {
                    font-family: Montserrat-SemiBold, sans-serif;
                    font-size: 26px;
                    //color: var(--main-titles);
                    color: var(--main-result-success);
                    //margin-bottom: 15px;
                    margin-bottom: 37px;

                    @include only-lg-down {
                        text-align: center;
                    }
                }

                & button {
                  margin-top: 50px;
                  border: none;
                  height: 36px;
                  width: 132px;
                  border-radius: 200px;
                  //color: var(--main-dark-paper);
                  //background: var(--main-paper);
                  color: #818181;
                  background-color: #e6e6e6;
                    font-family: Montserrat-Medium, sans-serif;
                    font-size: 12px;

                    &:hover {
                        background: silver;
                    }
                }
            }
        }
    }
</style>