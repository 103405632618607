import {RepositoryFactory} from "../../repository/RepositoryFactory";

const AuthRepository = RepositoryFactory.get('auth');

const state = {
    user: {},
    displayBalance: null,
    displayPs: null
}
const mutations = {
    setUser(state, payload) {
        // console.log(state.user, payload);
        state.user = payload;
    },
    setUserInfo(state, payload) {
        state.user = Object.assign(state.user, payload)
    },
    setUserWallet(state, payload) {
        state.wallet = payload;
        state.displayBalance = payload.balances;
        state.displayPs = payload.ps;
    },
    setUserData(state, payload) {
        state.user = payload.user;
        state.wallet = payload.wallet;
        state.displayBalance = payload.displayBalance;
        state.displayPs = payload.displayPs;
    },
    removeDeals(state) {
        state.user = {};
        state.displayBalance = null;
        state.displayPs = null;
    }
}
const actions = {
    refresh_user({ commit }, payload) {
        if(!state.user?.is_authenticated) {
            commit('setUser', payload)
        }
    },
    refresh_personal_info({ commit }, payload) {
        commit('setUserInfo', payload)
    },
    refresh_user_wallet({ commit }, payload) {
        commit('setUserWallet', payload)
    },
    logout({ commit }) {
        AuthRepository.logout()
            .then(() => {
                commit('removeDeals');

                // const savedState = JSON.parse(localStorage.getItem('vuex_state'));
                // if(savedState.user) {
                //     savedState.user = state;
                //     localStorage.setItem('vuex_state', JSON.stringify(savedState));
                // }

                document.location.reload();
            })
    }
}
const getters = {
    user: state => {
        return state.user
    },
    isAuth: state => {
        return state.user.is_authenticated
    },
    userName: state => {
        if (state.user.first_name && state.user.last_name) {
            return `${ state.user.first_name } ${state.user.last_name}`
        } else if (state.user.first_name) {
            return state.user.first_name
        } else {
            return state.user.email
        }
    },
    wallet: state => state.wallet,
    displayBalance: state => state.displayBalance,
    displayPs: state => state.displayPs
}

export default {
    state,
    mutations,
    actions,
    getters
}