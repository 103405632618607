<template>
    <div class="video-btn">
        <b-icon icon="play-fill"></b-icon>
        <p v-html="$content('landing_title_button')"></p>
    </div>
</template>

<script>
export default {
name: "VideoButton"
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .video-btn {
        width: 296px;
        height: 65px;
        border: 3px solid white;
        border-radius: 200px;
        font-family: Montserrat-Medium, sans-serif;
        //font-size: 20px;
        font-size: 18px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        padding: 0 32px;
        cursor: pointer;
        color: white;
        transition: ease-out 0.3s;

        @include only-xs {
            width: 250px;
            height: 60px;
            font-size: 17px;
        }

        &:hover {
            background: var(--main-app);
        }

        & svg {
            font-size: 30px;
            color: inherit;

            @include only-xs {
                font-size: 25px;
            }
        }

        & p {
            text-align: center;
            color: inherit;
            width: 100%;
            margin-bottom: 0;
        }
    }
</style>