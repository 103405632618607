<template>
    <section class="content" v-html="$content(key_)"/>
</template>

<script>
    export default {
        name: 'Content',
        props: {
            key_: String
        }
    }
</script>
