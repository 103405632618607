<template>
    <div class="loading-process">
        <transition name="fade">
            <div v-if="$store.getters.ex_process" class="loader"></div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "DealsLoadingProcess"
}
</script>

<style lang="scss" scoped>
.loading-process {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10000;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(255, 255, 255, 0.37);
  border-right: 0.5em solid rgba(255, 255, 255, 0.37);
  border-bottom: 0.5em solid var(--main-orange);
  border-left: 0.5em solid var(--main-orange);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


</style>