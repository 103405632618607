<template>
    <Page>
        <FullVwContainer color="var(--main-light-silver)" :first="true">
            <div class="company-page" id="company-page">
                <h1 class="company-page_title" v-html="$content('myCompany_title')"></h1>
                <h1 class="company-page_block_title" v-html="$content('myCompany_news_title')"></h1>
                <div>
                    <div class="c_field_wrapper">
                        <div class="c_field c_field_search">
                            <b-icon icon="search"></b-icon>
                            <input
                                @input="onParamsChange('news', 'search', $event.target.value)"
                                type="text"
                                :placeholder="$content('myCompany_searchInputPlaceholder')" />
                        </div>
                        <div
                            class="c_field c_field_auto"
                            @click="onParamsChange('news', 'order_ascending', !newsConfig.order_ascending)">
                            <b-icon icon="sliders"></b-icon>
                            <span v-html="$content('myCompany_listFilterBtn')"></span>
                        </div>
                        <div class="c_field c_field_auto c_field_spacer" @click="onNewsAction('delete')">
                            <b-icon icon="trash"></b-icon>
                            <span v-html="$content('myCompany_listDeleteBtn')"></span>
                        </div>
                        <div class="c_field c_field_auto c_field_color" @click="onNewsAction('mailing')">
                            <b-icon icon="arrow90deg-left"></b-icon>
                            <span v-html="$content('myCompany_listAnswerBtn')"></span>
                        </div>
                    </div>
                    <div class="company-page_news" v-if="news">
                        <div class="company-page_news_item" v-for="item in news.content" :key="item.referral_id">
                            <div class="control-group centered">
                                <label class="control control-checkbox">
                                    <input type="checkbox" :id="item.referral_id" :value="item" v-model="selectedNews"/>
                                    <div class="control_indicator"></div>
                                </label>
                            </div>
                            <div class="company-page_news_item_user">
                                <h3>{{ item.referral_account_id }}</h3>
                            </div>
                            <div class="company-page_news_item_event">
                                <div class="company-page_news_item_event_body">
                                    <h3 v-html="item.news_body"></h3>
                                </div>
                            </div>
                            <div class="company-page_news_item_date">{{ item.date }}</div>
                        </div>
                        <div class="company-page_news_empty" v-if="news && !news.content.length">
                            <span v-html="$content('no_news')"></span>
                        </div>
                        <div class="company-page_news_bar" v-if="news && news.content.length">
                            <div class="company-page_news_bar_nav">
                                <div class="pagination-block">
                                    <div class="pagination-block_btn" @click="togglePage('prev')">
                                        <b-icon icon="chevron-left"></b-icon>
                                    </div>
                                    <div class="pagination-block_btn" @click="togglePage('next')">
                                        <b-icon icon="chevron-right"></b-icon>
                                    </div>
                                </div>
                                <span>{{ newsConfig.page }} of {{ news.pages }}</span>
                            </div>
                        </div>
                    </div>


                </div>

                <h1 class="company-page_block_title" v-html="$content('myCompany_news_title_2')"></h1>
                <div class="c_field_wrapper">
                    <div class="c_field c_field_search c_field_search_ref">
                        <b-icon icon="search"></b-icon>
                        <input
                            @input="getReferrals"
                            type="text"
                            v-model="refConfig.search"
                            :placeholder="$content('myCompany_searchInputPlaceholder')"/>
                    </div>
                    <div class="c_field c_field_auto" @click="toggleRefAsc">
                        <b-icon icon="sliders"></b-icon>
                        <span v-html="$content('myCompany_listFilterBtn')"></span>
                    </div>
                </div>

                <div class="company-page_user">

                    <div class="company-page_user_card">
                        <b-avatar class="company-page_user_card_profile" size="88"
                                  :src="image"></b-avatar>
                        <img class="company-page_user_card_avatar" :src="this.imageSelector($store.getters.logo)" alt="icon"/>
                        <h3>{{ $store.getters.user.account_id }}</h3>
                    </div>

                    <div class="company-page_user_info">
                        <div class="company-page_user_info_block1">
                            <span>{{ $store.getters.userName }}</span>
                            <h3>{{ $content('myCompany_user_level') }}-{{ ref_level }}</h3>
                        </div>
                        <div class="company-page_user_info_block2" v-if="refInfo">
                            <span><div v-html="$content('referral_team')"></div> - {{ refInfo.members_team_count ||  0 }}</span>
                            <span><div v-html="$content('referral_turnout')"></div> - {{ refInfo.team_turnover ||  0 }}</span>
                            <span><div v-html="$content('referral_personal_turnout')"></div> - {{ refInfo.personal_turnover ||  0 }}</span>
                        </div>
                    </div>

                </div>

                <div v-if="users && Object.keys(users).length">
                    <Referrals :title="level" v-for="(list, level) in users" :key="`ref${level}`">
                        <Referral v-for="referral in list" :key="`ref_${referral.id}`" :data="referral"></Referral>
                    </Referrals>
                </div>
                <div class="company-page_empty" v-else>
                    <span v-html="$content('no_referrals')"></span>
                </div>

                <div class="referrals-bottom" v-observe-visibility="visibilityChanged"></div>

            </div>
        </FullVwContainer>
        <Footer/>
    </Page>
</template>

<script>
import Page from "@/components/Page";
import FullVwContainer from "@/components/Wrappers/FullVwContainer";
import ProjectButton from "@/components/Buttons/ProjectButton";
import Referrals from "@/components/Wrappers/Referrals";
import Referral from "@/components/Referral";
import Footer from "../../components/Footer";

export default {
    name: "Company",
    components: {Footer, Referral, Referrals, ProjectButton, FullVwContainer, Page},
    data() {
        return {
            users: null,
            news: null,
            newsConfig: {
                page: 1,
                order_ascending: false,
                search: ''
            },
            refConfig: {
                page: 1,
                order_ascending: false,
                per_page: 20,
                search: ''
            },
            refInfo: null,
            selectedNews: []
        }
    },
    computed: {
        newsList() {
            return Boolean(this.selectedNews.length)
        },
        image() {
          // return this.$store.getters.user.user_photo
          return this.imageSelector(this.$store.getters.user.avatar);
        },
        ref_level() {
          return this.$store.getters.user.ref_level??0
          // return this.refInfo.referral_level??1
        }
    },
    methods: {
        getReferralInfo() {
            this.$http('referral').referralInfo()
                .then(res => {
                    const payload = this.$payloadHandler(res);

                    if (payload.result) {
                        this.refInfo = payload.result;
                    }
                })
        },
        toggleRefAsc() {
            this.refConfig.order_ascending = !this.refConfig.order_ascending;
            this.getReferrals();
        },
        visibilityChanged(status) {
            if (status) {
                this.refConfig.per_page = this.refConfig.per_page + this.refConfig.per_page;
                this.getReferrals();
            }
        },
        onNewsAction(action) {
            if (this.newsList) {
                switch (action) {
                    case 'delete':
                        this.onNewsDelete();
                        break;
                    case 'mailing':
                        this.onMailingStart();
                        break;
                }
            } else {
                this.$modal.open('Notify', {key: 'no_selected_news', type: 'fail'})
            }
        },
        onMailingStart() {
            this.$modal.open('Mailing', {color: 'black', ids: this.selectedNews})
        },
        onNewsDelete() {
            let ids = []
            this.selectedNews.forEach(x => ids.push(x.news_id))
            this.$modal.open('Confirm', {
                title: 'confirm-delete-news',
                confirm: {
                    repo: 'referral',
                    method: 'deleteNew',
                    data: ids
                }
            })
        },
        onParamsChange(type, prop, val) {
            this.selectedNews = [];
            switch (type) {
                case 'news':
                    this.newsConfig[prop] = val;
                    break;
            }
            this.getRefNews();
        },
        togglePage(way) {
            if (this.news) {
                switch (way) {
                    case 'prev':
                        if (this.newsConfig.page > 1) {
                            this.newsConfig.page--;
                            this.getRefNews();
                        }
                        break;
                    case 'next':
                        if (this.news.pages > this.newsConfig.page) {
                            this.newsConfig.page++;
                            this.getRefNews();
                        }
                }
            }
        },
        getRefNews() {
            this.$http('referral').getReferralNews(this.newsConfig)
                .then(res => this.news = res.data.payload)
        },
        getReferrals() {
            this.$http('referral').getRefList(this.refConfig)
                .then(res => {
                  this.users = res.data.payload.result;
                })
        }
    },
    created() {
        this.getRefNews();
        this.$root.$on('newsDeleted', () => {
            this.selectedNews = [];
            this.getRefNews();
        });
        this.getReferrals();
        this.getReferralInfo();
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixins";
@import "../../styles/variables";

.referrals-bottom {

}

.company-page {
    padding: 55px 10px 155px 10px;
    min-height: calc(100vh - #{ $standartHeaderHeight * 2 });

    @include only-xs {
        padding: 30px 10px 155px 10px;
    }

    &_empty {
        background: white;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        font-family: Montserrat-Regular, sans-serif;
    }

    &_user {
        padding: 30px 33px;
        background: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 22px;

        @include only-lg {
            padding: 30px 20px;
        }

        @include only-md-down {
            flex-direction: column;
        }

        &_info {
            margin-left: 47px;
            flex: auto;
            font-family: Montserrat-Medium, sans-serif;

            @include only-md-down {
                margin-top: 25px;
            }

            @include only-lg {
                margin-left: 20px;
            }

            @include only-md {
                margin-left: 18px;
            }

            @include only-md-down {
                margin: 25px 0 0 0;
            }


            &_block1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;

                @include only-xs {
                    flex-direction: column;
                }

                @include only-md-down {
                    margin-bottom: 25px;
                }

                & span {
                    font-size: 18px;
                    color: var(--main-dark);
                }

                & h3 {
                    font-size: 22px;
                    color: var(--main-app);
                    margin-bottom: 0;
                }
            }

            &_block2 {
                display: flex;
                font-family: Montserrat-Regular, sans-serif;
                color: grey;

                & span {
                    font-size: 16px;

                    @include only-xs {
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                    }

                    @include only-lg {
                        font-size: 13px;
                    }

                    @include only-md {
                        font-size: 12px;
                    }

                    &:nth-child(2) {
                        padding: 0 19px;
                        border-right: 1px solid silver;
                        border-left: 1px solid silver;
                        margin: 0 19px;

                        @include only-xs {
                            margin: 0 10px;
                            padding: 0 10px;
                        }
                    }
                }
            }
        }

        &_card {
            padding: 18px;
            display: flex;
            align-items: center;
            background: var(--main-light-silver);
            border-radius: 200px;

            &_profile {

                @include only-xs {
                    width: 50px !important;
                    height: 50px !important;
                }

                @include only-lg {
                    height: 68px !important;
                    width: 68px !important;
                }

                @include only-md {
                    height: 60px !important;
                    width: 60px !important;
                }
            }

            &_avatar {
                height: 40px;
                margin: 0 15px;

                @include only-xs {
                    height: 28px;
                    margin: 0 10px;
                }

                @include only-lg {
                    height: 25px;
                    margin: 0 11px;
                }

                @include only-md {
                    height: 20px;
                    margin: 0 10px;
                }
            }

            & h3 {
                font-family: Montserrat-Light, sans-serif;
                font-size: 44px;
                color: var(--main-dark);
                margin-bottom: 0;

                @include only-xs {
                    font-size: 16px;
                }

                @include only-lg {
                    font-size: 25px;
                }

                @include only-md {
                    font-size: 25px;
                }

            }
        }
    }

    &_news {

        &_empty {
            background: white;
            border-radius: 5px;
            padding: 10px;
            font-family: Montserrat-Regular, sans-serif;
            text-align: center;
            margin-bottom: 25px;
        }

        &_bar {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 60px;

            &_nav {
                display: flex;
                align-items: center;

                & span {
                    margin-left: 20px;
                    color: silver;
                }
            }
        }

        &_item {
            height: 64px;
            background: white;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e4e4e4;
            padding: 0 29px 0 20px;
            font-family: Montserrat-Medium, sans-serif;

            @include only-xs {
                flex-direction: column;
                align-items: unset;
                padding: 15px;
                height: unset;
            }

            &_date {
                font-size: 12px;
                color: grey;
            }

            &_event {
                width: 930px;
                padding: 0 34px;

                @include only-lg {
                    width: 670px;
                }

                @include only-md {
                    width: 610px;
                }

                @include only-sm {
                    width: 380px;
                }

                @include only-xs {
                    padding: 0;
                    width: 90%;
                }

                &_body {
                    width: 100%;

                    & h3 {
                        margin-bottom: 0;
                        font-size: 13px;
                        line-height: 1;
                        //text-overflow: ellipsis;
                        //overflow: hidden;
                        //white-space: nowrap;

                        @include only-xs {
                            width: 100%;
                            margin: 10px 0;
                            text-overflow: unset;
                            overflow: unset;
                            white-space: unset;
                        }
                    }
                }
            }

            &_user {
                width: 234px;
                border-right: 1px solid silver;

                @include only-md {
                    width: 170px;
                }

                @include only-sm {
                    width: 160px;
                }

                @include only-xs {
                    width: 100%;
                    border: 0;
                }

                & h3 {
                    font-size: 16px;
                    color: var(--main-dark);
                    margin-bottom: 0;
                    margin-left: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    @include only-xs {
                        margin: 0;
                    }
                }

            }

            &:first-child {
                border-radius: 10px 10px 0 0;
            }

            &:last-child {
                border-radius: 0 0 10px 10px;
                border-bottom: none !important;
            }
        }
    }

    &_block_title {
        font-size: 22px;
        color: black;
        font-family: Montserrat-Medium, sans-serif;
        margin-bottom: 23px;
    }

    &_title {
        font-family: Montserrat-Medium, sans-serif;
        font-size: 26px;
        color: var(--main-titles_secondary);
        text-align: center;
        font-weight: bolder;
    }
}

.c_field {
    background: white;
    border-radius: 5px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-family: Montserrat-Medium, sans-serif;
    font-size: 12px;

    &_spacer {

        @include only-xs {
            margin: 0 6px !important;
        }
    }

    &_search {

        &_ref {

            & input {
                width: 1050px !important;

                @include only-lg {
                    width: 800px !important;
                }

                @include only-md {
                    width: 670px !important;
                }

                @include only-sm {
                    width: 500px !important;
                }

                @include only-xs {
                    width: 80% !important;
                }
            }
        }

        @include only-xs {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    &_color {
        flex: unset !important;
        width: 122px;

        @include only-xs {
            flex: auto !important;
            width: unset;
        }

        & svg {
            color: green !important;
        }

        & span {
            color: var(--main-light-green) !important;
        }

        font-weight: bolder;
        text-transform: uppercase;
    }

    &_auto {
        flex: auto;
        margin-left: 6px;
        cursor: pointer;

        @include only-xs {
            display: flex;
            margin: 0;
            justify-content: center;
        }

        & span {
            color: var(--main-dark);

            @include only-xs {
                display: none;
            }
        }

        &:hover {
            background: #efefef;
        }
    }

    &_wrapper {
        display: flex;
        margin-bottom: 17px;

        @include only-xs {
            flex-wrap: wrap;
        }
    }

    & input {
        width: 750px;
        outline: none;
        border: none;

        @include only-lg {
            width: 550px;
        }

        @include only-md {
            width: 430px;
        }

        @include only-sm {
            width: 210px;
        }

    }

    & svg {
        height: 15px;
        color: grey;
        margin-right: 10px;
    }
}


</style>