import Repository from "../Repository";
import store from "../../store";

export default {
    getDepositForm() {
        return Repository.get('/deposit/form');
    },
    onDeposit(data) {
        // data.append("account_id", store.getters.user.account_id);
        return Repository.post('/deposit/form', data);
    },
    getWithdrawForm() {
        return Repository.get('/withdraw/form');
    },
    onWithdraw(data) {
        // data.append("account_id", store.getters.user.account_id);
        return Repository.post('/withdraw/form', data);
    }
};