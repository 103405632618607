import Vue from 'vue'
import VueRouter from 'vue-router'
import Root from "@/views/Root";
import Shop from '@/views/MAIN/Shop'
import Friends from "@/views/MAIN/Friends";
import Company from "@/views/MAIN/Company";
import WildCard from "@/views/WildCard";
import Article from "../views/MAIN/Article";
import Contracts from "../views/MAIN/Contracts";
import Mailing from "../views/MAIN/Mailing";
import MailingBody from "../components/MailingBody";

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Root,
    },
    {
        path: '/services',
        name: 'services',
        component: Shop,
    },
    {
        path: '/friends',
        name: 'friends',
        component: Friends,
    },
    {
        path: '/company',
        name: 'company',
        component: Company,
    },
    {
        path: '/article/:name',
        name: 'article',
        component: Article,
    },
    {
        path: '/contract',
        name: 'contract',
        component: Contracts
    },
    {
        path: '/mail',
        name: 'mail',
        component: Mailing,
        children: [
            {path: '/mail/:id', name: 'mail-body', component: MailingBody}
        ]
    },
    // {
    //     path: '*',
    //     name: 'wildcard',
    //     component: WildCard
    // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
