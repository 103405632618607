<template>
    <transition name="fade">
        <div class="page-modal"
             :style="{'background': $store.getters.isAuth ? 'var(--main-gradient_transparent)' : 'rgba(255,255,255,0.5)'}"
             v-if="modal">
            <div
                v-on-clickaway="away"
                class="page-modal_content"
                :style="customStyle(['width', 'box-shadow', 'overflow'])">
                <b-icon
                    :style="customStyle(['color'])"
                    @click="$modal.close()"
                    icon="x"
                    class="page-modal_content_close"></b-icon>
                <component v-if="el" :is="el"></component>
            </div>
        </div>
    </transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
    name: "Modal",
    mixins: [ clickaway ],
    data() {
        return {
            el: null,
            isMounted: false
        }
    },
    methods: {
        away() {
            this.$modal.close();
        },
        customStyle(props) {
            let obj = {};

            if (this.params) {
                props.forEach(property => this.params[property] ? obj[property] = this.params[property] : '')
            }

            return obj
        }
    },
    computed: {
        modal() {
            return this.$store.getters.activeModal
        },
        params() {
            return this.$store.getters.activeModalParams
        }
    },
    watch: {
        modal(name) {
            this.$store.dispatch('togglePopover', null);
            console.log(name);
            if (name) {
                import('./Modals/' + name)
                    .then(res => {
                        this.el = res.default;
                        this.isMounted = true;
                    })
            } else {
                this.isMounted = false;
                this.el = null;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/mixins";

@keyframes appear {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.page-modal {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    background-size: cover !important;
    z-index: 1000;
    overflow-y: auto;
    padding: 15px 0;
    display: flex;
    backdrop-filter: blur(2px);

    &_content {
        border-radius: 10px;
        //padding: 5px;
        box-shadow: 0px 0px 20px 1px #00000030;
        position: relative;
        animation: appear 0.3s ease-in-out;
        margin: auto;

        @include only-xs {
            width: 90% !important;
        }

        &_close {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            z-index: 1;
            transform: scale(1.6);

            &:hover {
                transform: scale(1.7);
            }

            @include only-xs {
                font-size: 17px;
            }
        }
    }
}
</style>