<template>
    <div class="exchanges-slider">


        <div class="exchanges-slider_body" v-if="isDeals">
            <Slicker name="list">
                <Slick v-for="exchange in deals" :key="exchange.id">
                    <div class="exchanges-slider_body_content">
                        <Exchange @swipe="onSlideToggle" :exchange="exchange"></Exchange>
                    </div>
                </Slick>
            </Slicker>
            <div class="exchanges-slider_body_control">
                <div class="exchanges-slider_body_control_box"></div>
                <div class="exchanges-slider_body_control_box">
                    <Prev @click.native="toggleSlider('left')" />
                    <Next @click.native="toggleSlider('right')" />
                </div>
                <div class="exchanges-slider_body_control_box">
                    <Pagination @dotClicked="toggleSlider" :items="deals.length" :active="activeListSlide" />
                </div>
            </div>
        </div>


        <div class="exchanges-slider_loader" v-show="!isDealsLoaded">
            <ExchangesLoader />
        </div>

        <div class="exchanges-slider_loader" v-if="noDeals">
            <NoDealsPlug />
        </div>

    </div>
</template>

<script>
import ExchangesLoader from "./ExchangesLoader";
import Slicker from "../Slider/Slicker";
import Slick from "../Slider/Slick";
import Exchange from "./Exchange";
import Prev from "../SVG/Prev";
import Next from "../SVG/Next";
import Pagination from "../Pagination";
import NoDealsPlug from "../Deals/NoDealsPlug";
export default {
    name: "ExchangesSlider",
    components: {NoDealsPlug, Pagination, Next, Prev, Exchange, Slick, Slicker, ExchangesLoader},
    methods: {
        onSlideToggle(data) {
            this.$store.dispatch('toggleSlicker', {name: 'list', payload: data, items: this.deals.length});
        },
        toggleSliderToStartPosition() {
            if (this.isDeals) {
                if (this.activeDeals && this.activeDeals.length) {
                    const activeExchangeIndex = this.deals.findIndex(x => x.state === 'processing');
                    this.toggleSlider(activeExchangeIndex);
                } else {
                    const firstAvailableIndex = this.deals.findIndex(x => x.state === 'available');
                    if (firstAvailableIndex === -1) {
                        this.toggleSlider(0)
                    } else {
                        this.toggleSlider(firstAvailableIndex);
                    }
                }
            }
        },
        toggleSlider(data) { // toggle slider, accept index or direction
            this.$root.$emit('dealsListToggle');
            this.$store.dispatch('toggleSlicker', {payload: data, name: 'list', items: this.deals.length});
        },
        fetchExchanges() { // fetching list
            this.$store.dispatch('exchanges.list')
        }
    },
    computed: {
        activeDeals() {
            return this.$store.getters.ex_active
        },
        deals() {
            return this.$store.getters.ex_list
        },
        noDeals() {
            return this.isDealsLoaded && !this.isDeals
        },
        isDeals() {
            return this.$store.getters.ex_list.length > 0
        },
        isDealsLoaded() {
            return this.$store.getters.ex_loaded
        },
        activeListSlide() {
            return this.$store.getters.slickers.list
        }
    },
    watch: {
        deals() {
            this.toggleSliderToStartPosition();
        }
    },
    created() {
        this.fetchExchanges();
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .exchanges-slider {
        width: 100%;
        height: 100%;

        &_errors {
            width: 215px;
        }

        &_body {
            overflow-x: clip;
            width: 860px;

            @include only-lg-exchanges {
                width: 770px;
            }

            @include only-md-exchanges {
                width: 770px;
                margin: 0 auto;
            }

            @include only-xs-exchanges {
                width: 100%;
            }

            &_content {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            &_control {
                display: flex;
                width: 100%;
                margin-top: 6px;

                @include only-xs-exchanges {
                    flex-direction: column-reverse;
                }

                &_box {
                    flex: 1;

                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    padding-bottom: 13px;

                    & svg {
                        height: 17px;
                        cursor: pointer;

                        @include only-xs-exchanges {
                            display: none !important;
                        }

                        &:first-child {
                            margin-right: 5px;
                            transform: translate(6px, 5px);
                        }

                        &:last-child {
                            transform: translateY(5px);
                            margin-left: 24px;
                        }
                    }
                }
            }
        }

        &_loader {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }
</style>