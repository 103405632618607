import auth from './modules/auth'
import content from "@/repository/modules/content";
import client from '@/repository/modules/client'
import exchanges from '@/repository/modules/exchanges'
import support from '@/repository/modules/support'
import mail from '@/repository/modules/mail'
import referral from "./modules/referral";
import transactions from './modules/transactions'
import user from "./modules/user";
import shop from "./modules/shop"

const repositories = {
    auth: auth,
    content: content,
    client: client,
    exchanges: exchanges,
    mail: mail,
    support: support,
    referral,
    transactions,
    user,
    shop
};

export const RepositoryFactory = {
    get: name => repositories[name]
};