<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 44.42 42.02" style="enable-background:new 0 0 44.42 42.02;" xml:space="preserve">
<path d="M34.08,28.1c2.55-1.13,4.23-3.69,4.23-6.49c0-3.92-3.19-7.1-7.1-7.1s-7.1,3.19-7.1,7.1c0,2.8,1.69,5.36,4.23,6.49
	c-5.25,1.34-9.02,6.27-9.02,11.86c0,0.51,0.4,0.92,0.9,0.92s0.9-0.41,0.9-0.92c0-5.73,4.53-10.38,10.09-10.38
	c5.57,0,10.09,4.66,10.09,10.38c0,0.51,0.4,0.92,0.9,0.92s0.9-0.41,0.9-0.92C43.1,34.37,39.33,29.45,34.08,28.1z M36.47,21.62
	c0,2.9-2.36,5.26-5.26,5.26s-5.26-2.36-5.26-5.26s2.36-5.26,5.26-5.26S36.47,18.72,36.47,21.62z"/>
<g>
	<path d="M35.91,0.33H4.09c-2.21,0-4,1.81-4,4.04v32.18c0,2.23,1.79,4.04,4,4.04h9.43c0,0,1.11,0.1,1.13-1.11
		c0.01-0.97-1.11-0.91-1.11-0.91H4.09c-1.1,0-2-0.91-2-2.02V4.38c0-1.12,0.9-2.02,2-2.02h31.82c1.1,0,2,0.91,2,2.02l-0.15,6.75
		c0,0,0.05,1.04,1.12,1.02c1.08-0.01,1.02-1.06,1.02-1.06l0-6.71C39.91,2.15,38.12,0.33,35.91,0.33z"/>
</g>
<path class="st0" d="M18.97,14.11H6.89c-0.57,0-1.04-0.46-1.04-1.04v-0.16c0-0.57,0.46-1.04,1.04-1.04h12.08
	c0.57,0,1.04,0.46,1.04,1.04v0.16C20,13.64,19.54,14.11,18.97,14.11z"/>
<path class="st0" d="M16.87,22.73H6.33c-0.57,0-1.04-0.46-1.04-1.04v-0.16c0-0.57,0.46-1.04,1.04-1.04h10.54
	c0.57,0,1.04,0.46,1.04,1.04v0.16C17.9,22.27,17.44,22.73,16.87,22.73z"/>
</svg>

</template>

<script>
export default {
name: "Step1"
}
</script>

<style scoped>

</style>