<template>
    <router-link :to="{name: 'mail-body', params: {id: dialog.id}}">
        <div class="dialog" :class="{'dialog-active': selectedDialog === dialog.id}">
            <div :style="{'background': dialog.unread_msgs ? 'var(--main-orange)' : ''}" class="dialog-label"></div>
            <b-avatar size="45px" variant="light" :src="this.imageSelector(dialog.image ? dialog.image : '/assets/img/no-user.png')" />
            <div class="dialog-text fit-text">
                <h1 v-html="dialog.title"></h1>
                <p v-html="dialog.last_msg_txt"></p>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "Dialog",
    props: ['dialog'],
    computed: {
        selectedDialog() {
            return this.$store.getters.selectedDialog
        }
    }
}
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
    .dialog {
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        padding: 0 10px 0 5px;
        border-radius: 200px;
        margin-bottom: 5px;

        &-active {
            background: var(--main-app);

            &:hover {
               background: var(--main-app) !important;
            }

            & h1, p {
                color: white !important;
            }
        }

        &:hover {
            background: var(--main-paper);
        }

        &-label {
            width: 8px;
            height: 8px;
            border-radius: 200px;
            margin-right: 10px;
            flex-shrink: 0;
        }

        &-text {
            margin-left: 10px;

            & h1, p {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            & h1 {
                font-size: 16px;
                color: var(--main-dark);
                margin-bottom: 0;
                width: 100%;
            }

            & p {
                font-size: 13px;
                color: grey;
                margin-bottom: 0;
            }
        }
    }
</style>