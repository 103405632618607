<template>
    <div class="monitor" v-touch:swipe="swipeHandler">

        <transition name="fade">
            <div
                class="monitor-label"
                :style="{'z-index': $store.getters.activePopover ? '0' : '50'}"
                v-if="deal"
                @click="$store.dispatch('exchanges.monitor', deal.id)">
                <span class="monitor-label_steps">{{ deal.processed_pair + 1 }}/{{ deal.pairs.length }}</span>
                <div class="monitor-label_bar">
                    <Prev class="monitor-label_bar_prev" />
                    <radial-progress-bar
                         class="monitor-label_bar_radial"
                        :inner-stroke-width="6"
                        :stroke-width="6"
                        stop-color="var(--main-app)"
                        start-color="var(--main-app)"
                        inner-stroke-color="#efefef"
                        :diameter="80"
                        :completed-steps="Math.round(deal.processing_duration) - Math.round(timeLeft)"
                        :total-steps="Math.round(deal.processing_duration)">
                        <div class="monitor-label_bar_inner">
                            <span>{{ processProgressPercent > 100 ? 100 : processProgressPercent }}%</span>
                        </div>
                    </radial-progress-bar>
                </div>
            </div>
        </transition>


        <transition name="fade">
            <div class="monitor-slider" v-if="deal && dealForSlider === deal.id && dealUpdated">

                <div class="monitor-slider_wrapper">

                    <DealMonitorSlide
                        :slide="slide"
                        :activeChain="activeChain"
                        :activeSlide="activeSlide"
                        @move="moveSlider"
                        v-for="slide in deal.pairs"
                        :key="slide.id" />

                </div>

                <div class="monitor-slider_pagination">
                    <Pagination :active="activeSlide" :items="deal.pairs.length" />
                </div>

            </div>
        </transition>

    </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import Prev from "../SVG/Prev";
import DealDetailsSliderPair from "./DealDetailsSliderPair";
import Pagination from "../Pagination";
import DealMonitorSlide from "./DealMonitorSlide";
export default {
    name: "DealMonitor",
    components: {DealMonitorSlide, Pagination, DealDetailsSliderPair, Prev, RadialProgressBar},
    props: ['id'],
    data() {
        return {
            deal: null,
            secondsToNextCheck: null,
            dealFinished: false,
            timeLeft: null,
            activeSlide: 0,
            dealUpdated: false
        }
    },
    computed: {
        dealForSlider() {
            return this.$store.getters.ex_monitor
        },
        ticker() {
            return this.$store.getters.ex_ticker
        },
        processProgressPercent() {
            return Math.round((this.deal.processing_duration - this.timeLeft) / this.deal.processing_duration * 100)
        },
        activeChain() {
            return this.deal ? this.deal.processed_pair : 0
        }
    },
    methods: {
        handleVisibilityChange() {
            if (document.hidden) {

            } else {
                this.getDealInfo();
            }
        },
        swipeHandler(e) {
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            if (vw <= 600) {
                switch (e) {
                    case 'left':
                        if (this.activeChain > this.activeSlide) {
                            this.moveSlider('next')
                        }
                        break;
                    case 'right':
                        if (this.activeSlide > 0) {
                            this.moveSlider('prev')
                        }
                        break;
                }
            }
        },
        moveSlider(direction) {
            switch (direction) {
                case 'prev':
                    this.activeSlide--;
                    break;
                case 'next':
                    this.activeSlide++;
                    break;
            }
        },
        getDealInfo() {
            this.$store.dispatch('processToggle', true);
            this.$store.dispatch('exchanges.check', this.id).then(deal => {
                this.deal = deal;

                if (!this.dealFinished) {
                    switch (deal.status) { // handle a fresh deal data status
                        case 'fail':
                            this.dealFinished = true;
                            this.$store.dispatch('exchanges.list');
                            this.$store.dispatch('exchanges.result', deal);
                            break;
                        case 'success':
                            this.dealFinished = true;
                            this.$store.dispatch('exchanges.list');
                            this.$store.dispatch('exchanges.result', deal);
                            break;
                        case 'processing':
                            this.secondsToNextCheck = Math.round(deal.pairs[deal.processed_pair].time_left) + 1;
                            this.timeLeft = deal.time_left;
                            this.activeSlide = deal.processed_pair;
                            this.dealUpdated = true;
                            break;
                    }
                }
            })
            .finally(() => this.$store.dispatch('processToggle', false))
        }
    },
    watch: {
        ticker() {
            if (!this.dealFinished) { // watching only if status processing
                if (this.secondsToNextCheck === 0) {
                    this.getDealInfo();
                } else {
                    this.secondsToNextCheck--;
                    this.timeLeft--;
                }
            }
        },
        dealForSlider: {
            handler: function (id) {
                if (id) {
                    this.getDealInfo();
                } else {
                    this.dealUpdated = false;
                }
            },
            immediate: true
        }
    },
    created() {
        this.getDealInfo();
        document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
    }
}
</script>

<style lang="scss">
    @import "../../styles/mixins";

    .monitor {

        &-label {
            position: fixed;
            top: 100px;
            right: 0;
            width: 135px;
            border-radius: 10px 0 0 10px;
            background: white;
            box-shadow: 0 0 7px 2px rgba(192,192,192,55%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px 0 20px 0;
            cursor: pointer;
            margin-bottom: 25px;
            transition: 0.3s;
            z-index: 50;

            &:hover {
                transform: scale(1.04);
            }

            &_steps {
                font-size: 13px;
                color: silver;
                margin-bottom: 5px;
                text-align: center;
            }

            &_bar {
                display: flex;
                align-items: center;

                &_prev {
                    height: 15px;
                    fill: black;
                    color: black;
                    margin-left: 10px;
                    margin-right: 7px;

                    & .st0 {
                        fill: var(--main-app);
                    }
                }

                &_inner {
                    height: 45px;
                    width: 45px;
                    background: var(--main-app);
                    border-radius: 200px;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Montserrat-Light, sans-serif;

                    &_small {
                        height: 33px;
                        width: 33px;
                        background: var(--main-app);
                        border-radius: 200px;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: Montserrat-Light, sans-serif;
                        font-size: 10px;
                    }
                }
            }
        }

        &-slider {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background: var(--main-gradient_transparent);
            z-index: 1000;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &_pagination {
                margin-top: 25px;
            }

            &_wrapper {
                height: 390px;
                position: relative;
                width: 100%;
                margin: 0 auto;
                display: flex;
                justify-content: center;

                @include only-xl {
                    width: $xl;
                }
            }
        }
    }
</style>