<template>
    <div class="land" id="land">
        <div class="land-banner">
            <h1 v-html="$content('landing_title1')"></h1>
            <h2 v-html="$content('landing_title2')"></h2>
            <VideoButton @click.native="$modal.open('Video', {url: 'land-video_1', width: '750px'})"></VideoButton>
        </div>
        <HalfBanner v-for="(banner, index) in 5" :key="`land_hb_${ index }`" :index="index + 1">
            <b-carousel :interval="10000" ref="landCarousel" img-height="500" img-width="1024" indicators v-if="index === 1">
                <b-carousel-slide :key="`landSlide${index}`" v-for="(slide, index) in 2">
                    <template v-slot:img>
                        <div class="land-slide">
                            <h1 v-html="$content(`landing_slider_slide${ index }_title`)"></h1>
                            <span v-html="$content(`landing_slider_slide${ index }_text`)"></span>
                            <ProjectButton
                                @click.native="$modal.open('Slider', {
                                    img: '/assets/img/banner3.png',
                                    index: slide - 1,
                                    width: '650px',
                                    color: 'white'})"
                                class="land-slide_btn"
                                variant="transparent">
                                <div v-html="$content(`landing_slider_button`)"></div>
                            </ProjectButton>
                            <div class="land-slide_toggle_prev" @click="prev">
                                <SlideArrow style="transform: rotate(180deg)"></SlideArrow>
                            </div>
                            <div class="land-slide_toggle_next" @click="next">
                                <SlideArrow></SlideArrow>
                            </div>
                        </div>
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </HalfBanner>
        <div class="land-video">
            <h1 v-html="$content('landing_video_title')"></h1>
            <VideoButton @click.native="$modal.open('Video', {url: 'land-video_2', width: '662px'})"></VideoButton>
        </div>
        <Steps></Steps>
        <FAQ></FAQ>
        <div class="land-footer">
            <div class="land-footer_info">
                <div class="land-footer_info_body">
                    <div class="land-footer_info_body_img"></div>
                    <div class="land-footer_info_body_content">
                        <h1 v-html="$content('landing_footer_title')"></h1>
                        <p v-html="$content('landing_footer_text')"></p>
                        <span @click="$modal.open('Terms', {width: '650px'})" v-html="$content('landing_footer_terms')"></span>

                        <div class="d-flex">
                            <div class="land-footer_info_socials" v-for="item in $store.getters.static.socials" :key="`social_${ item }`">
                                <b-icon :icon="item"></b-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="land-footer_panel">
                <div class="land-footer_panel_buttons">
                    <button
                        v-html="$content('landing_footer_button1')"
                        @click="$modal.open('Documents', {width: '500px'})"></button>
                    <button
                        v-html="$content('landing_footer_button2')"
                        @click="$modal.open('Partnership', {width: '500px'})"></button>
                </div>
                <div class="land-footer_panel_img">
                    <img
                        :src="getImages(index)"
                        alt="label"
                        v-for="index in 5"
                        :key="`footer_img${ index }`" />
                </div>
                <button
                    class="land-footer_subscribe"
                    v-html="$content('landing_footer_button3')"
                    @click="$modal.open('Partnership', {width: '500px'})"></button>
            </div>
        </div>
        <div class="land-footer_line"></div>
    </div>
</template>

<script>
import HalfBanner from "@/components/HalfBanner";
import VideoButton from "@/components/Buttons/VideoButton";
import Steps from "@/components/Steps";
import FAQ from "@/components/FAQ";
import ProjectButton from "@/components/Buttons/ProjectButton";
import SlideArrow from "../../components/SVG/SlideArrow";
export default {
    name: "Landing",
    components: {SlideArrow, ProjectButton, FAQ, Steps, VideoButton, HalfBanner},
    methods: {
        prev() {
            this.$refs.landCarousel[0].prev()
        },
        next() {
            this.$refs.landCarousel[0].next()
        },
        getImages(index) {
          return `/assets/img/brands${ index }.png`;
        },
    }
}
</script>

<style lang="scss">
    @import "../../styles/mixins";

    .carousel-control-prev-icon, .carousel-control-next-icon {
        @include only-xs {
            display: none !important;
        }
    }

    .land {
        height: 100%;

        .half-banner-container:nth-child(2 of .half-banner-container) {
          .half-banner_content {
            &_text {
              & h1 {
                width: 340px;
              }
            }
          }
        }

        &-footer {
            padding-top: 95px;
            //background: url('/assets/img/landing_map.jpg');
            background: url('/assets/img/1920 black.jpg');
            background-position: center;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include only-xs {
                padding-top: 40px;
            }

            &_line {
                height: 8px;
                width: 100%;
                background: var(--main-titles);
            }

            &_subscribe {
                width: 152px;
                height: 34px;
                background: var(--main-titles);
                font-family: Montserrat-SemiBold, sans-serif;
                font-size: 12px;
                border-radius: 200px;
                border: none;
                outline: none;
                color: white;
                transition: 0.3s;

                &:hover {
                    background: #0fa782;
                }
            }

            &_panel {
                width: 100%;
                padding: 48px 10% 54px 10%;
                background: #00000054;
                display: flex;
                align-items: center;
                margin-top: 90px;

                @include only-lg-down {
                    flex-direction: column;
                }

                @include only-lg {
                    padding: 40px;
                }

                @include only-md {
                    padding: 20px;
                }

                @include only-sm {
                    padding: 20px;
                }

                @include only-xs {
                    padding: 20px;
                    flex-direction: column;
                }

                &_img {
                    flex: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include only-lg-down {
                        margin: 25px 0;
                    }

                    @include only-xs {
                        flex-wrap: wrap;
                    }

                    & img {
                        margin: 0 10px;

                        @include only-xs {
                            margin: 10px;
                        }
                    }
                }

                &_buttons {

                    @include only-lg-down {
                        flex-direction: column;
                        align-items: center;
                        display: flex;
                    }

                    @include only-xs {
                        font-size: 13px;
                        margin-bottom: 25px;
                    }

                    & button {
                        height: 48px;
                        width: 180px;
                        background: none;
                        border: 2px solid white;
                        color: white;
                        border-radius: 200px;
                        margin-right: 10px;
                        font-size: 13px;

                        @include only-lg-down {
                            margin: 5px 0;
                        }

                        @include only-sm {
                            width: 150px;
                        }

                        &:hover {
                            background: white;
                            color: black;
                        }
                    }
                }
            }

            &_info {
                width: 80%;

                @include only-xs {
                    width: calc(100% - 15px);
                }

                &_body {
                    display: flex;
                    border-radius: 20px;
                    background: hsla(0,0%,100%,.87);
                    width: 700px;
                    height: 400px;

                    @include only-lg-down {
                        flex-direction: column;
                        height: 700px;
                        width: 100%;
                    }

                    &_img {
                        flex-basis: 50%;
                        background: url('/assets/img/company-house2.jpg');
                        border-radius: 20px;
                        background-size: 101%;
                        background-size: cover;
                        background-repeat: no-repeat !important;

                        @include only-lg-down {
                            background-size: cover !important;
                        }
                    }

                    &_content {
                        flex-basis: 50%;
                        padding: 25px 10px 25px 40px;

                        @include only-lg-down {
                            padding: 25px 15px;
                        }

                        & h1 {
                            color: var(--main-titles);
                            font-size: 34px;
                            font-family: Montserrat-SemiBold, sans-serif;
                            text-transform: uppercase;

                            @include only-lg-down {
                                font-size: 25px;
                            }
                        }

                        & p {
                            font-family: Montserrat-Light,sans-serif;
                            font-size: 18px;
                            color: var(--main-dark);
                            margin: 10px 0;

                            @include only-lg-down {
                                font-size: 15px;
                            }
                        }

                        & span {
                            color: var(--main-dark);
                            font-family: Montserrat-Medium, sans-serif;
                            font-size: 14px;
                            cursor: pointer;
                            text-decoration: underline;

                            &:hover {
                                color: var(--main-titles)
                            }
                        }
                    }
                }

                &_socials {
                    height: 25px;
                    width: 25px;
                    border-radius: 200px;
                    background: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    transition: 0.3s;
                    cursor: pointer;
                    margin-top: 25px;

                    &:hover {
                        transform: scale(1.1);
                    }

                    & svg {
                        font-size: 15px;
                    }
                }
            }
        }

        &-video {
            background: url('/assets/img/video2.png');
            height: 500px;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include only-xs {
                padding: 0 10px;
            }

            & h1 {
                font-family: Montserrat-SemiBold, sans-serif;
                font-size: 55px;
                color: var(--main-titles);
                margin-bottom: 70px;
                text-align: center;

                @include only-xs {
                    font-size: 35px;
                }
            }

            & button {
                color: white;
            }
        }

        &-slide {
            width: 100%;
            height: 500px;
            background: url('/assets/img/slider1.png') center center;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;

            &_toggle {

                &_prev {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    width: 15%;
                    padding: 0;
                    color: #fff;
                    text-align: center;
                    background: none;
                    border: 0;
                    opacity: .5;
                    transition: opacity .15s ease;
                    left: 0;

                    &:hover {
                        cursor: pointer;

                        & svg {
                            opacity: 1 !important;
                        }
                    }
                }

                &_next {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    width: 15%;
                    padding: 0;
                    color: #fff;
                    text-align: center;
                    background: none;
                    border: 0;
                    opacity: .5;
                    transition: opacity .15s ease;
                    right: 0;

                    &:hover {
                        cursor: pointer;

                        & svg {
                            opacity: 1 !important;
                        }
                    }
                }
            }

            & p {
                margin-bottom: 5px !important;
            }

            & h1, span {
                width: 570px;
                text-align: center !important;

                @include only-xs {
                    width: 80%;
                }
            }

            & h1 {
                font-family: Montserrat-SemiBold, sans-serif;
                text-transform: uppercase;
                font-size: 36px;
                color: var(--main-titles) !important;

                @include only-xs {
                    font-size: 30px;
                }
            }

            & span {
                font-size: 35px;
                font-family: Montserrat-Light, sans-serif;
                color: white;
                display: block;
                margin: 50px 0 62px 0;

                @include only-xs {
                    font-size: 20px;
                    margin: 25px 0 31px 0;
                }
            }

            &_btn {
                width: 217px;
                height: 65px;
                border-radius: 200px;
                font-family: Montserrat-Medium, sans-serif;
                font-size: 18px;
            }
        }

        &-banner {
            background-image: url('/assets/img/land_top_mask2.png'), url('/assets/img/land_top.jpg');
            background-repeat: no-repeat;
            background-size: cover !important;
            background-position: center center, no-repeat center / right;
            height: 100%;
            display: flex;
            flex-direction: column;
            color: white;
            align-items: center;
            justify-content: center;
            padding: 0 $mobilePadding;

            @include only-lg-down {
                height: unset;
                padding: 150px $mobilePadding 50px $mobilePadding;
                min-height: 600px;
            }

            @include only-xs {
                background-position: right center;
            }

            & h1, h2 {
                text-align: center;
                text-transform: uppercase;
            }

            & h1 {
                font-family: Montserrat-ExtraBold, sans-serif;
                font-size: 50px;

                @include only-xs {
                    font-size: 30px;
                }
            }

            & h2 {
                font-size: 30px;
                font-family: Montserrat-Light, sans-serif;
                margin-bottom: 53px;

                @include only-xs {
                    font-size: 20px;
                }
            }
        }
    }
</style>