<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 26.1 39.9" style="enable-background:new 0 0 26.1 39.9;" xml:space="preserve">
<g>
	<path class="st0" d="M17.7,20.6c-2.7-2.7-5.2-5.2-7.8-7.8c-2-2-4-4-6-6c-0.9-1-1-2.4-0.3-3.4C4.4,2.3,5.8,2,7,2.6
		C7.3,2.7,7.5,3,7.8,3.2C12.9,8.3,18,13.4,23.1,18.6c1.2,1.2,1.2,2.7,0,3.9C18,27.6,12.9,32.8,7.7,37.9C6.6,39,5,39,3.9,38
		c-1.1-1-1-2.7,0.1-3.8C8.4,29.8,17.5,20.8,17.7,20.6z"/>
</g>
</svg>

</template>

<script>
export default {
    name: "Next"
}
</script>

<style scoped>
    .st0 {
        fill:#FFFFFF;
    }
</style>