<template>
    <div class="cabinet-popover" v-on-clickaway="away">

        <div class="cabinet-popover_head">
            <b-icon @click="$store.dispatch('togglePopover', 'cabinet')" icon="chevron-left"></b-icon>
            <div class="cabinet-popover_head_last">
                <Avatar @click.native="$store.dispatch('togglePopover', 'cabinet')" size="40"></Avatar>
                <b-icon icon="gear" @click="onToggleForm('info')"></b-icon>
            </div>
        </div>

        <div class="cabinet-popover_body">
            <div class="cabinet-popover_user cabinet-popover_form" v-show="form === 'user'">

                <div class="d-flex flex-column">
                    <div class="cabinet-popover_name">
                        <div v-if="$store.getters.user.first_name || $store.getters.user.last_name">
                            <h1 v-html="$store.getters.user.first_name"></h1>
                            <h1 v-html="$store.getters.user.last_name"></h1>
                        </div>
                        <div v-else>
                            <h1 v-html="$store.getters.user.email"></h1>
                        </div>
                    </div>

                    <div class="user-popover_status">
                        <span v-html="$content('user-level')"></span>
                        <div>
                            <p v-html="$store.getters.user.ref_level"></p>
                        </div>
                    </div>

                    <div class="user-popover_block user-popover_referral">
                        <span v-html="$content('user-ref_link')"></span>
                        <p v-html="$store.getters.user.ref_link"></p>
                    </div>
                </div>

                <div class="user-popover_padding">
                    <div class="cabinet-popover_locales">
                        <LocalesRow />
                    </div>
                    <ProjectButton @click.native="logout" variant="orange">
                        <div content="w-100" v-html="$content('user-logout')"></div>
                    </ProjectButton>
                </div>
            </div>

            <div class="cabinet-popover_form" v-show="form === 'info'">
<!--                <div class="user-popover_avatar centered-x">-->
<!--                    <b-avatar :src="$store.getters.static.avatar" variant="light" size="88px"></b-avatar>-->
<!--                    <img class="user-popover_avatar_img" src="/assets/img/avatar.png">-->
<!--                </div>-->
<!--                <div class="user-popover_block">-->
<!--                    <AuthInput radius="200" :placeholder="$content('user-change_name')"></AuthInput>-->
<!--                    <AuthInput radius="200" :placeholder="$content('user-change_last_name')"></AuthInput>-->
<!--                    <AuthInput radius="200" :placeholder="$content('user-change_phone')"></AuthInput>-->
<!--                    <ProjectButton variant="lime">-->
<!--                        <div class="w-100" v-html="$content('user-change_button')"></div>-->
<!--                    </ProjectButton>-->
<!--                </div>-->
<!--                <FormWrapper-->
<!--                    name="info"-->
<!--                    class="user-popover_form_wrapper"-->
<!--                    mb="5px"-->
<!--                    repository="user"-->
<!--                    :methods="{preload: 'getInfoChangeForm', submit: 'submitInfoChangeForm'}">-->
<!--                        <template v-slot:info_avatar="props">-->
<!--                            <div class="user-popover_avatar mb-2">-->
<!--                                <div class="user-popover_avatar_change">-->
<!--                                    <b-avatar :src="props.data.value ? props.data.value : loadedImg" variant="secondary" size="60"></b-avatar>-->
<!--                                </div>-->
<!--                                <img @click="onAvatarChange" class="user-popover_avatar_img" src="/assets/img/avatar.png">-->
<!--                                <input @change="onAvatarLoaded" name="avatar" type="file" ref="avatar_change" />-->
<!--                            </div>-->
<!--                        </template>-->
<!--                        <template v-slot:info_first_name="props">-->
<!--                            <input :value="props.data.value" name="first_name" type="text" class="common-input" :placeholder="$content('user-change_name')" />-->
<!--                        </template>-->
<!--                        <template v-slot:info_last_name="props">-->
<!--                            <input :value="props.data.value" name="last_name" type="text" class="common-input" :placeholder="$content('user-change_last_name')" />-->
<!--                        </template>-->
<!--                        <template v-slot:info_phone="props">-->
<!--                            <input :value="props.data.value" name="phone" type="text" class="common-input" :placeholder="$content('user-change_phone')" />-->
<!--                        </template>-->

<!--                        <template v-slot:submit>-->
<!--                            <ProjectButton variant="lime">-->
<!--                                <div class="w-100" v-html="$content('user-change_button')"></div>-->
<!--                            </ProjectButton>-->
<!--                        </template>-->
<!--                </FormWrapper>-->

                <div class="p-3">
                <div class="user-popover_avatar">
                    <div class="user-popover_avatar_change">
                        <b-avatar :src="this.imageSelector(loadedImg ? loadedImg : user.user_photo)" variant="secondary" size="88px"></b-avatar>
                    </div>
                    <img @click="onAvatarChange" class="user-popover_avatar_img" src="/assets/img/avatar.png">
                    <input @change="onAvatarLoaded" name="avatar" type="file" ref="avatar_change" />
                </div>

                <input class="common-input" v-model="info.first_name" :placeholder="$content('user-change_name')" type="text">
                <input class="common-input" v-model="info.last_name" :placeholder="$content('user-change_last_name')" type="text">
                <input class="common-input" v-model="info.phone" :placeholder="$content('user-change_phone')" type="text">

                <ProjectButton @click.native="submit" class="w-100" variant="lime">
                    <div class="w-100" v-html="$content('user-change_button')"></div>
                </ProjectButton>
            </div>

            </div>
        </div>

    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import Avatar from "@/components/Avatar";
import ProjectButton from "@/components/Buttons/ProjectButton";
import AuthInput from "@/components/Inputs/AuthInput";
import LocalesRow from "../LocalesRow";
import FormWrapper from "../Wrappers/FormWrapper";
export default {
    name: "Cabinet",
    components: {FormWrapper, LocalesRow, AuthInput, ProjectButton, Avatar},
    mixins: [ clickaway ],
    data() {
        return {
            form: 'user',
            loadedImg: null,
            info: {
                avatar: '',
                first_name: '',
                last_name: '',
                phone: ''
            }
        }
    },
    methods: {
        submit() {
            this.$http('user').submitInfoChangeForm(this.info)
                .then(() => this.onToggleForm('user'))
        },
        onAvatarLoaded(e) {
            this.loadedImg = URL.createObjectURL(e.target.files[0]);
            this.info.avatar = e.target.files[0];
        },
        onAvatarChange() {
            this.$refs.avatar_change.click();
        },
        onToggleForm(name) {
            this.form = name

            if (name === 'info') {
                this.info.first_name = this.user.first_name;
                this.info.last_name = this.user.last_name;
                this.info.phone = 'no phone';
            }
        },
        logout() {
            this.$http('auth').logout()
        },
        away: function() {
            this.$store.dispatch('togglePopover', null)
        }
    },
    computed: {
        user() {
            return this.$store.getters.user
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/variables";
    @import "../../styles/mixins";

    $popoverBottomPadding: 15px;

    .cabinet-popover {
        height: 100%;
        padding: 0px 15px;
        z-index: 100;
        //display: flex;
        //flex-direction: column;

        &_locales {
            display: none;

            @include only-sm {
                display: flex;
                justify-content: center;
            }
        }

        &_form {
            width: 100%;
            padding-bottom: $popoverBottomPadding;
        }

        &_user {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: calc(100% - #{$standartHeaderHeight + $popoverBottomPadding});
        }

        &_name {
            width: 100%;
            margin-bottom: 25px;
            z-index: 100;

            & h1 {
                font-family: Montserrat-Medium, sans-serif;
                font-size: 18px;
                color: var(--main-dark);
                line-height: 1.3;
                margin-bottom: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &_head {
            display: flex;
            justify-content: space-between;
            height: $standartHeaderHeight;
            align-items: center;
            z-index: 100;

            & svg {
                font-size: 18px;
                color: grey;
            }

            &_last {
                display: flex;
                align-items: center;

                & svg {
                    margin-left: 10px;
                    width: 24px;
                }
            }
        }

        &_body {
            height: 100%;
            display: flex;
        }
    }
</style>