import Repository from "../Repository";
import store from "../../store";

export default {
    getPackages() {
        // return Repository.get(`/investment?account_id=${store.getters.user.account_id}&locale=${store.getters.locale}`);
        return Repository.get(`/investment`);
    },
    purchaseBuyWallet(data) {
        let fd = new FormData();
        for (let key in data) {
            fd.append(key, data[key])
        }
        // fd.append("account_id", store.getters.user.account_id);
        return Repository.post('/investment/purchase/wallet', fd);
    },
    psPurchaseForm(id) {
        return Repository.get(`/investment/purchase/ps/${id}`);
    },
    psPurchaseFormSubmit(id, data) {
        // data.append("account_id", store.getters.user.account_id);
        // data.append("locale", store.getters.locale);
        return Repository.post(`/investment/purchase/ps/${id}`, data);
    }
};