import { RepositoryFactory } from "@/repository/RepositoryFactory";
import moment from "moment";
const ExchangesRepository = RepositoryFactory.get('exchanges');

const state = {
    ex_process: false,
    ex_list: [],
    ex_update: null,
    ex_loaded: false,
    ex_ticker: 0,
    ex_monitor: null,
    ex_errors: null
}
const mutations = {
    'exchanges.errors.set': (state, errors) => {
        state.ex_errors = errors ? errors : null
    },
    'exchanges.list.set': (state, payload) => {
        if (payload) {
            state.ex_loaded = true;
            state.ex_list = payload.chain_list;
            state.ex_update = payload.update_time;
        } else {
            state.ex_loaded = false;
            state.ex_list = [];
            state.ex_update = null;
        }
    },
    'exchanges.monitor.set': (state, id) => {
        state.ex_monitor = id ? id : null;
    },
    processToggle(state, status) {
        state.ex_process = status;
    },
    updateTicker(state) {
        state.ex_ticker++;
    }
}
const actions = {
    processToggle({commit}, status) {
        commit('processToggle', status)
    },
    'exchanges.errors': ({ commit }, errors) => {
        commit('exchanges.errors.set', errors)
    },
    'exchanges.monitor': ({ commit }, id) => {
        commit('exchanges.monitor.set', id)
    },
    'exchanges.result': ({ dispatch }, deal) => {
        dispatch('openModal', {name: 'Result', params: {deal: deal, audio: true}})
    },
    'exchanges.check': ({ commit }, id) => {

        return new Promise((resolve, reject) => {
            ExchangesRepository.checkDeal(id)
                .then(res => {
                    resolve(res.data.payload.chain)
                })
        })
    },
    'exchanges.invest': ({ commit, dispatch }, data) => {
        commit('processToggle', true);

        return new Promise((resolve, reject) => {

            ExchangesRepository.invest(data)
                .then(res => { // Status handler here
                    const payload = res.data.payload;

                    if (payload.status === 'success') {
                        commit('exchanges.list.set', payload.result);
                        dispatch('exchanges.monitor', data.chain_id);
                    } else {
                        dispatch('exchanges.errors', payload.errors)
                    }
                })
                .finally(() => commit('processToggle', false))
        })
    },
    'exchanges.list': ({ commit }) => {
        commit('processToggle', true);
        commit('exchanges.list.set');
        return new Promise((resolve, reject) => {
            ExchangesRepository.getExchangesList()
                .then(res => {
                    console.log(res);
                    commit('exchanges.list.set', res.data.payload);
                    resolve(res);
                    commit('processToggle', false);
                })
                .catch(e => {
                    reject(e);
                    commit('processToggle', false);
                });
        })
    },
    'exchanges.calc': ({ commit }, params) => {
        commit('processToggle', true);

        return new Promise((resolve, reject) => {
            ExchangesRepository.calc(params)
                .then(res => {
                    resolve(res.data.payload);
                    commit('processToggle', false);
                })
                .catch(e => {
                    reject(e);
                    commit('processToggle', false);
                })
        })
    },
    'exchanges.leverage': ({ commit }, amount) => {
        return new Promise((resolve, reject) => {

            ExchangesRepository.getLeverageVariants(amount)
                .then(res => {
                    resolve(res.data.payload)
                })
        })
    },
    updateTicker({ commit }) {
        commit('updateTicker')
    }
}
const getters = {
    ex_process: state => state.ex_process,
    ex_list: state => state.ex_list,
    ex_update: state => moment.parseZone(state.ex_update).local().format('HH:mm'),
    ex_loaded: state => state.ex_loaded,
    ex_ticker: state => state.ex_ticker,
    ex_active: state => state.ex_list.length ? state.ex_list.filter(x => x.state === 'processing') : [],
    ex_monitor: state => state.ex_monitor,
    ex_errors: state => state.ex_errors
}


export default {
    state,
    mutations,
    actions,
    getters
}