<template>
    <div class="guest-header">
        <router-link to="/">
            <img class="heading-logo" :src="this.imageSelector(siteSettingsLogoUrl)" alt="logo" />
        </router-link>
        <div class="guest-header_control">

            <Chat @click.native="openPartnership" class="guest-header_mail" />

            <div class="guest-header_profile">
                <div class="guest-header_profile_user" @click="togglePopover">
                    <Login></Login>
                    <span v-html="setLoginBtn"></span>
                </div>
                <Popover name="auth">
                    <Auth />
                </Popover>
            </div>

            <div class="guest-header_profile_mobile" @click="togglePopover">
                <Login></Login>
                <span v-html="setLoginBtn"></span>
            </div>

            <LangSwitcher class="guest-header_locales" />
        </div>


        <b-sidebar
            v-if="!isAuth"
            :no-slide="true"
            bg-variant="transparent"
            :no-header="true"
            :visible="activePopover === 'auth'"
            @shown="show"
            @hidden="close"
            id="header-sidebar"
            sidebar-class="header-sidebar"
            shadow>
                <SidebarWrapper></SidebarWrapper>
        </b-sidebar>



    </div>
</template>

<script>
import LangSwitcher from "../../LangSwitcher";
import Login from "../../SVG/Login";
import Chat from "../../SVG/Chat";
import Popover from "../../Popover";
import Auth from "../../Popovers/Auth";
import SidebarWrapper from "../../Sidebars/SidebarWrapper";
export default {
    name: "GuestHeader",
    components: {SidebarWrapper, Auth, Popover, Chat, Login, LangSwitcher},
    methods: {
        show() {
            console.log('show')
            this.$store.dispatch('openSidebar', 'auth');
        },
        close() {
            console.log('close')
            this.$store.dispatch('closeSidebar');
        },
        togglePopover() {
          this.$store.dispatch('togglePopover', 'auth');
        },
        openPartnership() {
          this.$modal.open('Partnership', {width: '500px'})
        },
    },
    computed: {
      siteSettings() {
        return this.$store.getters.siteSettings
      },
      siteSettingsLogoUrl() {
        // if(this.$store.getters.logo) {
        //   return this.$store.getters.logo;
        // } else {
        //   return "";
        // }
        if(this.$store.getters?.siteSettings?.logo_url) {
          return this.$store.getters.siteSettings.logo_url;
        } else {
          return "";
        }
      },
      activePopover() {
        return this.$store.getters.activePopover
      },
      isAuth() {
        return this.$store.getters.isAuth
      },
      setLoginBtn() {
        return this.$content('header_loginBtn')
      },
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../styles/mixins";

    .guest-header {
        display: flex;
        width: 100%;
        padding: 0 45px;
        justify-content: space-between;
        align-items: center;

        @include only-xs {
            padding: 0 15px;
        }

        &_locales {

            @include only-xs {
                margin-left: 15px;
            }
        }

        &_mail {
            height: 49px;
            //fill: #b7b7b7;
            fill: #777777;
            transition: 0.3s;

            @include only-xs {
                height: 35px;
            }

            &:hover {
                fill: var(--main-dark-paper);
                cursor: pointer;
                transform: scale(1.09);
            }
        }

        &_control {
            display: flex;
            align-items: center;
            height: 100%;
        }

        &_profile {
            margin: 0 38px 0 53px;
            cursor: pointer;
            position: relative;

            @include only-xs {
                display: none;
            }

            &_mobile {
                display: none;

                & svg {
                    height: 25px;
                    margin-left: 15px;
                    fill: var(--main-titles);
                    margin-right: 5px;
                }

                & span {
                    font-family: Montserrat-Medium, sans-serif;
                    font-weight: bolder;
                    color: var(--main-titles);
                    font-size: 14px;
                }

                @include only-xs {
                    display: flex;
                    align-items: center;
                }
            }

            & span {
                font-family: Montserrat-Medium, sans-serif;
                font-weight: bolder;
                margin-left: 10px;
                color: var(--main-titles);
                font-size: 16px;
            }

            & svg {
                height: 28px;
                margin-right: 9px;
                fill: var(--main-titles);
            }
        }

        &_logo {
            height: 45px;
        }
    }
</style>