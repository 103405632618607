<template>
    <div class="auth-sidebar">
        <div class="auth-sidebar_logo">
            <img :src="this.imageSelector($store.getters.logo)" alt="logo">
        </div>
        <div class="auth-sidebar_form">
            <div v-if="form === 'login'">
                <AuthInput
                    @input="removeErrors"
                    name="email"
                    :warnings="errors.login"
                    :submitted="isSubmitted"
                    placeholder="login"
                    v-model="payload.login.email"></AuthInput>
                <AuthInput
                    @input="removeErrors"
                    name="password"
                    type="password"
                    :warnings="errors.login"
                    :submitted="isSubmitted"
                    placeholder="password"
                    v-model="payload.login.password"></AuthInput>
                <span @click="toggleForm('forgot_password')" class="warn-text">Forgot Pass?</span>
                <ProjectButton @click.native="login" class="mt-3" variant="lightBlue" radius="10">Login</ProjectButton>
                <ProjectButton
                    @click.native="toggleForm('registration')"
                    variant="paper"
                    radius="10"
                    size="40">registr</ProjectButton>
            </div>

            <div v-if="form === 'registration'">
                <AuthInput
                    @input="removeErrors"
                    name="email"
                    :warnings="errors.registration"
                    :submitted="isSubmitted"
                    placeholder="email"
                    v-model="payload.registration.email"></AuthInput>
                <AuthInput
                    @input="removeErrors"
                    name="password"
                    type="password"
                    :warnings="errors.registration"
                    :submitted="isSubmitted"
                    placeholder="pass"
                    v-model="payload.registration.password"></AuthInput>
                <AuthInput
                    name="password_confirm"
                    @input="removeErrors"
                    type="password"
                    :warnings="errors.registration"
                    :submitted="isSubmitted"
                    placeholder="repass"
                    v-model="payload.registration.password_confirm"></AuthInput>
                <ProjectButton @click.native="register" class="mt-3" variant="lightBlue" radius="10">Registr</ProjectButton>
                <ProjectButton
                    @click.native="toggleForm('login')"
                    variant="paper"
                    radius="10"
                    size="40">login</ProjectButton>
            </div>

            <div v-if="form === 'forgot_password'">
                <p class="auth-popover_text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam eaque eligendi et impedit iste maiores modi nesciunt saepe, sed tempore!</p>
                <AuthInput placeholder="email"></AuthInput>
                <ProjectButton variant="lightBlue" radius="10">Send</ProjectButton>
            </div>

        </div>
    </div>
</template>

<script>
import AuthInput from "@/components/Inputs/AuthInput";
import ProjectButton from "@/components/Buttons/ProjectButton";
import qs from "qs";
export default {
    name: "Auth",
    components: {ProjectButton, AuthInput},
    data() {
        return {
            form: 'login',
            payload: {
                login: {
                    email: null,
                    password: null
                },
                registration: {
                    email: null,
                    password: null,
                    password_confirm: null
                },
                recover: {
                    email: null
                }
            },
            isSubmitted: false,
            errors: {
                login: {},
                registration: {}
            }
        }
    },
    methods: {
        removeErrors() {
            Object.keys(this.errors).forEach(i => this.errors[i] = null);
        },
        toggleForm(name) {
            this.isSubmitted = false;
            this.form = name
        },
        login() {
            this.isSubmitted = true;
            this.$utils.validateForm(this.payload.login)
                .then(() => {
                    this.$Progress.start();
                    this.$http('auth').login(qs.stringify(this.payload.login))
                        .then(() => {
                            this.$Progress.finish()
                        })
                        .catch(e => {
                            this.$Progress.fail();
                            this.errors[this.form] = e.response.data.response.errors;
                        })
                })
        },
        register() {
            this.isSubmitted = true;
            this.$utils.validateForm(this.payload.registration)
                .then(() => {
                    this.$Progress.start();
                    this.$http('auth').signUp(qs.stringify(this.payload.registration))
                        .then(() => {
                            this.$Progress.finish()
                        })
                        .catch(e => {
                            this.$Progress.fail();
                            this.errors[this.form] = e.response.data.response.errors;
                        })
                })
        }
    }
}
</script>

<style lang="scss">
    @import "../../styles/mixins";

    .auth-popover_text {
        font-family: Montserrat-Medium, sans-serif;
        font-size: 15px;
        line-height: 1.2;
        color: var(--main-dark-paper);
    }
    .auth-sidebar {

        &_logo {
            height: 200px;
            width: 200px;
            border-radius: 200px;
            background: var(--main-light-blue);
            margin: 0 auto;
            margin-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
                height: auto;
                width: 50%;
            }
        }

        &_form {
            padding-top: 25px;
            width: 300px;
            margin: 0 auto;

            @include only-sm {
                width: 60%;
            }

            & input {
                background: white;

                &::placeholder {
                    color: grey;
                }
            }
        }
    }
</style>