<template>
    <div class="auth-input_wrapper">
        <input
            :readonly="readonly"
            :style="{'border-radius': radius ? radius + 'px' : ''}"
            :class="{'warning-input': isErrored}"
            :value="value"
            @input="handleInput($event.target.value)"
            class="auth-input"
            :type="type ? type : 'text'"
            :placeholder="$content(placeholder)"  />
        <p class="warn-text" v-if="submitted && !value">*fill this field</p>

        <div v-if="warnings && warnings[name]">
            <p class="warn-text" v-for="val in warnings[name]">{{ val }}</p>
        </div>

        <slot></slot>

    </div>
</template>

<script>
export default {
    name: "AuthInput",
    props: ['placeholder', 'value', 'submitted', 'warnings', 'name', 'radius', 'type', 'readonly'],
    computed: {
        isErrored() {
            return (this.submitted && !this.value) || (this.warnings && this.warnings[this.name])
        }
    },
    methods: {
        handleInput(val) {
            this.$emit('input', val)
        }
    }
}
</script>

<style lang="scss" scoped>
    .auth-input_wrapper {
        width: 100%;

        & input {
            width: 100%;
            padding-left: 22px;
            font-family: Montserrat-Medium, sans-serif;
            font-size: 15px;
            color: var(--main-dark-paper);
        }

        & p {
            margin-bottom: 5px;
        }
    }
</style>