import Repository from "../Repository";
import store from "../../store";

export default {
    getInfoChangeForm() {
        // return Repository.get(`/user/form?account_id=${store.getters.user.account_id}`);
        return Repository.get(`/user/form`);
    },
    submitInfoChangeForm(data) {
        let fd = new FormData();
        for (let key in data) {
            fd.append(key, data[key])
        }
        // fd.append("account_id", store.getters.user.account_id);
        return Repository.post('/user/form', fd);
    }
};