<template>
    <div class="locales-popover">
        <p
            @click="toggleLocale(locale)"
            :key="'locale_' + locale"
            v-for="locale in $store.getters.locales">{{ locale }}</p>
    </div>
</template>

<script>
export default {
    name: "Locales",
    methods: {
        toggleLocale(locale) {
            if (locale !== this.$store.getters.locale) {
                this.$http('client').toggleLocale(locale)
                    .then((res) => {
                      // console.log(locale, this.$store.getters.locale);
                      // window.location.reload();
                      if(res) {
                        this.$store.commit("setLocale", locale);
                        // localStorage.setItem('vuex_state', JSON.stringify(this.$store.state));
                        window.location.reload();
                      }
                    })
            } else {
                this.$store.dispatch('togglePopover', null);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .locales-popover {
        background: white;
        width: 124px;
        border-radius: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 22px;

        @include only-sm {
            width: 60px;
            padding: 22px 5px;
        }

        @include only-xs {
            width: 60px;
            padding: 22px 5px;
        }

        & p {
            margin: 0;
            font-size: 15px;
            font-family: Montserrat-Medium, sans-serif;
            color: var(--main-dark-paper);
            text-align: center;
            border-radius: 200px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 80%;
            text-transform: uppercase;

            &:hover {
                background: var(--main-titles);
                color: white;
            }
        }
    }
</style>