<template>
    <transition name="fade">
        <div class="page-popover" v-if="$store.getters.activePopover === name" v-on-clickaway="away">
            <div class="page-popover_content">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';

export default {
    name: "Popover",
    props: ['name'],
    mixins: [clickaway],
    methods: {
        away: function () {
            if (this.$store.getters.activePopover === 'auth') {
                if (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 750) {
                    return false
                } else {
                    this.$store.dispatch('togglePopover', null)
                }
            } else {
                this.$store.dispatch('togglePopover', null)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.page-popover {
    position: absolute;
    top: calc(100% + 18px);
    left: 50%;
    transform: translate(-50%, 0);

    &_content {
        background: white;
        border-radius: 10px;
        box-shadow: 0 0 20px 7px #9e9e9e61;
    }

    &:after {
        position: absolute;
        width: 25px;
        height: 20px;
        content: '';
        top: -2px;
        background: white;
        bottom: calc(100% - 15px);
        left: 50%;
        transform: translate(-50%, 0) rotate(135deg) scale(0.9);
    }
}
</style>