<template>
    <div class="user-header">

        <div class="user-header_side">
            <router-link class="d-flex align-items-center" to="/">
                <img class="heading-logo" :src="this.imageSelector($store.getters.siteSettings.logo_url)" alt="logo" />
            </router-link>
            <div class="user-header_links">
                <div
                    :class="{'user-header_links_item_active': $route.path === link.url}"
                    class="user-header_links_item"
                    v-for="link in links">
                    <router-link :to="link.url">
                        {{ link.title }}
                    </router-link>
                </div>
            </div>
        </div>

        <div class="user-header_mail">
            <router-link :to="{name: 'mail'}">
                <Chat />
            </router-link>
            <Counter v-if="$store.getters.counters" :getter="$store.getters.counters.unread_chats" />
        </div>

        <div class="user-header_side">

            <div class="user-header_buttons">
                <button @click="openOperationModal('withdraw')" v-html="$content('header-withdraw')"></button>
                <button @click="openOperationModal('deposit')" v-html="$content('header-deposit')"></button>
            </div>

            <div class="user-header_control">

                <div class="user-header_control_user">
                    <div class="d-flex align-items-center" @click="$store.dispatch('togglePopover', 'user')">
                        <Avatar />
                        <div class="user-header_control_name" ref="user_name">{{ $store.getters.userName.split(' ')[0] }}</div>
                    </div>
                    <Popover name="user">
                        <User />
                    </Popover>
                </div>

                <div class="user-header_control_user_mobile">
                    <Avatar @click.native="$store.dispatch('togglePopover', 'cabinet')" />
                    <b-icon
                        @click="$store.dispatch('openSidebar', 'menu')"
                        class="user-header_control_user_mobile_hamburger"
                        icon="list"></b-icon>
                </div>

                <LangSwitcher class="user-header_control_locale" />
            </div>

        </div>

        <b-sidebar
            bg-variant="transparent"
            :visible="$store.getters.activeSidebar === 'menu'"
            @hidden="$store.dispatch('closeSidebar')"
            class="menu-sidebar"
            id="menu-sidebar"
            right
            no-header
            title="Sidebar"
            shadow>
            <template #default>
                <Menu />
            </template>
        </b-sidebar>

    </div>
</template>

<script>
import LangSwitcher from "../../LangSwitcher";
import Chat from "../../SVG/Chat";
import Popover from "../../Popover";
import User from "@/components/Popovers/User";
import Menu from "@/components/Sidebars/Menu";
import Avatar from "../../Avatar";
import Counter from "../../Counter";
export default {
    name: "UserHeader",
    components: {Counter, Avatar, Menu, Popover, Chat, LangSwitcher, User},
    computed: {
        links() {
            return this.$utils.getMenu('header_menu')
        }
    },
    methods: {
        openOperationModal(operation) {
            this.$modal.open('Operation', {
                width: '409px',
                xColor: 'var(--main-app)',
                type: operation
            })
        },
        handleNameWidth() {
            const nameElement = this.$refs.user_name;
            if (nameElement && nameElement.getBoundingClientRect())
            if (Number(nameElement.getBoundingClientRect().width) > 180) {
                this.$refs.user_name.style.width = '180px'
            }
        }
    },
    mounted() {
        this.handleNameWidth()
    },
    updated() {
        this.handleNameWidth()
    },
    created() {
        window.addEventListener('resize', () => this.handleNameWidth());
    }
}
</script>

<style lang="scss">
    @import "../../../styles/mixins";
    @import "../../../styles/variables";

    #menu-sidebar {
        width: 100%;
        background: rgba(255,255,255,0.95) !important;
    }

    .user-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 45px;

        @include only-lg {
            padding: 0 55px;
        }

        @include only-md {
            padding: 0 55px;
        }

        @include only-md-down {
            padding: 0 15px;
        }

        &_control {
            display: flex;
            align-items: center;

            &_user {
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;

                &_mobile {
                    display: none;

                    &_hamburger {
                        transform: scale(1.4);
                        margin-left: 15px;
                        display: none !important;

                        @include only-xs {
                            display: block !important;
                        }
                    }

                    @include only-md-down {
                        display: flex;
                        align-items: center;
                    }
                }

                @include only-md-down {
                    display: none;
                }
            }

            &_locale {
                margin-left: 36px;

                @media (max-width: 1350px) {
                    margin-left: 10px;
                }

                @include only-xs {
                    display: none;
                }
            }

            &_name {
                font-family: Montserrat-Medium, sans-serif;
                font-size: 16px;
                color: #373737;
                margin-left: 7px;
                text-overflow: ellipsis;
                overflow-x: hidden;

                @media (max-width: 1350px) {
                    display: none !important;
                    width: 0 !important;
                }
            }
        }

        &_buttons {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @include only-xs {
                display: none;
            }

            & button {
                border-radius: 200px;
                background: none;
                outline: none;
                font-family: Montserrat-Medium, sans-serif;
                font-size: 13px;
                height: 33px;
                text-transform: uppercase;
                padding: 0 16px;
                font-weight: bold;

                @include only-md-down {
                    font-size: 11px;
                    padding: 0 15px !important;
                }

                &:first-child {
                    border: 2px solid #aedf5a;
                    color: #aedf5a;
                    margin-right: 3px;

                    &:hover {
                        background: #aedf5a;
                        color: white;
                    }
                }

                &:last-child {
                    border: 2px solid #5dd5b5;
                    color: #5dd5b5;
                    padding: 0 25px;

                    &:hover {
                        background: #5dd5b5;
                        color: white;
                    }
                }
            }
        }

        &_links {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @include only-xs {
                display: none;
            }

            &_item {
                padding: 0 15px;
                border-radius: 200px;
                height: 35px;
                display: flex;
                align-items: center;
                cursor: pointer;

                @include only-md-down {
                    padding: 0 10px;
                }

                &_active {
                    background: #f2f2f2;

                    a {
                        color: var(--main-app) !important;
                    }
                }

                &:hover {

                    a {
                        color: var(--main-app) !important;
                    }
                }
            }

            & a {
                font-size: 13px;
                color: #373737;
                font-family: Montserrat-Medium, sans-serif;
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: none;

                @include only-md-down {
                    font-size: 11px;
                }
            }
        }

        &_side {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include only-xs {
                flex: unset;
            }
        }

        &_mail {
            position: relative;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 40px;

            @include only-xs {
                display: none;
            }

            @include only-md {
                margin: 0 20px;
            }

            @include only-sm {
                margin: 0 15px;
            }

            & svg {
                width: 35px;
                height: 50px;
                z-index: 10;
            }

            &:hover {
                background: var(--main-gradient);
                border-radius: 200px;

                & svg {
                    fill: white;
                    margin-right: 1px;
                }
            }
        }

    }
</style>