import Repository from "../Repository";
import Vue from '../../main'

export default {
    toggleLocale(lang) {
        return Repository.post(`/lang`, {lang: lang})
    },
    supportAppeal(data) {
        let form = new FormData();
        form.append('email', data.email);
        form.append('text', data.text)

        return Repository.post('/support_appeal', form)
    },
    test() {
        return Repository.get(`/rdr/first`)
    },
    progressAPI() {
        console.log(Vue)
    }
}
