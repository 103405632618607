import Repository from "../Repository";

export default {
    getChatList() {
        return Repository.get('/chat/list')
    },
    startChat(recipient, data) {
        let formData = new FormData;
        if (data) {
            for (let key in data) {
                formData.append(key, data[key]);
            }
        }

        return Repository.get(`/chat/private/${ recipient }`, formData)
    },
    send(recipient, data) {
        return Repository.post(`/chat/private/${ recipient }`, data)
    },
    checkAsRead(recipient, id) {
        let formData = new FormData;
        formData.append('last_read_message', id)

        return Repository.post(`/chat/private/read/${ recipient }`, formData)
    }
}
