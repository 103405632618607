import dynamicContentComp from "./component";
import Vue from 'vue';
import store from "../../store";

function difference(set1, set2) {
    return new Set([...set1].filter(x => !set2.has(x)));
}

const Observer = (new Vue()).$data.__ob__.constructor;

function preventUpdate(obj, func) {
    // prevents from update when obj changes inside func

    let old = Object.assign({}, obj.__ob__);
    obj.__ob__= new Observer({});
    func();
    obj.__ob__= old;
}

const CACHING = false;
const PRELOAD = true;

const DynamicContent = {
    install(Vue, url, router, axios, store) {

        Vue.component('DContent', dynamicContentComp);
        let component = new Vue({
            created() {
                if (CACHING) {
                    if (!localStorage.content) {
                        localStorage.setItem('content', JSON.stringify({}));
                    }
                    this.content = JSON.parse(localStorage.getItem('content'))
                }

                if (PRELOAD) {
                    this.fetchContent();
                }

                this.content[this.currentLanguage] = {};
            },
            data() {
                return {
                    content: {},
                    keys: [],
                    fetching: false
                }
            },
            methods: {
                async fetchByKeys() {
                    let keys = JSON.parse(JSON.stringify(this.keys));
                    keys = Array.from(difference(new Set(keys), new Set(Object.keys(this.content))));
                    if(keys.length) {
                        this.fetchContent(keys)
                    }
                },
                fetchContent(keys) {
                    if ( !this.fetching) {
                        this.fetching = true;
                        // console.log("lang::: ", this.currentLanguage)
                        let data = { lang: this.currentLanguage };
                        if (keys) {
                            data.keys = keys;
                        }
                        // console.log(url);
                        // url = 'http://localhost:5000/api/content'
                        axios.post(url, data).then(function (response) {
                            // console.log(response);
                            this.content[this.currentLanguage] = Object.assign(this.content[this.currentLanguage], response.data.payload);
                            if (CACHING) {
                                localStorage.setItem('content', JSON.stringify(this.content));
                            }
                            this.keys = [];
                            this.fetching = false;
                        }.bind(this))
                            .catch(function () {
                                this.fetching = false;
                                // console.error('Can`t fetch content. \n', err);
                            }.bind(this));
                    }
                    if(false) {
                        console.log(this.content);
                    }
                },
            },
            computed: {
                $content() {
                    return function (key) {
                        if (!key) {
                            throw `Invalid key provided: ${key}`
                        }
                        if (this.content[this.currentLanguage][key]) {
                            return this.content[this.currentLanguage][key];
                        } else {
                            preventUpdate(this.keys, () => this.keys.push(key));
                            return ``
                        }
                    }.bind(this)
                },
                currentLanguage() {
                    // return 'ru'
                    // console.log("lang: ", store.getters, store.getters.locale, localStorage.getItem('vuex_state'));
                    // return store.getters.locale
                    return JSON.parse(localStorage.getItem('vuex_state'))?.site_settings?.locale??"ru";
                }
            },
            watch: {
                currentLanguage() {
                    if(PRELOAD) {
                        this.fetchContent();
                    }
                    if(!this.content[this.currentLanguage]){
                        this.content[this.currentLanguage] = {};
                    }
                    this.fetchByKeys();
                }
            }
        });

        Vue.prototype.$content = component.$content;
        router.afterEach(() => {
            Vue.nextTick(function () {
                component.fetchByKeys()
            })
        });

        Vue.mixin({
            updated: function () {
                Vue.nextTick(function () {
                    component.fetchByKeys()
                });
            }
        })
    }
};

export default DynamicContent;