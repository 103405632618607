const state = {
    active: null,
    sidebars: ['auth'],
    selectedForm: null
}
const mutations = {
    togglePopover(state, name) {
        state.active === name ? state.active = null : state.active = name;
    },
    setPopoverForm(state, form) {
        state.selectedForm = form;
    }
}
const actions = {
    togglePopover({ commit }, name) {
        commit('setPopoverForm', null)
        commit('togglePopover', name)
    },
    togglePopoverWithForm({ commit }, payload) {
        commit('togglePopover', payload.popover);
        commit('setPopoverForm', payload.form)
    }
}
const getters = {
    activePopover: state => state.active,
    isSidebar: state => state.sidebars.includes(state.active),
    activePopoverForm: state => state.selectedForm
}

export default {
    state,
    mutations,
    actions,
    getters
}