<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 322.1 322.9" style="enable-background:new 0 0 322.1 322.9;" xml:space="preserve">
<g>
	<path class="st0" d="M164.5,316.6C77.7,316.6,7.1,246,7.1,159.2S77.7,1.9,164.5,1.9s157.4,70.6,157.4,157.4
		S251.3,316.6,164.5,316.6z M164.5,10.9c-81.8,0-148.4,66.6-148.4,148.4c0,81.8,66.6,148.4,148.4,148.4S312.8,241,312.8,159.2
		C312.8,77.4,246.3,10.9,164.5,10.9z"/>
</g>
<g>
	<polygon class="st0" points="211.1,224 211.1,224 211.1,224 	"/>
	<path class="st0" d="M236.4,85.3c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1
		c-0.1-1.3-0.7-2.6-1.7-3.6c-1.7-1.6-4.2-1.9-6.2-0.9c0,0,0,0,0,0l-0.1,0.1c0,0,0,0-0.1,0L65.4,164.9c-1.9,1-3.1,3.1-2.9,5.2
		c0.2,2.2,1.6,4,3.7,4.7l59.8,20.4v45.1c0,2.3,1.5,4.4,3.7,5.2c0.6,0.2,1.1,0.3,1.7,0.3v0c1.7,0,3.3-0.8,4.4-2.2l26.4-36l47.2,16.1
		c0.6,0.2,1.2,0.3,1.8,0.3c1,0,2-0.3,2.8-0.8c1.4-0.8,2.3-2.2,2.6-3.8L236.4,85.3C236.4,85.3,236.4,85.3,236.4,85.3L236.4,85.3z
		 M205.4,104l-75.6,81.1l-48.2-16.5L205.4,104z M136.8,223.8V199l14.6,5L136.8,223.8z M206.7,211.4l-65.6-22.4l82-87.9L206.7,211.4z
		"/>
</g>
</svg>

</template>

<script>
export default {
    name: "Chat"
}
</script>

<style scoped>

</style>