var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"menu-sidebar_header"},[_c('img',{staticClass:"heading-logo",attrs:{"src":this.imageSelector(_vm.$store.getters.siteSettings.logo_url),"alt":"logo"}}),_c('div',{staticClass:"menu-sidebar_header_control"},[_c('Avatar'),_c('b-icon',{staticClass:"menu-sidebar_header_control_close",attrs:{"icon":"x"},on:{"click":function($event){return _vm.$store.dispatch('closeSidebar')}}})],1)]),_c('div',{staticClass:"menu-sidebar_body"},[_c('div',{staticClass:"menu-sidebar_buttons"},[_c('button',{staticClass:"menu-sidebar_buttons_withdraw",domProps:{"innerHTML":_vm._s(_vm.$content('header-withdraw'))},on:{"click":function($event){_vm.$modal.open('Operation', {
                            width: '409px',
                            xColor: 'var(--main-app)',
                            type: 'withdraw'})}}}),_c('button',{staticClass:"menu-sidebar_buttons_deposit",domProps:{"innerHTML":_vm._s(_vm.$content('header-deposit'))},on:{"click":function($event){_vm.$modal.open('Operation', {
                            width: '409px',
                            xColor: 'var(--main-app)',
                            type: 'deposit'})}}})]),_c('div',{staticClass:"menu-sidebar_mail"},[_c('router-link',{attrs:{"to":{name: 'mail'}}},[_c('Chat',{staticClass:"menu-sidebar_chat"}),(_vm.$store.getters.counters)?_c('Counter',{attrs:{"getter":_vm.$store.getters.counters.unread_chats}}):_vm._e()],1)],1),_c('div',{staticClass:"menu-sidebar_links"},_vm._l((_vm.$utils.getMenu('header_menu')),function(link){return _c('router-link',{key:`link-${link.title}`,attrs:{"to":link.url}},[_vm._v(" "+_vm._s(link.title)+" ")])}),1),_c('div',{staticClass:"menu-sidebar_locales"},[_c('LangSwitcher')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }