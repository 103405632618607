import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './plugins/Utils'
import directives from './plugins/directives'
import exchanges from './plugins/exchanges'
import VueProgressBar from 'vue-progressbar'
import dynamicContent from './plugins/dynamic-content/index'
import Repository from './repository/Repository'
import TextareaAutosize from 'vue-textarea-autosize'
import Viewer from 'v-viewer'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vue2TouchEvents from 'vue2-touch-events'
import VueObserveVisibility from 'vue-observe-visibility'
import VueHead from 'vue-head'
import VueClipboard from 'vue-clipboard2'

import 'viewerjs/dist/viewer.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./styles/global.css"

Vue.use(VueHead)
Vue.use(VueClipboard)
Vue.use(VueObserveVisibility)
Vue.use(BootstrapVue)
Vue.use(Vue2TouchEvents, {
    disableClick: false,
    touchClass: '',
    tapTolerance: 10,
    touchHoldTolerance: 400,
    swipeTolerance: 30,
    longTapTimeInterval: 400,
    namespace: 'touch'
})
Vue.use(dynamicContent, '/content', router, Repository, store);
Vue.use(IconsPlugin)
Vue.use(TextareaAutosize)
Vue.use(VueProgressBar, {
    color: 'var(--main-orange)',
    failedColor: 'red',
    height: '3px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    },
})
Vue.use(Viewer)
Vue.config.productionTip = false;

export default new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
