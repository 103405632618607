<template>
    <div class="referrals-list">
        <div class="referrals-list_body" v-html="`${ title }${ $content('level-end') } ${ $content('level') }`"></div>
        <div class="referrals-list_wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Referrals",
    props: ['title']
}
</script>

<style lang="scss" scoped>
    .referrals-list {
        margin-top: 50px;

        &_wrapper {
            margin-top: -7px;
        }

        &_body {
            display: flex;
            color: white;
            font-family: Montserrat-Medium, sans-serif;
            font-size: 16px;
            padding-top: 6px;
            justify-content: center;
            height: 45px;
            background: var(--main-app);
            border-radius: 5px 5px 0 0;
        }
    }
</style>