import login from "../../components/SVG/Login.vue";

const state = {
    modal: {
        name: null,
        params: null
    }
}
const mutations = {
    openModal(state, payload) {
        console.log(payload);
        // console.log("OpenModal mutation");
        // console.log(state.modal.name, state.modal.params);
        Object.keys(state.modal).forEach(k => state.modal[k] = null);
        if(state.modal.name == null || state.modal.params == null) {
            // console.log("modal data will change");
            state.modal.name = payload.name;
            state.modal.params = payload.params;
            // console.log("modal data changed");
        }
    },
    closeModal(state, callback) {
        // console.log("modal is closed")
        Object.keys(state.modal).forEach(k => state.modal[k] = null);
    }
}
const actions = {
    openModal({ commit }, payload) {
        commit('openModal', payload);
    },
    closeModal({ commit }) {

        return new Promise((resolve) => {
            commit('closeModal');
            resolve(true);
        })
    }
}
const getters = {
    activeModal: state => {
        // console.log("active modal", state);
        return state.modal.name;
    },
    activeModalParams: state => state.modal.params
}

export default {
    state,
    mutations,
    actions,
    getters
}