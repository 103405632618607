var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"novelty"},[_c('div',{staticClass:"novelty-body"},[_c('div',{on:{"click":function($event){return _vm.$modal.open('Novelty', {
                                            width: '800px',
                                            color: 'white',
                                            overflow: 'unset',
                                            selected: _vm.novelty.slug,
                                            list: _vm.news,
                                            'box-shadow': 'none'
                                        })}}},[_c('div',{staticClass:"novelty-body_image"},[_c('img',{attrs:{"src":this.imageSelector(_vm.novelty.image_url ? _vm.novelty.image_url : '/assets/img/novelty-img.jpg')}})]),_c('span',[_vm._v(_vm._s(new Date(_vm.novelty.publish_date).toLocaleDateString()))]),_c('h1',[_vm._v(_vm._s(_vm.novelty.title))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.novelty.body)}})]),(_vm.novelty.video_url)?_c('div',{staticClass:"novelty-body_video",on:{"click":function($event){return _vm.$modal.open('Video', {url: 'https://www.youtube.com/embed/lH1F6m3slrQ', width: '762px'})}}},[_c('b-icon',{attrs:{"icon":"play-fill"}}),_vm._v(" "+_vm._s(_vm.$content('novelty_play'))+" ")],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }