<template>
    <div class="user-popover">

        <div class="user-popover_control">
            <b-icon @click="onBack" icon="chevron-left"></b-icon>
            <b-icon @click="toggleForm('info')" v-show="form === 'user'" icon="gear-fill"></b-icon>
        </div>

        <div class="user-popover_form" v-if="form === 'user'">
            <Avatar size="88" />
            <div class="user-popover_block">
                <div v-if="$store.getters.user.first_name || $store.getters.user.last_name">
                    <h3 v-html="$store.getters.user.first_name"></h3>
                    <h3 v-html="$store.getters.user.last_name"></h3>
                </div>
                <div v-else>
                    <h3 v-html="$store.getters.user.email"></h3>
                </div>
            </div>
            <div class="user-popover_status">
                <span v-html="$content('user-level')"></span>
                <div class="d-flex align-items-center">
                    <img :src="this.imageSelector($store.getters.logo)" alt="icon"/>
                    <p v-html="$store.getters.user.ref_level"></p>
                </div>
            </div>
            <div class="user-popover_block user-popover_referral">
                <span v-html="$content('user-ref_link')"></span>
                <div @click="onCopy" v-clipboard:copy="$store.getters.user.ref_link">
                    <p id="ref-link" v-html="$store.getters.user.ref_link"></p>
                </div>
<!--                <div @click="onCopy" v-clipboard:copy="$store.getters.user.account_id">-->
<!--                    <p id="ref-link" v-html="$store.getters.user.account_id"></p>-->
<!--                </div>-->
            </div>
            <div class="user-popover_padding w-100">
                <ProjectButton
                    class="mt-3"
                    @click.native="logout"
                    variant="orange">
                    <div content="w-100" v-html="$content('user-logout')"></div>
                </ProjectButton>
            </div>
        </div>

        <b-tooltip :disabled="tooltipHidden" ref="tooltip" target="ref-link">
            <div v-html="$content('copied')"></div>
        </b-tooltip>

        <div class="user-popover_form" v-if="form === 'info'">

            <div class="p-3">
                <div class="user-popover_avatar">
                    <div class="user-popover_avatar_change">
                        <b-avatar :src="image" variant="secondary" size="88px"></b-avatar>
                    </div>
                    <img @click="onAvatarChange" class="user-popover_avatar_img" src="/assets/img/avatar.png">
                    <input @change="onAvatarLoaded" name="avatar" type="file" ref="avatar_change" />
                </div>

                <input class="common-input" v-model="info.first_name" :placeholder="$content('user-change_name')" type="text">
                <input class="common-input" v-model="info.last_name" :placeholder="$content('user-change_last_name')" type="text">
                <input class="common-input" v-model="info.phone" :placeholder="$content('user-change_phone')" type="text">

                <ProjectButton @click.native="submit" class="w-100" variant="lime">
                    <div class="w-100" v-html="$content('user-change_button')"></div>
                </ProjectButton>
            </div>

<!--            <FormWrapper-->
<!--                name="info"-->
<!--                class="user-popover_form_wrapper"-->
<!--                @submitted="toggleForm('user')"-->
<!--                mb="10px"-->
<!--                repository="user"-->
<!--                :methods="{preload: 'getInfoChangeForm', submit: 'submitInfoChangeForm'}">-->
<!--                    <template v-slot:info_avatar="props">-->
<!--                        <div class="user-popover_avatar">-->
<!--                            <div class="user-popover_avatar_change">-->
<!--                                <b-avatar-->
<!--                                    :src="loadedImg ? loadedImg : props.data.value"-->
<!--                                    variant="secondary"-->
<!--                                    size="88px"></b-avatar>-->
<!--                            </div>-->
<!--                            <img @click="onAvatarChange" class="user-popover_avatar_img" src="/assets/img/avatar.png">-->
<!--                            <input-->
<!--                                @change="onAvatarLoaded"-->
<!--                                :value="loadedImg"-->
<!--                                name="avatar"-->
<!--                                type="file"-->
<!--                                ref="avatar_change" />-->
<!--                        </div>-->
<!--                    </template>-->
<!--                    <template v-slot:info_first_name="props">-->
<!--                        <input :value="props.data.value" name="first_name" type="text" class="common-input" :placeholder="$content('user-change_name')" />-->
<!--                    </template>-->
<!--                    <template v-slot:info_last_name="props">-->
<!--                        <input :value="props.data.value" name="last_name" type="text" class="common-input" :placeholder="$content('user-change_last_name')" />-->
<!--                    </template>-->
<!--                    <template v-slot:info_phone="props">-->
<!--                        <input :value="props.data.value" name="phone" type="text" class="common-input" :placeholder="$content('user-change_phone')" />-->
<!--                    </template>-->

<!--                    <template v-slot:submit>-->
<!--                        <ProjectButton style="width: 100%" variant="lime">-->
<!--                            <div class="w-100" v-html="$content('user-change_button')"></div>-->
<!--                        </ProjectButton>-->
<!--                    </template>-->
<!--            </FormWrapper>-->

        </div>

    </div>
</template>

<script>
import ProjectButton from "@/components/Buttons/ProjectButton";
import AuthInput from "@/components/Inputs/AuthInput";
import FormWrapper from "../Wrappers/FormWrapper";
import Avatar from "../Avatar";
import store from "../../store";
export default {
name: "User",
    components: {Avatar, FormWrapper, AuthInput, ProjectButton},
    data() {
        return {
            tooltipHidden: true,
            tooltipLock: false,
            form: 'user',
            loadedImg: null,
            info: {
                avatar: '',
                first_name: '',
                last_name: '',
                phone: ''
            }
        }
    },
    methods: {
        onCopy() {
            this.$root.$emit('bv::show::tooltip', 'ref-link');
        },
        submit() {
            this.$http('user').submitInfoChangeForm(this.info)
                .then((res) => {
                  // if(res.data.payload.status == "success") {
                  //   this.$http('user').getInfoChangeForm(this.info)
                  //       .then((res) => {
                  //         if(res.data.payload.status == "success") {
                  //           let newData = res.data.payload.form;
                  //
                  //           Object.keys(newData).forEach(key => {
                  //             if(newData[key].value == 'undefined' || newData[key].value == 'no phone') {
                  //               delete newData[key];
                  //             } else {
                  //               newData[key] = newData[key].value;
                  //             }
                  //           });
                  //           // console.log(newData);
                  //           // store.dispatch(`refresh_personal_info`, newData);
                  //           // localStorage.setItem('vuex_state', JSON.stringify(this.$store.state));
                  //         }
                  //       })
                  // }
                  this.toggleForm('user');
                })
        },
        onAvatarLoaded(e) {
            this.loadedImg = URL.createObjectURL(e.target.files[0]);
            this.info.avatar = e.target.files[0];
        },
        onAvatarChange() {
            this.$refs.avatar_change.click();
        },
        toggleForm(name) {
            this.form = name;

            if (name === 'info') {
                this.info.first_name = this.user.first_name;
                this.info.last_name = this.user.last_name;
                this.info.phone = 'no phone';
            }
        },
        onBack() {
            switch (this.form) {
                case "user":
                    this.$store.dispatch('togglePopover', null)
                    break;
                case "info":
                    this.toggleForm('user');
                    break;
            }
        },
        logout() {
            this.$store.dispatch('logout');
        }
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
        image() {
          return this.imageSelector(this.$store.getters.user.user_photo);
          // return this.imageSelector(this.$store.getters.user.avatar);
        }
    },
}
</script>

<style lang="scss">
    $sidePagging: 24px;

    .user-popover {
        width: 260px;
        padding: 15px 0 10px 0;

        &_avatar {
            position: relative;
            cursor: pointer;
            margin-bottom: 15px;

            & input {
                display: none;
            }

            &_change {
                opacity: 0.3;
                display: flex;
                justify-content: center;
            }

            &_img {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                top: 50%;
            }
        }

        &_referral {
            background: var(--main-light-silver);
            display: flex;
            flex-direction: column;
            align-items: center;

            & span {
                font-size: 12px;
                font-family: Montserrat-Medium, sans-serif;
                color: var(--main-silver);
            }

            & p {
                font-size: 14px;
                font-family: Montserrat-Light, sans-serif;
                color: var(--main-dark);
                text-align: center;
                margin-bottom: 0;
                word-break: break-all;
            }
        }

        &_status {
            border-top: 1px solid var(--main-paper);
            font-size: 14px;
            color: var(--main-silver);
            font-family: Montserrat-Medium, sans-serif;
            display: flex;
            justify-content: space-between;
            height: 40px;
            align-items: center;
            width: 100%;
            padding: 0 $sidePagging;

            & img {
                height: 25px;
                margin-right: 5px;
            }

            & p {
                margin-bottom: 0;
                color: var(--main-dark);
            }
        }

        &_control {
            display: flex;
            justify-content: space-between;
            padding: 0 17px;

            & svg {
                color: grey;
                cursor: pointer;

                &:hover {
                    color: black;
                }
            }
        }

        &_form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            &_wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                & a {
                    width: 100%;
                }
            }

            h3 {
                font-size: 16px;
                font-family: Montserrat-Medium, sans-serif;
                color: var(--main-dark);
                font-weight: bolder;
                text-align: center;
                line-height: 1;
                margin-bottom: 5px;
            }
        }

        &_padding {
            width: 100%;
            padding: 0 $sidePagging;
        }

        &_block {
            width: 100%;
            padding: 15px $sidePagging;

            & h3 {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            & input {
                background: var(--main-light-silver) !important;
                box-shadow: none;
            }
        }
    }
</style>