const state = {
    menu: {}
}
const mutations = {
    setMenus(state, data) {
        state.menu = data;
    }
}
const actions = {
    setMenus({ commit }, data) {
        commit('setMenus', data)
    }
}
const getters = {
    menus: () => ['header_menu', 'footer_menu'],
    menus_list: state => state.menu
}

export default {
    state,
    mutations,
    actions,
    getters
}