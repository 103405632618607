<template>
    <div class="balance-label">
        <div class="balance-label_inner">
            <div class="balance-label_content" v-if="displayPs && displayBalance">
                <div class="balance-label_content_img">
                    <img :src="this.imageSelector(displayPs.logo_url)" :alt="displayPs.alt">
                </div>
                <div class="balance-label_content_amount">
                    <p v-html="$content('platform_circle_title')"></p>
                    <p>{{ $store.getters.static.symbol }}{{ spacesAmount(displayBalance.amount) }}</p>
                </div>
                <button @click="$modal.open('Balance', {width: '400px', color: 'var(--main-app)'})">
                    <span v-html="$content('platform_circle_button')"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectButton from "@/components/Buttons/ProjectButton";
export default {
    name: "BalanceCircle",
    components: {ProjectButton},
    computed: {
        displayPs() {
            return this.$store.getters.displayPs
        },
        displayBalance() {
          if(this.$store.getters.displayBalance.amount) {
            return this.$store.getters.displayBalance;
          } else {
            return {amount: 0};
          }
        }
    },
    methods: {
        spacesAmount(x) {
          if (x || x == 0) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return parts.join(".");
          }

          return ''
        }
    }
}
</script>

<style lang="scss">
    @import "../styles/mixins";
    $wrapper: 398px;
    $minWrapper: 300px;

    .balance-label {
        height: $wrapper;
        width: $wrapper;
        border: 2px solid rgba(255, 255, 255, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 200px;

        @include only-xs {
            height: $minWrapper;
            width: $minWrapper;
        }

        &_inner {
            height: 344px;
            width: 344px;
            background: white;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: -1px 4px 12px 0px rgba(0,0,0, 0.15);
            border-radius: 200px;


            @include only-xs {
                height: calc(#{$minWrapper} - 25px);
                width: calc(#{$minWrapper} - 25px);
            }
        }

        &_content {
            height: calc(#{$wrapper} - 73px);
            width: calc(#{$wrapper} - 73px);
            border: 2px solid #e4f4c8;
            background: white;
            position: relative;
            border-radius: 200px;
            padding: 25px 0 50px 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include only-xl-down {
                justify-content: space-between;
            }

            @include only-xs {
                height: calc(#{$minWrapper} - 50px);
                width: calc(#{$minWrapper} - 50px);
                padding: 15px 0;
            }

            &_amount {
                padding-top: 5px;
                margin-bottom: 45px;

                & p {
                    margin-bottom: 0;
                    line-height: 1;
                    text-align: center;

                    &:first-child {
                        font-size: 20px;
                        letter-spacing: 1px;
                        font-family: Montserrat-Light, sans-serif;
                        color: #c5e790;
                        font-weight: bold;

                    }

                    &:last-child {
                        margin-top: 7px;
                        font-size: 70px;
                        font-family: Acrobat-Light, sans-serif;
                        color: var(--main-blue);
                        background: var(--main-gradient);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;

                        @include only-xs {
                            font-size: 50px;
                        }
                    }
                }
            }

            & button {
                width: 81px !important;
                height: 37px;
                background: none;
                outline: none;
                border-radius: 200px;
                border: 2px solid var(--main-light-blue);
                color: var(--main-light-blue);
                font-family: Montserrat-Medium, sans-serif;
                font-size: 16px;

                & span {
                    display: block;
                    margin-top: 3px;
                }

                @include only-xs {
                    font-size: 15px;
                }

                &:hover {
                    background: var(--main-light-blue);
                    color: white;
                }
            }

            &_img {
                height: 60px;

                & img {
                    height: auto;
                    width: 60px;
                }
            }
        }
    }
</style>