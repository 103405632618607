<template>
    <div class="exdlist-leverage">
        <h3 v-html="$content('timer_toogle_modal_title')"></h3>
        <div v-if="variants">
            <div class="exdlist-leverage_item" v-for="(item, id) in variants" :key="id" @click="onSelect(id)">
                <div class="exdlist-leverage_item_body"
                     :class="{'exdlist-leverage_item_active': id === selectedVariantId}">
                    <img :src="this.imageSelector($store.getters.logo)" alt="">
                    <span>{{ item.multiplier }}</span>
                    <p v-html="$content('timer_toogle_modal_btn')"></p>
                </div>
                <div class="exdlist-leverage_item_info">
                    <p v-html="$content('timer_toogle_modal_apply')"></p>
                    <span>${{ item.total_amount }}</span>
                </div>
            </div>
        </div>
        <b-card v-else>
            <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
            <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
            <b-skeleton height="50px" animation="wave" width="100%"></b-skeleton>
        </b-card>

        <div class="exdlist-leverage_errors" v-if="submitted">
            <p v-if="!userAccept" v-html="$content('leverage_accept_error')"></p>
            <p v-if="!selectedVariantId" v-html="$content('leverage_id_error')">Select a variant please</p>
        </div>
        <div class="exdlist-leverage_rules">
            <b-form-checkbox
                style="margin-left: 10px"
                id="credit-shoulder-rules"
                v-model="userAccept"
                name="credit-shoulder-rules"
                :value="true"
                :unchecked-value="false"></b-form-checkbox>
            <span v-html="$content('leverage_rules')"></span>
        </div>

        <ProjectButton @click.native="invest" variant="app">
            <div v-html="$content('timer_toogle_modal_change')"></div>
        </ProjectButton>
        <ProjectButton @click.native="close" variant="paper">
            <div v-html="$content('timer_toogle_modal_cancel')"></div>
        </ProjectButton>
    </div>
</template>

<script>
import ProjectButton from "../Buttons/ProjectButton";

export default {
    name: "ExchangeLeverage",
    components: {ProjectButton},
    props: ['id', 'amount'],
    data() {
        return {
            userAccept: false,
            variants: null,
            selectedVariantId: null,
            submitted: false
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        invest() {
            this.submitted = true;
            if (this.userAccept && this.selectedVariantId) {
                const data = {
                    leverage_id: this.selectedVariantId,
                    agreetment: this.userAccept,
                    chain_id: this.id
                }
                this.$store.dispatch('exchanges.invest', data);
            }
        },
        onSelect(id) {
            this.selectedVariantId = id;
        }
    },
    created() {
        this.$store.dispatch('exchanges.leverage', this.amount)
            .then(payload => {
                if (payload.status === 'success') {
                    this.variants = payload.result
                } else {
                    this.close()
                }
            })
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixins";
@import "../../styles/z-indexes";

.exdlist-leverage {
    width: 100%;
    background: white;
    border-radius: 15px;
    padding: 19px 26px 23px 26px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.42);
    transition: 0.3s;
    position: static;

    &_errors {
        text-align: center;

        & p {
            margin-bottom: 0;
            color: red;
            font-family: Montserrat-Medium, sans-serif;
            font-size: 14px;
        }
    }

    @include only-xs {
        padding: 23px 10px;
    }

    &_rules {
        display: flex;
        align-items: center;
        margin: 12px 0 22px 0;

        & span {
            font-family: Montserrat-Medium, sans-serif;
            font-size: 14px;
            color: var(--main-dark);
        }
    }

    & h3 {
        font-family: Acrobat-Light, sans-serif;
        font-size: 18px;
        color: grey;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    &_item {
        border-radius: 200px;
        background: #f3f3f3;
        height: 90px;
        margin-bottom: 9px;
        display: flex;
        align-items: center;
        justify-content: center;

        &_active {
            background: var(--main-app-transparent);

            & span {
                color: black !important;
            }

            & p {
                color: black !important;
            }
        }

        &_info {
            margin-left: 21px;

            @include only-xs {
                margin-left: 10px;
            }

            & p {
                font-family: Montserrat-Medium, sans-serif;
                font-size: 12px;
                color: var(--main-app);
                margin-bottom: 0px;
                line-height: 1;
            }

            & span {
                font-size: 22px;
                font-family: Acrobat-Light, sans-serif;
                color: var(--main-dark);
                line-height: 1;

                @include only-xs {
                    font-size: 18px;
                }
            }
        }

        &_body {
            width: 170px;
            height: 43px;
            border-radius: 200px;
            border: 2px solid var(--main-app);
            display: flex;
            align-items: center;
            padding-right: 10px;

            @include only-xs {
                width: unset;
                padding: 0 10px;
            }

            &:hover {
                background: #78d82247;
                cursor: pointer;

                & p, span {
                    color: var(--main-dark);
                }
            }

            & img {
                width: 17px;
                margin-left: 16px;
                margin-right: 7px;
            }

            & span {
                margin-right: 7px;
                font-family: Acrobat-Light, sans-serif;
                font-size: 21px;
                color: grey;
                line-height: 1;
                margin-bottom: 3px;
            }

            & p {
                margin-bottom: 0;
                font-family: Montserrat-Medium, sans-serif;
                font-size: 12px;
                text-transform: uppercase;
                color: var(--main-app);
                line-height: 1;
                margin-left: auto;
                padding-left: 15px;


                @include only-xs {
                    font-size: 10px;
                }
            }
        }
    }
}
</style>