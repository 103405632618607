<template>
    <div class="steps">
        <div class="steps-content">
            <div>
                <h1 v-html="$content('landing_steps_title')"></h1>
                <h2 v-html="$content('landing_steps_title2')"></h2>
                <h3 v-html="$content('landing_steps_title3')"></h3>
            </div>
            <div class="steps-content_list">
                <Step color="var(--main-titles)" v-for="step in 4" :index="step" :isLast="step === 4"></Step>
            </div>
            <div class="d-flex justify-content-center">
                <button
                    @click="$store.dispatch('togglePopoverWithForm', {popover: 'auth', form: 'registration'})"
                    v-html="$content('landing_steps_button')"></button>
            </div>
        </div>
    </div>
</template>

<script>
import Step1 from "@/components/SVG/Step1";
import Step2 from "@/components/SVG/Step2";
import Step3 from "@/components/SVG/Step3";
import Step4 from "@/components/SVG/Step4";
import Step from "@/components/Step";
export default {
    name: "Steps",
    components: {Step, Step4, Step3, Step2, Step1}
}
</script>

<style lang="scss" scoped>
    @import "../styles/mixins";

    .steps {
        padding: 115px 10px 100px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        //background: var(--main-light-silver);

        &-content {
            width: 1000px;

            @include only-lg-down {
                width: 100%;
            }

            & button {
                border: 2px solid var(--main-titles);
                background: none;
                font-family: Montserrat-SemiBold, sans-serif;
                color: var(--main-titles);
                width: 285px;
                height: 66px;
                outline: none;
                border-radius: 200px;
                transition: 0.3s;

                &:hover {
                    background: var(--main-titles);
                    color: white;
                }
            }

            &_list {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 40px 0 56px 0;

                @include only-xs {
                    flex-direction: column;
                }
            }
        }

        & h1, h2, h3 {
            margin-bottom: 0;
            text-align: center;
        }

        & h1 {
            font-size: 34px;
            color: var(--main-titles);
            text-transform: uppercase;
            font-family: Montserrat-SemiBold, sans-serif;
        }

        & h2 {
            font-size: 18px;
            font-family: Montserrat-Medium, sans-serif;
            color: var(--main-dark);
            margin-top: 40px;
        }

        & h3 {
            font-family: Montserrat-SemiBold, sans-serif;
            font-size: 28px;
            margin-top: 70px;
            text-transform: uppercase;
        }
    }
</style>