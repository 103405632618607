const state = {
    activeSidebar: null
}
const mutations = {
    setActiveSidebar(state, name) {
        state.activeSidebar = name;
    },
    closeSidebar(state) {
        state.activeSidebar = null;
    }
}
const actions = {
    openSidebar({ commit }, name) {
        commit('setActiveSidebar', name)
    },
    closeSidebar({ commit }) {
        commit('closeSidebar')
    }
}
const getters = {
    activeSidebar: state => state.activeSidebar
}

export default {
    state,
    mutations,
    actions,
    getters
}