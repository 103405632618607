<template>
    <div class="message" v-if="data.body || data.files.length" :class="{'message-income': !isUser}">
        <div class="message-body" :style="styles">
            <p>{{ data.body }}</p>
            <p v-for="link in data.files">
                <a :href="fileUrl(link.url)"  target="_blank">
                    {{ link.filename }}
                </a>
            </p>
            <span>
                {{ data.date }}
                <b-icon :icon="isRead ? 'check2-all' : 'check2'"></b-icon>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Message",
    props: ['data', 'isLast'],
    computed: {
        styles() {
            return {background: this.isUser ? 'var(--main-app-transparent)' : 'rgb(234, 234, 234)'}
        },
        isUser() {
            return this.data.user_message === 'yes' || this.data.type === 'from_user';
        },
        isRead() {
            return this.data.read === 'yes' || this.data.read === true
        },
        selectedDialog() {
            return this.$store.getters.selectedDialog
        },
    },
    created() {
        if (!this.isUser && !this.isRead) {
            if (this.$store.getters.selectedDialog === 'support') {
                if (this.data.type === 'from_support') {
                    this.$http('support').mark_unread(this.data.id)
                }
            } else {
                this.$http('mail').checkAsRead(this.$store.getters.selectedDialog, this.data.id)
            }
        }
    },
    methods: {
      fileUrl(url) {
        return this.imageSelector(url);
      }
    }
}
</script>

<style lang="scss" scoped>
    @import "../styles/mixins";

    .message {
        margin-bottom: 10px;
        font-family: Acrobat-Light, sans-serif;
        font-weight: bold;
        display: flex;

        &-income {

            @include only-xs {
                justify-content: flex-end;
            }

            & span {
                color: black !important;
            }
        }

        &-body {
            display: inline-block;
            padding: 5px 10px;
            max-width: 50%;
            border-radius: 10px 10px 10px 0;
            min-width: 150px;

            @include only-xs {
                max-width: 80%;
            }

            & span {
                color: black;
                font-size: 10px;
                display: block;
                text-align: end;
                align-items: center;

                & svg {
                    font-size: 13px;
                }
            }

            & p {
                margin-bottom: 0;
                font-size: 16px;
                overflow-wrap: anywhere;
            }
        }
    }
</style>