<template>
    <div class="package">
        <div class="package-info">
            <div class="package_name">
                <div class="package_name_label">
                    {{ pack.name }}
                </div>
            </div>
            <div class="package_text" v-html="pack.description"></div>
        </div>

        <div class="package_footer">
            <div class="package_available" v-show="!isPurchased">
                <h4 class="package_price">{{ spacesAmount(pack.price) }} $</h4>
                <ProjectButton @click.native="onSelect" style="margin-bottom: 32px" variant="app-light">
                    <div class="w-100" v-html="$content('landing_service_button')"></div>
                </ProjectButton>
            </div>
            <div class="package_purchased" v-show="isPurchased">
                <h1 v-html="$content('purchased')"></h1>
                <div v-html="pack.user_inv"></div>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectButton from "./Buttons/ProjectButton";
export default {
    name: "Package",
    components: {ProjectButton},
    props: ['pack'],
    methods: {
        onSelect() {
            this.$emit('selected', this.pack)
        },
        spacesAmount(x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return parts.join(".");
        }
    },
    computed: {
        isPurchased() {
            return this.pack.status === 'bought'
        }
    }
}
</script>

<style lang="scss">
    @import "../styles/mixins";

    .package {
        width: 235px;
        background: white;
        border-radius: 10px;
        box-shadow: 2px 2px 6px 3px #00000040;
        cursor: pointer;
        margin: 0 15px;
        position: relative;
        height: 0%;

        &_available {
            padding: 0 19px;
        }

        &-info {
            padding: 0 19px;
        }

        &_purchased {
            height: 100%;
            background: var(--main-app);
            padding: 19px 15px 25px 15px;
            overflow-x: hidden;
            border-radius: 10px;
            color: white;

            & h1 {
                font-size: 23px;
                text-align: center;
            }

            & p {
                margin-bottom: 0;
                font-size: 15px;
            }
        }

        @include only-xl-down {
            margin-top: 100px;
        }

        &_price {
            color: var(--main-titles);
            font-size: 28px;
            text-align: center;
            font-weight: bolder;
            margin-bottom: 37px;
        }

        &_text {
            margin-top: 69px;
            margin-bottom: 37px;
            min-height: 133px;

            & ul {
                padding-left: 15px;

                & li {
                    line-height: 1;
                    font-size: 12px !important;
                    margin-bottom: 10px;
                }
            }
        }

        &_name {
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;

            &_label {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 90px;
                width: 90px;
                border-radius: 200px;
                background: var(--main-gradient_secondary);
                top: -45px;
                border: 6px solid white;
                font-size: 22px;
                color: white;
                text-transform: uppercase;
                box-shadow: 0px 2px 11px 3px #c0c0c033;
            }
        }
    }
</style>