<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 26.1 39.9" style="enable-background:new 0 0 26.1 39.9;" xml:space="preserve">
<g>
	<path class="st0" d="M9.5,20.6c2.7-2.7,5.2-5.2,7.8-7.8c2-2,4-4,6-6c0.9-1,1-2.4,0.3-3.4c-0.7-1-2.1-1.4-3.3-0.8
		c-0.3,0.2-0.6,0.4-0.8,0.6C14.3,8.3,9.2,13.4,4.1,18.6c-1.2,1.2-1.2,2.7,0,3.9c5.1,5.2,10.3,10.3,15.4,15.4
		c1.1,1.1,2.7,1.1,3.8,0.1c1.1-1,1-2.7-0.1-3.8C18.8,29.8,9.7,20.8,9.5,20.6z"/>
</g>
</svg>

</template>

<script>
export default {
    name: "Prev"
}
</script>

<style scoped>
.st0 {
        fill:#FFFFFF;
    }
</style>