<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 122 199" style="enable-background:new 0 0 122 199;" xml:space="preserve">
<path d="M111.3,93.6L29.9,12.2c-1.9-1.9-4.4-2.9-7-2.9c-2.6,0-5.1,1-7,2.9c-1.9,1.9-2.9,4.4-2.9,7s1,5.1,2.9,7l74.4,74.4L15.9,175
	c-1.9,1.9-2.9,4.4-2.9,7s1,5.1,2.9,7c1.9,1.9,4.4,2.9,7,2.9c2.6,0,5.2-1.1,7-2.9l81.4-81.4c1.9-1.9,2.9-4.4,2.9-7
	C114.2,98,113.2,95.5,111.3,93.6z"/>
</svg>

</template>

<script>
export default {
    name: "SlideArrow"
}
</script>

<style scoped>
 svg {
     height: 30px;
     fill: white;
     opacity: 0.5;
 }
</style>