<template>
    <Page :key="renderKey">
        <FluidContent background="#f3f3f3">
            <FullVwContainer class="h-100">
                <div class="rules" v-if="selectedArticle">

                    <div class="rules-list">
                        <div :class="{'rules-list_item_active': selectedArticle.title === item.title}"
                             class="rules-list_item" v-for="item in articles" @click="onArticleSelect(item)">
                            <span v-html="$content(item.title)"></span>
                        </div>
                    </div>

                    <div class="rules-list_dd">
                        <div class="rules-list_dd_head" @click="articleListDisplay = true">
                            <h4>{{ selectedArticle.title }}</h4>
                            <b-icon :icon="articleListDisplay ? 'chevron-up' : 'chevron-down'"/>
                        </div>
                        <div class="rules-list_dd_body" v-show="articleListDisplay">
                            <div
                                :class="{'rules-list_item_active': selectedArticle.title === item.title}"
                                class="rules-list_dd_body_item"
                                v-for="item in articles" @click="onArticleSelect(item)">
                                <span>{{ item.title }}</span>
                            </div>
                        </div>
                    </div>

                    <div ref="article_text" class="rules-text">
                        <h1 class="rules-text_title">{{ selectedArticle.title }}</h1>
                        <div v-html="selectedArticle.body"></div>
                    </div>

                </div>
            </FullVwContainer>
        </FluidContent>
    </Page>
</template>

<script>
import Page from "../../components/Page";
import FluidContent from "../../components/Wrappers/FluidContent";
import FullVwContainer from "../../components/Wrappers/FullVwContainer";

export default {
    name: "Contracts",
    components: {FullVwContainer, FluidContent, Page},
    data() {
        return {
            articles: [],
            selectedArticle: null,
            articleListDisplay: false,
            renderKey: 1
        }
    },
    methods: {
        onArticleSelect(item) {
            this.renderKey++;
            this.selectedArticle = item;
            this.articleListDisplay = false;
            // if (this.$refs.article_text) {
            //     this.$refs.article_text.scrollTop = 0
            // }
        }
    },
    created() {
        this.$http('content').getAgreetments()
            .then(res => {
                this.articles = this.$payloadHandler(res).contracts;
                this.selectedArticle = this.articles[0];
            })
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixins";
@import "../../styles/variables";

$py: 50px;

.rules {
    font-family: Montserrat-Medium, sans-serif;
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: $py 0;

    @include only-md-down {
        padding: 25px 0;
    }

    @include only-xs {
        flex-direction: column;
        padding: unset;
    }

    &-text {
        width: 900px;
        overflow-y: auto;
        padding-right: 60px;
        font-family: Montserrat-Light, sans-serif;
        font-size: 16px;
        font-weight: bold;
        height: 100%;
        background: #f3f3f3;

        @include only-xs {
            width: 100%;
            padding: 100px 20px 15px 20px;
        }

        @include only-lg {
            width: 700px;
        }

        @include only-md {
            width: 100%;
            padding-left: 30px;
        }

        @include only-sm {
            width: 100%;
            padding-left: 30px;
        }

        &::-webkit-scrollbar {
            width: 7px;
            height: 9px;
        }

        &::-webkit-scrollbar-thumb {
            background: #e4e4e4;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: silver;
        }

        &::-webkit-scrollbar-track {
            background: #efefef;
            border-radius: 10px;
            box-shadow: none;
        }

        &_title {
            font-family: Montserrat-Medium, sans-serif;
            font-size: 28px;
            color: var(--main-dark);
            font-weight: bold;
            margin-bottom: 25px;
        }
    }

    &-list {
        width: 350px;
        background: white;
        border-radius: 10px;
        overflow: hidden;
        height: 500px;
        position: relative;

        @include only-sm {
            height: 100%;
        }

        &_dd {
            width: 100%;
            height: 66px;
            background: var(--main-app);
            position: fixed;
            align-items: center;
            padding: 0 15px;
            justify-content: space-between;
            font-family: Montserrat-Medium, sans-serif;
            color: white;
            display: none;

            @include only-xs {
                display: flex;
            }

            &_head {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            & h4 {
                font-size: 16px;
                margin-bottom: 0;
            }

            &_body {
                position: absolute;
                background: white;
                top: 100%;
                left: 0;
                right: 0;
                border: 1px solid silver;
                border-top: unset;

                &_item {
                    color: black;
                    font-size: 16px;
                    padding: 15px 10px;
                }
            }
        }

        @include only-xs {
            display: none;
        }

        @include only-lg {
            width: 300px;
        }

        @include only-lg-down {
            width: 300px;
        }

        @include only-xs {
            width: 100%;
        }

        &_item {
            font-size: 18px;
            color: var(--main-dark-paper);
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 15px 0 15px 30px;

            @include only-lg-down {
                font-size: 16px;
                padding: 10px;
            }

            &_active {
                background: var(--main-app);
                color: white;
            }
        }
    }
}
</style>