<template>
    <div id="app">
        <Vue100vh>
            <Modal></Modal>
            <Heading />
            <router-view></router-view>
            <div
                v-if="$store.getters.ex_active.length && $route.name === 'Home' && $store.getters.activeSidebar !== 'menu'">
                <DealMonitor :id="deal.id" v-for="deal in $store.getters.ex_active" :key="`monitor${deal.id}`" />
            </div>
            <DealsLoadingProcess />
            <vue-progress-bar></vue-progress-bar>
        </Vue100vh>
    </div>
</template>

<script>
import Vue100vh from 'vue-div-100vh'
import Modal from "@/components/Modal";
import Heading from "./components/Header/Heading";
import DealMonitor from "./components/Deals/DealMonitor";
import DealsLoadingProcess from "./components/Deals/DealsLoadingProcess";

export default {
    components: { DealsLoadingProcess, DealMonitor, Heading, Modal, Vue100vh },
    computed: {
        title() {
            return this.$store.getters.title
        },
        isAuth() {
            return this.$store.getters.isAuth
        },
        ex_process() {
            return this.$store.getters.ex_process
        },
        ex_errors() {
            return this.$store.getters.ex_errors
        },
        isModalOpened() {
            return this.$store.getters.activeModal
        }
    },
    watch: {
        isModalOpened(val) {
            this.toggleClassName(val, 'fixed-body_modal')
        },
        isSidebar(val) {
            this.toggleClassName(val, 'fixed-body_sidebar')
        },
        title(val) {
            document.title = this.$content(val);
        },
        isAuth() {
            this.$store.dispatch('togglePopover', null)
            if (this.isAuth) {
                function getCookieValue(name) {
                    const regex = new RegExp(`(^| )${name}=([^;]+)`)
                    const match = document.cookie.match(regex)
                    if (match) {
                        return match[2]
                    }
                }
                this.socketWorker = new Worker('/sw.js');
                this.socketWorker.postMessage(`${window.location.origin}/?session=${getCookieValue('session')}`);
                this.socketWorker.addEventListener("message", (e) => {
                    switch (e.data.method) {
                        case 'new_private_chat_message':
                            this.$store.dispatch('updateDialogs');
                            this.$root.$emit('socket.new_message')
                            break;
                        case 'new_support_chat_message':
                            this.$store.dispatch('updateDialogs');
                            this.$root.$emit('socket.new_message')
                            break;
                        case 'need_update_chain_list':
                            this.$store.dispatch('exchanges.list')
                                .then(() => {
                                    const audio = new Audio('/assets/sounds/23.mp3')
                                    audio.play();
                                })
                            break;
                    }
                })
            }
        },
        ex_errors(errors) {
            if (errors) {
                this.$modal.open('DealError', { errors });
            }
        }
    },
    data() {
        return {
            socketWorker: {}
        }
    },
    methods: {
        toggleClassName(state, className) {
            const body = document.getElementsByTagName('body')[0];
            if (state) {
                body.classList.add(className);
            } else {
                body.classList.remove(className);
            }
        },
        handleVisibilityChange() {
            if (document.hidden) {
                console.log('page hidden')
            } else {
                console.log('page shown')
            }
        }
    },
    mounted() {
        const favicon = document.getElementById("favicon");
        favicon.href = "favicon.ico";
    },
    created() {
        // const savedState = JSON.parse(localStorage.getItem('vuex_state'));
        // if (savedState) {
        //   if(savedState.user) {
        //     this.$store.commit("setUserData", savedState.user);
        //   }
        //   console.log(savedState.site_settings?.locale)
        //   if(savedState.site_settings?.locale) {
        //     this.$store.commit("setLocale", savedState.site_settings?.locale??"ru");
        //   }
        // }
    }
}
</script>

<style lang="scss">
#app {
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
