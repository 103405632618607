<template>
    <div class="project-page">
        <div class="project-page_popover">
            <div class="project-page_popover_body" :class="{'project-page_popover_body_opened': isCabinetOpened}">
                <Cabinet v-if="isCabinetOpened"></Cabinet>
            </div>
        </div>
        <div class="project-page_content" v-if="isAuth">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import Popover from "@/components/Popover";
import Cabinet from "@/components/Popovers/Cabinet";
import Footer from "@/components/Footer";
export default {
    name: "Page",
    components: {Footer, Cabinet, Popover},
    computed: {
        isCabinetOpened() {
            return this.$store.getters.activePopover === 'cabinet'
        },
        isAuth() {
            return this.$store.getters.isAuth
        }
    },
    methods: {
        onRouteRender() {
            const auth = this.isAuth;

            if (auth === true) {
                this.$http('content').getMenu(this.$store.getters.menus)
                    .then(res => this.$store.dispatch('setMenus', this.$payloadHandler(res)))
            } else if (auth === false) {
                this.$router.push('/')
            }
        },
    },
    watch: {
        isAuth() {
            this.onRouteRender()
        }
    },
    created() {
        this.onRouteRender();
    }
}
</script>

<style lang="scss" scoped>
    @import "../styles/z-indexes";

    .project-page {
        height: 100%;

        &_popover {
            width: 100%;
            position: relative;

            &_body {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                height: 0;
                background: white;
                z-index: $cabinetZindex;
                border-radius: 0 0 20px 20px;
                transition: 0.3s;
                box-shadow: 0px 2px 15px 0px silver;
                overflow: hidden;

                &_opened {
                    height: 450px;
                }
            }
        }

        &_content {
            height: 100%;
            position: relative;
        }
    }
</style>