<template>
    <div class="sidebar-wrapper" :style="{'background': background}">
        <div class="sidebar-wrapper_header">
            <b-icon @click="$store.dispatch('togglePopover', 'auth')" class="sidebar-wrapper_close" icon="x"></b-icon>
        </div>
        <div class="sidebar-wrapper_content">
            <div class="h-100 overflow-auto">
                <Auth v-if="sidebar === 'auth' && !isAuth"></Auth>
                <div class="d-flex justify-content-center mt-3">
                    <LocalesRow />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Auth from "@/components/Sidebars/Auth";
import LocalesRow from "../LocalesRow";
export default {
    name: "SidebarWrapper",
    components: {LocalesRow, Auth},
    computed: {
        sidebar() {
            return this.$store.getters.activePopover
        },
        isAuth() {
            return this.$store.getters.isAuth
        },
        background() {
            switch (this.sidebar) {
                case 'auth':
                    return this.isAuth ? 'rgba(255, 255, 255, 0.84)' : 'rgba(255, 255, 255, 0.84)'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/variables";

    .sidebar-wrapper {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 10;

        &_content {
            overflow-y: scroll;
            height: 100%;
        }

        &_close {
            position: absolute;
            right: 24px;
            font-size: 25px;
            top: 20px;
        }
    }
</style>