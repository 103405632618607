import Repository from "../Repository";
import store from "../../store";

export default {
    sendMsgToSupport(data) {
        // data.append("account_id", store.getters.user.account_id);
        return Repository.post('/support', data);
    },
    getSupportChatHistory(page) {
        // return Repository.get(`/support?account_id=${store.getters.user.account_id}`, {
        //     params: {
        //         page: page
        //     }
        // });
        return Repository.get(`/support`, {
            params: {
                page: page
            }
        });
    },
    mark_unread(id) {
        // return Repository.post('/support/read', {last_read_message: id, account_id: store.getters.user.account_id});
        return Repository.post('/support/read', {last_read_message: id});
    }
};