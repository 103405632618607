<template>
    <div class="novelty">
        <div class="novelty-body">
            <div @click="$modal.open('Novelty', {
                                            width: '800px',
                                            color: 'white',
                                            overflow: 'unset',
                                            selected: novelty.slug,
                                            list: news,
                                            'box-shadow': 'none'
                                        })">
                <div class="novelty-body_image">
                    <img :src="this.imageSelector(novelty.image_url ? novelty.image_url : '/assets/img/novelty-img.jpg')">
<!--                    <div class="novelty-body_image_label" v-show="novelty.video_url">-->
<!--                        <b-icon icon="play-fill"></b-icon>-->
<!--                    </div>-->
                </div>
                <span>{{ new Date(novelty.publish_date).toLocaleDateString() }}</span>
                <h1>{{ novelty.title }}</h1>
                <p v-html="novelty.body"></p>
            </div>
                            <div
                        v-if="novelty.video_url"
                        class="novelty-body_video"
                        @click="$modal.open('Video', {url: 'https://www.youtube.com/embed/lH1F6m3slrQ', width: '762px'})">
                        <b-icon icon="play-fill"></b-icon>
                        {{ $content('novelty_play') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Novelty",
    props: ['novelty', 'news']
}
</script>

<style lang="scss">
@import "../styles/mixins";

.novelty {
    width: 300px;
    flex-shrink: 0;
    border-radius: 10px;

    @include only-minimal {
        width: 100%;
    }

    @include only-lg-down {
        margin: 0 auto;
        width: 100%;
    }

    &-body {
        width: 355px;
        margin: 0 auto;
        cursor: pointer;

        @include only-minimal {
            width: 100%;
        }


        &_image {
            position: relative;

            &_label {
                position: absolute;
                bottom: 7px;
                right: 7px;
                background: #46464687;
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 200px;

                & svg {
                    font-size: 22px;
                    color: white;
                    margin-left: 2px;
                }
            }
        }

        &_video {
            font-family: Montserrat-Medium, sans-serif;
            color: var(--main-app);
            font-size: 16px;
            font-weight: bolder;
            cursor: pointer;
            margin-top: 10px;

            & svg {
                font-size: 18px;
            }

            &:hover {
                color: var(--main-app-dark);
            }
        }


        & img {
            height: 190px;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }

        p {
            font-family: Montserrat-Medium, sans-serif !important;
            font-size: 18px !important;
            color: grey !important;
            margin-bottom: 0 !important;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        & span {
            margin: 25px 0 10px 0;
            color: var(--main-silver);
            font-size: 16px;
            display: flex;
            font-family: Montserrat-Medium, sans-serif;
        }

        & h1 {
            width: 100%;
            font-size: 26px;
            font-weight: bolder;
            font-family: Montserrat-Medium, sans-serif;
        }
    }
}
</style>