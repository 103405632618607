import Repository from "../Repository";
import store from "../../store";

export default {
    getInviteForm() {
        // return Repository.get(`/referral/invite?account_id=${store.getters.user.account_id}&locale=${store.getters.locale}`)
        return Repository.get(`/referral/invite`)
    },
    invite(data) {
        // data.append("account_id", store.getters.user.account_id);
        // data.append("locale", store.getters.locale);
        return Repository.post('/referral/invite', data)
    },
    getRefList(params) {
        // return Repository.get(`/referral/list?account_id=${store.getters.user.account_id}`, { params })
        return Repository.get(`/referral/list`, { params })
    },
    getReferralNews(params) {
        return Repository.get('/referral/news', { params: Object.assign(params, {per_page: 6}) })
    },
    deleteNew(list) {
        return Repository.post('/referral/news/delete', {ids: list})
    },
    mailing(data) {
        return Repository.post('/referral/mailing', data)
    },
    referralInfo() {
        // return Repository.get(`/referral/info?account_id=${store.getters.user.account_id}`)
        return Repository.get(`/referral/info`)
    },
    getInvitedList(url) {
        return Repository.get(url)
    }
}
