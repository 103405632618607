const f_version = 'fv'

const state = {
    settings: null,
    locale: null,
    f_version : localStorage.getItem(f_version)
}
const mutations = {
    setSettings(state, data) {
        state.settings = data;
    },
    setLocale(state, data) {
        state.locale = data;
    },
    setFv(state, ver) {
        localStorage.setItem(f_version, ver)
    }
}
const actions = {
    refresh_site_settings({ commit }, data) {
        commit('setSettings', data)
    },
    refresh_locale({ commit }, data) {
        commit('setLocale', data)
    },
    refresh_fv({commit}, ver) {
        commit('setFv', ver)
        if (localStorage.getItem(f_version) !== ver) {
            document.location.reload
        }
    }
}
const getters = {
    siteSettings: state => state.settings,
    logo: state => state.settings ? state.settings.small_logo_url : false,
    title: state => state.settings ? state.settings.title_content_key: 'title',
    locales: state => state.settings ? state.settings.languages : [],
    locale: state => state.locale
}

export default {
    state,
    mutations,
    actions,
    getters
}