<template>
    <div class="faq">
        <h1 v-html="$content('main_questions_title')"></h1>
        <div class="faq-item" v-for="(item, index) in list" :key="`faq_${ index }`" @click="onItemClick(index)">
            <div class="faq-item_title">
                <span v-html="item.question"></span>
                <b-icon :icon="openedItem === index ? 'chevron-up' : 'chevron-down'"></b-icon>
            </div>
            <div class="faq-item_body" v-if="openedItem === index">
                <p v-html="item.answer"></p>
            </div>
        </div>
        <button @click="onBtnClick" v-html="limit > 0 ? $content('main_questions_button') : $content('main_questions_button_close')"></button>
    </div>
</template>

<script>
export default {
    name: "FAQ",
    data() {
        return {
            openedItem: null,
            list: null,
            limit: 6
        }
    },
    methods: {
        onItemClick(index) {
            this.openedItem === index ? this.openedItem = null : this.openedItem = index;
        },
        getNews() {
            this.$http('content').getFAQ(this.limit)
                .then(res => this.list = this.$payloadHandler(res))
        },
        onBtnClick() {
            if (this.limit > 0) {
                this.limit = 0;
                this.getNews()
            } else {
                this.limit = 6;
                this.getNews()
            }
        }
    },
    created() {
        this.getNews()
    }
}
</script>

<style lang="scss" scoped>
    @import "../styles/mixins";

    .faq {
        padding: 60px 15px 56px 15px;
        background: var(--main-light-silver);

        @include only-xs {
            padding: 50px 15px 30px 15px;
        }

        & button {
            padding: 0 15px;
            height: 35px;
            background: var(--main-paper);
            color: var(--main-dark-paper);
            border: none;
            outline: none;
            display: block;
            margin: 0 auto;
            margin-top: 50px;
            border-radius: 200px;
            font-size: 12px;
            font-family: Montserrat-Medium, sans-serif;

            &:hover {
                background: silver;
            }
        }

        &-item {
            padding: 17px 30px;
            background: white;
            border-radius: 10px;
            width: 800px;
            margin: 0 auto;
            cursor: pointer;
            margin-bottom: 5px;
            transition: 0.3s;

            @include only-md-down {
                width: 100%;
            }

            &_title {
                display: flex;
                justify-content: space-between;
                align-items: center;

                & svg {
                    font-size: 11px;
                }
            }

            & span {
                font-size: 18px;
                font-family: Montserrat-Medium, sans-serif;
                color: var(--main-dark);
            }

            & p {
                font-size: 12px;
                font-family: Montserrat-Light, sans-serif;
                color: var(--main-dark);
                margin-top: 20px;
            }
        }

        & h1 {
            font-family: Montserrat-Regular, sans-serif;
            font-size: 30px;
            color: var(--main-titles);
            text-align: center;
            margin-bottom: 48px;
        }
    }
</style>