<template>
    <div class="full-vw" :style="Object.assign(background, {'background-size': size})">
        <div class="full-vw_container" :class="{'full-vw_container_first': first}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
name: "FullVwContainer",
    props: ['image', 'color', 'first', 'gradient', 'size'],
    computed: {
        background() {
            if (this.image && this.gradient) {
                return {background: `var(--main-modal), url(${ this.image })`}
            } else if (this.image && !this.gradient) {
                return {background: `url(${ this.image })`}
            } else if (this.color) {
                return {background: this.color}
            } else {
                return {background: ''}
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";
    @import "../../styles/variables";

    .full-vw {
        width: 100%;
        display: flex;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat !important;

        &_container {
            padding: 0 15px;

            &_first {
                margin-top: $standartHeaderHeight;
            }

            @include only-xl {
                width: $xl;
            }

            @include only-lg {
                width: $lg;
            }

            @include only-md {
                width: $md;
            }

            @include only-sm {
                width: $sm;
            }

            @include only-xs {
                width: 100%;
                padding: 0;
            }
        }
    }
</style>