<template>
    <div class="fluid-content">
        <div class="fluid-content_body" :style="{'background': background}">
            <slot></slot>
        </div>
        <Footer class="hide-on-xs" style="flex-shrink: 0" />
    </div>
</template>

<script>
import Footer from "../Footer";
export default {
    name: "FluidContent",
    components: {Footer},
    props: ['background']
}
</script>

<style lang="scss" scoped>
    @import "../../styles/variables";

    .fluid-content {
        padding-top: $standartHeaderHeight;
        height: 100%;
        display: flex;
        flex-direction: column;

        &_body {
            overflow-y: auto;
            flex: 1;
        }
    }
</style>