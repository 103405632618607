<template>
    <Page>
        <div class="mailing">
            <div class="mailing-content">
                <div class="mailing-list" :style="{ 'display': isMobile && selectedDialog ? 'none' : '' }">
                    <router-link :to="{ name: 'mail-body', params: { id: 'support' } }">
                        <button class="mailing-list_support">
                            <img :src="this.imageSelector($store.getters.logo)" alt="icon" />
                            <span v-html="$content('mail-support')"></span>
                            <transition name="fade">
                                <div class="mailing-list_support_counter"
                                    v-if="$store.getters.counters && $store.getters.counters.unread_support_msgs">
                                    {{ $store.getters.counters.unread_support_msgs }}
                                </div>
                            </transition>
                        </button>
                    </router-link>
                    <Dialog :dialog="dialog" v-for="dialog in $store.getters.dialogs" :key="dialog.id" />
                </div>
                <div class="mailing-dialog" :style="{ 'display': isMobile && !selectedDialog ? 'none' : '' }">
                    <router-view></router-view>
                    <span v-show="!selectedDialog" class="mailing-dialog_alert" v-html="$content('select_chat')"></span>
                </div>
            </div>
        </div>
        <Footer class="hide-on-xs hide-on-low-height" />
    </Page>
</template>

<script>
import Page from "../../components/Page";
import Footer from "../../components/Footer";
import Dialog from "../../components/Dialog";
export default {
    name: "Mailing",
    components: { Dialog, Footer, Page },
    data() {
        return {
            vw: 0,
            socketWorker: {}
        }
    },
    computed: {
        selectedDialog() {
            return this.$store.getters.selectedDialog
        },
        isMobile() {
            return this.vw < 751
        }
    },
    methods: {
        updateDialogs() {
            this.$store.dispatch('updateDialogs');
        },
        setVw() {
            this.vw = document.documentElement.clientWidth || 0, window.innerWidth || 0;
        }
    },
    watch: {
        '$route.params': {
            handler: function (params) {
                if (params?.id) {
                    this.$store.dispatch('onDialogSelect', params.id);
                } else {
                    this.$store.dispatch('removeSelectedDialog');
                }
            },
            immediate: true
        }
    },
    mounted() {
        function getCookieValue(name) {
            const regex = new RegExp(`(^| )${name}=([^;]+)`)
            const match = document.cookie.match(regex)
            if (match) {
                return match[2]
            }
        }
        this.socketWorker = new Worker('/sw.js');
        this.socketWorker.postMessage(`${window.location.origin}/?session=${getCookieValue('session')}`);
        this.socketWorker.addEventListener("message", (e) => {
            switch (e.data.method) {
                case 'new_message':
                    if (this.$route.params.id) {
                        this.$store.dispatch('onDialogSelect', this.$route.params.id);
                    }
                    break;
            }
        })
    },
    created() {
        this.updateDialogs();
        this.setVw();
        window.addEventListener('resize', () => this.setVw(), true);
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables";
@import "../../styles/mixins";

.mailing {
    height: calc(100% - #{$standartHeaderHeight});

    @include only-xs {
        height: 100%;
    }

    @media (max-height: 500px) {
        height: 100%;
    }

    &-content {
        height: 100%;
        padding-top: $standartHeaderHeight;
        display: flex;
        background: white !important;
    }

    &-list {
        width: 300px;
        border-right: 1px solid var(--main-paper);
        height: 100%;
        padding: 15px 10px 0 10px;
        font-family: Montserrat-Medium, sans-serif;
        flex-shrink: 0;

        & a {
            text-decoration: none !important;
        }

        @include only-xs {
            width: 100%;
        }

        &_support {
            height: 55px;
            width: 100%;
            border-radius: 200px;
            border: 2px solid rgba(0, 0, 0, 0.08);
            box-shadow: 0 0 7px 2px rgba(192, 192, 192, 75%);
            background: var(--main-app);
            color: white;
            text-transform: uppercase;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            & img {
                height: 25px;
                margin-right: 5px;
            }

            &_counter {
                background: white;
                color: var(--main-dark);
                width: 20px;
                border-radius: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                margin-left: 10px;
                font-size: 12px;
            }

            &:hover {
                background: #0aae11;
            }
        }
    }

    &-dialog {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &_alert {
            font-family: Montserrat-Medium, sans-serif;
            font-size: 14px;
            text-transform: capitalize;
        }
    }
}
</style>