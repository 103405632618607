<template>
    <div>
        <div class="menu-sidebar_header">
            <img class="heading-logo" :src="this.imageSelector($store.getters.siteSettings.logo_url)" alt="logo" />
            <div class="menu-sidebar_header_control">
                <Avatar />
                <b-icon
                    class="menu-sidebar_header_control_close"
                    @click="$store.dispatch('closeSidebar')"
                    icon="x" />
            </div>
        </div>
        <div class="menu-sidebar_body">
            <div class="menu-sidebar_buttons">
                <button
                    @click="$modal.open('Operation', {
                                width: '409px',
                                xColor: 'var(--main-app)',
                                type: 'withdraw'})"
                    class="menu-sidebar_buttons_withdraw"
                    v-html="$content('header-withdraw')"></button>
                <button class="menu-sidebar_buttons_deposit" @click="$modal.open('Operation', {
                                width: '409px',
                                xColor: 'var(--main-app)',
                                type: 'deposit'})" v-html="$content('header-deposit')"></button>
            </div>

            <div class="menu-sidebar_mail">
                <router-link :to="{name: 'mail'}">
                    <Chat class="menu-sidebar_chat"/>
                    <Counter v-if="$store.getters.counters" :getter="$store.getters.counters.unread_chats" />
                </router-link>
            </div>

            <div class="menu-sidebar_links">
                <router-link :to="link.url" :key="`link-${link.title}`" v-for="link in $utils.getMenu('header_menu')">
                    {{ link.title }}
                </router-link>
            </div>

            <div class="menu-sidebar_locales">
                <LangSwitcher />
            </div>

        </div>
    </div>
</template>

<script>
import Chat from "../SVG/Chat";
import Counter from "../Counter";
import Avatar from "../Avatar";
import LangSwitcher from "../LangSwitcher";
export default {
    name: "Menu",
    components: {LangSwitcher, Avatar, Counter, Chat}
}
</script>

<style lang="scss">
@import "../../styles/variables";

.menu-sidebar {

    &_locales {
        margin: 25px 0;
        display: flex;
        justify-content: center;
    }

    &_links {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--main-paper);

        & a {
            display: block;
            text-align: center;
            padding: 20px 0;
            border-top: 1px solid var(--main-paper);
            font-family: Montserrat-Medium, sans-serif;
            font-size: 18px;
            color: black;
            text-transform: uppercase;
        }
    }

    &_mail {
        position: relative;
        height: 45px;
        display: inline-block;
        margin: 25px auto;

        & .counter {
            bottom: -5px;
            right: 3px;
        }
    }

    &_chat {
        height: 45px;
        display: block;
        fill: dimgrey;
    }

    &_body {
        padding: 15px 30px;
        display: flex;
        flex-direction: column;
    }

    &_buttons {
        display: flex;
        flex-direction: column;

        & button {
            background: none;
            border-radius: 200px;
            padding: 0 15px;
            font-size: 13px;
            font-family: Montserrat-Medium, sans-serif;
            outline: none;
            font-weight: bolder;
            margin: 0 3px;
            height: 50px;

            &:first-child {
                margin-bottom: 10px;
            }
        }

        &_withdraw {
            border: 2px solid #aedf5a;
            color: #aedf5a;
            text-transform: uppercase;

            &:hover {
                background: #aedf5a;
                color: white;
            }
        }

        &_deposit {
            border: 2px solid #5dd5b5;
            color: #5dd5b5;
            text-transform: uppercase;
            padding: 0 25px !important;

            &:hover {
                background: #5dd5b5;
                color: white;
            }
        }
    }

    &_header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        height: $standartHeaderHeight;

        &_control {
            display: flex;
            align-items: center;

            &_close {
                margin-left: 15px;
                transform: scale(1.4);
            }
        }
    }
}
</style>