<template>
    <transition name="fade">
        <form v-show="preloadFormData" @submit.prevent="submit" :ref="name">
            <div class="form-field" :style="{'margin-bottom': mb}" :class="{'form-develop': develop}" v-for="(field, key) in preloadFormData">
                <slot :data="field" :name="`${name}_${key}`"></slot>
                <span v-if="develop" v-html="`${name}_${key}`"></span>
                <div v-if="submittedData && submittedData.status === 'error'">
                    <p class="form-error" v-for="err in submittedData.errors[key]">{{ err }}</p>
                </div>
            </div>
            <slot></slot>
            <input ref="f-submit" type="submit" class="d-none">
            <div @click="onSubmit" class="w-100">
                <slot name="submit"></slot>
            </div>
        </form>
    </transition>
</template>

<script>
import Submitter from "./Submitter";
export default {
    name: "FormWrapper",
    components: {Submitter},
    props: ['name', 'repository', 'methods', 'develop', 'mb', 'query'],
    data() {
        return {
            preloadFormData: null,
            submittedData: null,
            loading: false
        }
    },
    mounted() {
        console.log('mounted')
        if (this.methods.preload) {
            this.$http(this.repository)[this.methods.preload](this.query ? this.query : '')
                .then(res => {
                  // console.log(this.$payloadHandler(res));
                  // this.$payloadHandler(res).form.payment_system.choices.map(choice => {
                  //   if(choice.img.includes("/images/")) {
                  //     choice.img = "http://localhost:5000" + choice.img;
                  //   }
                  //   return choice;
                  // });
                    this.preloadFormData = this.$payloadHandler(res).form;
                })
        }
    },
    methods: {
        onSubmit() {
            this.$refs['f-submit'].click();
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                const data = new FormData(this.$refs[this.name]);
                this.$http(this.repository)[this.methods.submit](this.query ? this.query : data, data)
                    .then(res => {
                        this.submittedData = this.$payloadHandler(res);
                        this.loading = false;
                        this.$emit('submitted', this.submittedData);
                    })
                    .catch(e => {
                        this.loading = false;
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .form {

        &-error {
            margin-bottom: 0;
            color: red;
            font-size: 13px;
            margin-left: 10px;
            margin-top: 5px;
            text-align: start;
        }

        &-develop {
            border: 1px solid red;

            & span {
                font-size: 11px;
            }
        }
    }
</style>