<template>

    <div class="deal-details_item">
        <div class="deal-details_item_header">
<!--            {{ leftTime }}/{{ active }}-->
            <b-icon icon="dash" @click="$store.dispatch('closeDealMonitor')" />
        </div>
        <div class="deal-details_item_body">
            <div class="deal-details_item_timer">
                <p>{{ getLeftTimeStr() }}</p>
                <radial-progress-bar
                    class="deal-process_bar_radial"
                    :inner-stroke-width="5"
                    :stroke-width="5"
                    stop-color="var(--main-app)"
                    start-color="var(--main-app)"
                    inner-stroke-color="#efefef"
                    :diameter="57"
                    :completed-steps="active ? pair.processing_duration - leftTime : 100"
                    :total-steps="active ? pair.processing_duration : 100">
                    <div class="deal-process_bar_inner_small">
                        <span>{{ percent }}%</span>
                    </div>
                </radial-progress-bar>
            </div>
            <div class="deal-details_item_ps">
                <div class="deal-details_item_ps_img">
                    <img :src="this.imageSelector(pair.ps_from.logo_url)" alt="">
                </div>
                <img class="deal-details_item_ps_arrow" src="/assets/img/green-arrow.png" alt="">
                <div class="deal-details_item_ps_img">
                    <img :src="this.imageSelector(pair.ps_to.logo_url)" alt="">
                </div>
            </div>
            <div class="deal-details_item_payouts">
                <div :class="{'deal-details_item_payouts_negative': pair.payout < 0}">{{ payout }}</div>
                <div>${{ pair.balance_after }}</div>
            </div>
            <div class="deal-details_item_body_arrows" v-if="displayed">
                <div>
                    <div class="deal-details_item_body_arrows_item" @click="moveSlider('prev')" v-if="pair.number > 0">
                        <b-icon icon="chevron-left" />
                    </div>
                </div>
                <div>
                    <div class="deal-details_item_body_arrows_item" @click="moveSlider('next')" v-if="!active">
                        <b-icon icon="chevron-right" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import moment from 'moment'

export default {
    name: "DealDetailsSliderPair",
    props: ['pair', 'active', 'displayed'],
    data() {
        return {
            leftTime: 0
        }
    },
    components: {
        RadialProgressBar
    },
    computed: {
        ticker() {
            return this.$store.getters.ticker
        },
        percent() {
            if (this.active) {
                const percent = Math.round((this.pair.processing_duration - this.leftTime) / this.pair.processing_duration * 100);

                if (percent > 100) {
                    return 100
                } else {
                    return Math.round((this.pair.processing_duration - this.leftTime) / this.pair.processing_duration * 100)
                }
            } else {
                return 100
            }
        },
        payout() {
            return `$${this.pair.payout}`
        }
    },
    methods: {
        moveSlider(direction) {
            this.$emit('move', direction)
        },
        setTimeLeft() {

            if (!this.leftTime && this.active) {
                this.leftTime = this.pair.time_left + 2;
            }
        },
        getLeftTimeStr() {
            if (this.active) {
                if (this.leftTime > 0) {
                    return moment.utc(this.leftTime*1000).format('HH:mm:ss')
                } else {
                    return moment.utc(this.pair.processing_duration*1000).format('HH:mm:ss')
                }
            } else {
                return moment.utc(this.pair.processing_duration*1000).format('HH:mm:ss')
            }
        }
    },
    watch: {
        leftTime(val) {
            if (val < 0 && this.active) {
                this.$emit('finish')
            }
        },
        active(val) {
            if (val) {
                this.setTimeLeft();
            }
        },
        ticker() {
            if (this.leftTime) {
                this.leftTime = this.leftTime - 1;
            }
        }
    },
    created() {
        this.setTimeLeft();
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .deal-details_item {
            width: 350px;
            background: white;
            border-radius: 15px;
            position: absolute;
            height: 100%;
            transition: 0.3s;
            display: flex;
            flex-direction: column;

            @include only-xs-exchanges {
                width: 300px !important;
            }

            &_payouts {
                margin-top: 54px;
                height: 73px;
                width: 100%;
                border-radius: 200px;
                background: rgba(120,216,34,0.10);
                display: flex;
                align-items: center;
                padding: 0 20px;

                &_negative {
                    color: red !important;
                }

                & div {
                    display: flex;
                    font-family: Montserrat-Light, sans-serif;


                    &:first-child {
                        font-size: 18px;
                        border-right: 1px solid var(--main-app);
                        padding-right: 15px;
                        color: silver;
                    }

                    &:last-child {
                        font-size: 25px;
                        padding-left: 15px;
                        font-weight: bold;
                        color: var(--main-app);
                    }
                }
            }

            &_ps {
                height: 51px;
                margin-top: 47px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;

                &_img {
                    height: 100%;
                    width: 50px;
                    display: flex;
                    align-items: center;

                    & img {
                        height: auto;
                        width: 100%;
                    }
                }

                &_arrow {
                    height: 22px;
                }
            }

            &_body {
                width: 272px;
                margin: 0 auto;
                position: relative;

                @include only-xs-exchanges {
                    width: 100% !important;
                    padding: 0 30px;
                }

                &_arrows {
                    width: calc(100% + 220px);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-30%);
                    left: -110px;
                    display: flex;
                    justify-content: space-between;

                    @include only-xs {
                        display: none;
                    }

                    &_item {
                        background: white;
                        width: 60px;
                        height: 60px;
                        border-radius: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.35);
                        cursor: pointer;

                        & svg {
                            transform: scale(1.75);
                            color: var(--main-dark);
                        }
                    }
                }
            }

            &_header {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                padding: 12px 18px;

                & svg {
                    cursor: pointer;
                }
            }

            &_timer {
                height: 75px;
                border: 2px solid #e4e4e4;
                margin-top: 3px;
                border-radius: 200px;
                padding: 0 13px 0 25px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                & p {
                    margin-bottom: 0;
                    font-size: 25px;
                    font-weight: bold;
                    font-family: Acrobat-Light, sans-serif;
                }
            }

            &_hidden {

                &_prev {
                    display: none;
                    left: 12%;
                    transform: translateX(-50%) scale(0.8);
                }

                &_next {
                    transform: translateX(-50%) scale(0.8);
                    display: none;
                    left: 88%;
                }
            }

            &_prev {
                display: flex !important;
                left: 31.6%;
                transform: translateX(-50%) scale(0.87);
                filter: blur(4px);
            }

            &_active {
                z-index: 10;
                display: flex;
                left: 50%;
                transform: translateX(-50%);
                box-shadow: 0px -1px 7px rgba(0,0,0, 0.26);
            }

            &_next {
                display: flex !important;
                transform: translateX(-50%) scale(0.85);
                left: 68%;
                filter: blur(4px);
            }

        }
</style>