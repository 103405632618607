<template>
    <div class="no-deals">
        <h1>{{ $content('no-ex-title1') }} {{ $store.getters.ex_update }}</h1>
        <p>{{ $content('no-ex-title2') }}</p>
    </div>
</template>

<script>
export default {
    name: "NoDealsPlug"
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .no-deals {
        width: 640px;
        height: 270px;
        background: black;
        border-radius: 30px;
        background: rgba(0,0,0,0.14);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        font-family: Montserrat-Regular, sans-serif;

        @include only-lg-down {
            margin: 25px auto 0 auto;
        }

        @include only-xs {
            width: calc(100% - 30px);
            text-align: center;
        }

        & h1 {
            font-size: 26px;
        }

        & p {
            font-size: 18px;
            margin-top: 15px;
        }
    }
</style>