<template>
    <div class="slick">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Slick",
    props: ['index']
}
</script>

<style lang="scss" scoped>
    .slick {
        width: 100%;
        flex-shrink: 0;
        display: flex;
    }
</style>