<template>
    <Page>
        <div class="friends-page" id="friends-page">
            <FullVwContainer image="/assets/img/friends.jpg" :gradient="true" :first="true" size="cover">
                <div class="friends-page_banner">
                    <h1 v-html="$content('friends_banner_1')"></h1>
                    <p v-html="$content('friends_banner_2')"></p>
                    <div class="friends-page_banner_btn">
                        <ProjectButton
                            @click.native="$modal.open('Invite', {color: 'black'})"
                            variant="transparent">
                            <div class="w-100" v-html="$content('friends_banner_button')"></div>
                        </ProjectButton>
                    </div>
                </div>
            </FullVwContainer>
            <FullVwContainer>
                <div class="friends-page_steps">
                    <h1 v-html="$content('friends_howItWorks_title')"></h1>
                    <div class="friends-page_steps_list">
                        <Step color="var(--main-light-green)" v-for="step in 3" :index="step + 1" :isLast="step === 3"></Step>
                    </div>
                </div>
            </FullVwContainer>
            <FullVwContainer color="var(--main-light-silver)">
                <div class="friends-page_points">
                    <h1 v-html="$content('friends_howItWorks_title_2')"></h1>
                    <p v-html="$content('friends_howItWorks_text')"></p>
                    <div class="friends-page_points_list">

                        <div class="friends-page_points_list_item_title friends-page_points_list_item">
                            <div v-html="$content('friends_circles_title_1')"></div>
                            <div v-html="$content('friends_circles_title_2')"></div>
                        </div>

                        <div
                            class="friends-page_points_list_item_color friends-page_points_list_item"
                            v-for="item in 9">
                            <div class="friends-page_points_list_item_color_wrapper">
                                <div :style="getMaskColor(item)" v-html="$content(`friends_circles_item_${ item }`)"></div>
                            </div>
                            <div class="friends-page_points_list_item_counter" v-html="item"></div>
                        </div>

                    </div>
                </div>
            </FullVwContainer>
        </div>
        <Footer />
    </Page>
</template>

<script>
import Page from "@/components/Page";
import FullVwContainer from "@/components/Wrappers/FullVwContainer";
import ProjectButton from "@/components/Buttons/ProjectButton";
import Steps from "@/components/Steps";
import Step1 from "@/components/SVG/Step1";
import Step2 from "@/components/SVG/Step2";
import Step3 from "@/components/SVG/Step3";
import Step4 from "@/components/SVG/Step4";
import Step from "@/components/Step";
import Footer from "../../components/Footer";
export default {
    name: "Friends",
    components: {Footer, Step, Step4, Step3, Step2, Step1, Steps, ProjectButton, FullVwContainer, Page},
    data() {
        return {
            counter: 9
        }
    },
    methods: {
        getMaskColor(index) {
            let obj = {background: `rgb(120 216 34 / 0.${ this.counter - index })`};

            return obj
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .friends-page {

        &_points {
            padding: 83px 15px 92px 15px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &_list {
                display: flex;

                @include only-xs {
                    flex-direction: column;
                }

                &_item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 15px;
                    font-family: Montserrat-Medium, sans-serif;

                    & div {

                        & div {
                            color: white !important;
                        }
                    }

                    @include only-xs {
                        margin-bottom: 15px;
                        margin-right: 0;
                    }

                    &_counter {
                        border-radius: 200px;
                        margin-top: -10px;
                        height: 40px;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        background: white;

                        @include only-sm {
                            height: 30px;
                            width: 30px;
                        }
                    }

                    &_title {
                        font-family: Montserrat-Medium, sans-serif !important;
                        font-size: 18px !important;

                        & div {
                            &:first-child {
                                color: grey;
                            }
                            &:last-child {
                                color: grey;
                            }
                        }
                    }

                    &_color {

                        &_wrapper {
                           background: var(--main-titles_secondary);
                        }

                        & div {

                            &:first-child {
                                color: white;
                            }
                            &:last-child {
                                color: var(--main-dark-paper);
                            }
                        }
                    }


                    & div {

                        &:first-child {
                            height: 80px;
                            width: 80px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 200px;
                            font-size: 22px;

                            @include only-sm {
                               height: 60px;
                                width: 60px;
                            }
                        }
                    }
                }
            }

            & h1 {
                text-align: center;
                font-size: 26px;
                font-family: Montserrat-Medium, sans-serif;
                margin-bottom: 30px;
                color: var(--main-dark);
            }

            & p {
                font-size: 18px;
                font-family: Montserrat-Regular, sans-serif;
                color: grey;
                text-align: center;
                margin-bottom: 90px;
                width: 640px;

                @include only-xs {
                    width: 96%;
                    margin-left: 2%;
                }
            }


        }

        &_steps {
            padding: 79px 15px 73px 15px;

            & h1 {
                font-size: 28px;
                color: var(--main-titles);
                font-family: Montserrat-Medium, sans-serif;
                text-align: center;
                margin-bottom: 0;
            }

            &_list {
                display: flex;
                margin-top: 58px;
                justify-content: center;

                @include only-xs {
                    flex-direction: column;
                    align-items: center;
                }
            }

        }

        &_banner {
            height: 560px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @include only-xs {
                height: 350px;
            }

            & h1 {
                font-size: 60px;
                color: white;
                font-family: Montserrat-Regular, sans-serif;
                text-align: center;

                @include only-xs {
                    font-size: 40px;
                }
            }

            & p {
                font-size: 44px;
                font-family: Montserrat-Regular, sans-serif;
                color: white;
                text-align: center;

                @include only-xs {
                    font-size: 25px;
                }
            }

            &_btn {
                width: 245px;
            }
        }
    }
</style>