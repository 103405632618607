<template>
    <Page>
        <div class="home">
            <div class="actions">

                <div class="actions-bb">
                    <div class="actions-wrapper">
                        <div class="actions-deals">
                            <div class="actions-deals_balance">
                                <BalanceCircle/>
                            </div>
                            <div class="actions-deals_list">
                                <ExchangesSlider/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="home-wrapper">
                    <div class="actions-history">
                        <div class="actions-history_bar">
                            <h1 v-html="$content('main_balance_title')"></h1>
                            <div class="actions-history_bar_sort" v-if="historyData.list && historyData.list.length">
                                <span v-html="$content('main_balance_sortBy')"></span>
                                <HistorySort @sort="onHistorySort"/>
                            </div>
                        </div>

                        <div class="actions-history_list" v-if="historyData.list && historyData.list.length">
                            <div class="actions-history_list_content">
                                <Report :report="report" v-for="report in historyData.list" :key="report.id"/>
                                <div v-observe-visibility="visibilityChanged" class="actions-history_list_indicator"></div>
                            </div>
                        </div>

                        <div class="actions-history_list_empty" v-else v-html="$content('no-history')"></div>

                    </div>
                </div>

            </div>

            <div class="home-wrapper">
                <div class="news">
                    <div class="news-header">
                        <div class="news-header_box"></div>
                        <div class="news-header_title">
                            <span v-html="$content('main_articles_title')"></span>
                        </div>
                        <div class="news-header_box">
                            <Prev @click.native="toggleNews('prev')" />
                            <Next @click.native="toggleNews('next')" />
                        </div>
                    </div>
                    <div class="news-list" v-if="news && news.length">
                        <Slick ref="slick" :options="slickOptions">
                            <Novelty
                                :news="news"
                                v-for="novelty in news"
                                :key="novelty.id"
                                :novelty="novelty" />
                        </Slick>
                    </div>
                    <button class="news-btn">
                          <div
                              @click="$modal.open('ProposeNew', {width: '333px'})"
                              v-html="$content('news_propose')"></div>
                    </button>
                </div>
            </div>
        </div>

        <div class="faq">
            <div class="home-wrapper">
                <FAQ></FAQ>
            </div>
        </div>


        <div class="main-page_footer_wrapper">
            <div class="home-wrapper">
                <div class="main-page_footer">
                    <div class="main-page_footer_card">
                        <div class="main-page_footer_card_img"></div>
                        <div class="main-page_footer_card_content">
                            <div class="main-page_footer_card_content_text">
                                <h1 v-html="$content('main_office_title')"></h1>
                                <p v-html="$content('main_office_text')"></p>
                                <div
                                    class="main-page_footer_card_content_link"
                                    @click="$modal.open('Documents', {width: '500px'})"
                                    v-html="$content('main_office_link')"></div>
                                <router-link :to="{name: 'mail-body', params: {id: 'support'}}">
                                   <button v-html="$content('main_office_writeUs')"></button>
                                </router-link>
                                <div class="main-page_footer_card_content_socials_wrapper">
                                    <div class="main-page_footer_card_content_socials"
                                         v-for="item in $store.getters.static.socials" :key="`social_${ item }`">
                                        <b-icon :icon="item"></b-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>

    </Page>
</template>

<script>
import Page from "../../components/Page";
import BalanceCircle from "../../components/BalanceCircle";
import ExchangesSlider from "../../components/Exchanges/ExchangesSlider";
import HistorySort from "../../components/Popovers/HistorySort";
import Report from "../../components/Report";
import Slick from 'vue-slick';
import Novelty from "../../components/Novelty";
import 'slick-carousel/slick/slick.css';
import FullVwContainer from "../../components/Wrappers/FullVwContainer";
import FAQ from "../../components/FAQ";
import Footer from "../../components/Footer";
import Prev from "../../components/SVG/Prev";
import Next from "../../components/SVG/Next";

export default {
    name: "Home",
    components: {
        Next,
        Prev,
        Footer,
        FAQ,
        FullVwContainer,
        Novelty,
        Report,
        HistorySort,
        ExchangesSlider,
        BalanceCircle,
        Page,
        Slick
    },
    data() {
        return {
            historyConfig: {
                page: 1,
                per_page: 20,
                order_ascending: false,
                sort_by: 'date'
            },
            historyData: {
                list: [],
                hasNext: true
            },
            slickOptions: {
                infinite: true,
                draggable: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 1230,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 880,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            },
            news: []
        }
    },
    methods: {
        toggleNews(method) {
            this.$refs.slick[method]();
        },
        visibilityChanged(status) {
            if (status) {
                this.historyConfig.per_page = this.historyConfig.per_page + this.historyConfig.per_page;
                this.getHistory();
            }
        },
        onHistorySort(data) {
            this.historyConfig.sort_by = data.sort;
            this.historyConfig.order_ascending = data.asc;
            this.getHistory();
        },
        getHistory() {
            this.$http('exchanges').getHistory(this.historyConfig)
                .then(res => {
                    const payload = this.$payloadHandler(res);

                    switch (payload.status) {
                        case 'success':
                            this.historyData.list = payload.result;
                            this.historyData.hasNext = payload.has_next;
                            break;
                    }

                })
        },
        getNews() {
            this.$http('content').getNews()
                .then(res => this.news = res.data.payload)
        }
    },
    created() {
        this.getHistory();
        this.getNews();
        this.$root.$on('balanceOperationFinished', () => {
            this.$http('content').getAgreetments();
            console.log('belence event')
        })
    }
}
</script>

<style lang="scss">
@import "../../styles/mixins";
@import "../../styles/variables";
@import "../../styles/z-indexes";

.faq {
    background: var(--main-light-silver);
}

.news {
    width: 1120px;
    margin: 0 auto;
    padding-bottom: 60px;

    @media (max-width: 1140px) {
        width: 100%;
        padding: 0 15px 60px 15px;
    }

    &-btn {
        margin: 20px auto 0 auto;
        display: block;
        height: 38px;
        border-radius: 200px;
        padding: 0 25px;
        background: #5dd5b5;
        border: none;
        outline: none;
        color: white;
        font-family: Montserrat-Medium, sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    }

    &-header {
        display: flex;
        padding: 70px 0 40px 0;

        &_box {
            flex: 1;
            display: flex;
            justify-content: flex-end;

            & .st0 {
                fill: var(--main-dark);
            }

            & svg {
                font-size: 20px;
                margin-top: 15px;
                margin-left: 20px;
                height: 20px !important;

                @media (max-width: 1140px) {
                    display: none;
                }

                &:hover {
                    cursor: pointer;

                    & .st0 {
                        fill: var(--main-app);
                    }
                }
            }
        }

        &_title {
            flex: 2;
            text-align: center;

            & span {
                font-family: Montserrat-Light, sans-serif;
                font-size: 30px;
                color: var(--main-app);
                font-weight: bold;
                letter-spacing: 0.8px;
            }
        }
    }

    &-list {
        width: 100%;
    }
}

.home-wrapper {
    margin: 0 auto;

    @media (min-width: 1368px) {
        width: 1368px;
    }
}

.actions {
    padding-top: $standartHeaderHeight;
    background: rgb(91, 208, 196);
    background: linear-gradient(0deg, rgba(91, 208, 196, 1) 0%, rgba(144, 222, 59, 1) 100%);

    &-bb {
        border-bottom: 2px solid rgba(239, 239, 239, 0.3);
        background: url('/assets/img/exchanges_shape.png'), url('/assets/img/light.png');
        background-repeat: no-repeat;
        background-size: contain, cover;
        background-position-y: bottom;
    }

    &-wrapper {
        margin: 0 auto;
        padding: 75px 15px 75px 15px;

        @include only-xl {
            width: $xl;
        }

        @include only-lg-exchanges {
            width: 1190px;
        }

        @include only-md-exchanges {
            width: 100%;
        }

        @include only-xs-exchanges {
            width: 100%;
            padding: 35px 10px;
        }
    }

    &-deals {
        display: flex;
        width: 100%;

        @include only-md-exchanges {
            flex-direction: column;
            align-items: center;
        }

        @include only-xs-exchanges {
            flex-direction: column;
            align-items: center;
        }

        &_list {
            width: 100%;
            z-index: $dealsZindex;
        }
    }

    &-history {
        padding: 77px 62px 115px 120px;

        @include only-xs-exchanges {
            padding: 35px 15px;
        }

        @include only-md-exchanges {
            padding: 35px 15px;
        }

        &_bar {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding-right: 60px;
            margin-bottom: 40px;

            @include only-md-exchanges {
                padding: 0 15px;
            }

            @include only-xs-exchanges {
                padding: 0;
                flex-direction: column;
                text-align: center;
                align-items: center;
            }

            &_sort {
                display: flex;
                align-items: center;

                @include only-xs-exchanges {
                    flex-direction: column;
                }

                & span {
                    font-family: Montserrat-Light, sans-serif;
                    font-size: 14px;
                    color: white;
                    margin-right: 10px;
                    letter-spacing: 0.5px;
                }
            }

            & h1 {
                font-size: 30px;
                letter-spacing: 1.4px;
                color: white;
                font-family: Montserrat-Light, sans-serif;
            }
        }

        &_list {
            height: 518px;
            width: 100%;
            padding-right: 45px;
            overflow-y: auto;

            &_empty {
                background: rgba(0,0,0,0.14);
                padding: 15px;
                border-radius: 200px;
                text-align: center;
                color: white;
                font-family: Montserrat-Regular, sans-serif;
            }

            @include only-xs-exchanges {
                padding-right: 0px;
                overflow-y: hidden;
                padding-bottom: 25px;
            }

            &_content {
                height: 100%;

                @include only-xs-exchanges {
                    width: 800px;
                    padding-right: 0;
                    overflow-y: scroll;
                }
            }

            &_indicator {
                width: 1px;
                height: 1px;
                background: none;
            }

            &::-webkit-scrollbar {
                width: 6px;
                height: 7px;
            }

            &::-webkit-scrollbar-button {
                width: 0px;
                height: 0px;
            }

            &::-webkit-scrollbar-thumb {
                background: #ffffff94;
                border: 0px none #ffffff;
                border-radius: 50px;
                cursor: pointer;
            }

            &::-webkit-scrollbar-track {
                background: #4490a033;
                border: 0px none #ffffff;
                border-radius: 46px;
            }

            &::-webkit-scrollbar-corner {
                background: transparent;
            }
        }
    }
}

.main-page {

    &_top {
        background: var(--main-gradient_transparent);
        padding-bottom: 90px;

        &_block {
            background: url('/assets/img/exchanges_shape.png');
            background-size: contain;
            background-repeat: no-repeat;

            @include only-xs {
                background-size: cover;
            }
        }
    }

    &_footer {
        height: 620px;
        display: flex;
        align-items: center;

        &_wrapper {
            background: url('/assets/img/1920 green.jpg');
            background-size: cover;
            background-position: center;
        }

        &_card {
            height: 400px;
            width: 700px;
            background: rgba(255, 255, 255, 0.87);
            border-radius: 20px;
            display: flex;
            overflow: hidden;
            margin-left: 125px;
            margin-top: 5px;

            @media (max-width: 960px) {
                height: unset;
                flex-direction: column;
                width: calc(100% - 30px);
                margin: 0 auto;
                max-width: 500px;
            }

            &_img {
                flex: 1;
                background: url('/assets/img/company-house2.jpg');
                background-size: cover;
                background-repeat: no-repeat !important;
                transform: scale(1.01);

                @media (max-width: 960px) {
                    flex: unset;
                    height: 250px;
                    background-size: cover;
                }
            }

            &_content {
                flex: 1;
                display: flex;
                padding-top: 45px;
                justify-content: center;


                & button {
                    height: 31px !important;
                    background: #5dd5b5;
                    border-radius: 200px;
                    font-size: 14px;
                    border: none;
                    color: white;
                    font-family: Montserrat-Medium, sans-serif;
                    padding: 0 20px;
                    margin: 32px 0 34px 0;

                    &:hover {
                        background: #5eb411;
                    }
                }

                @media (max-width: 960px) {
                    padding: 0 15px 5px 15px;
                    flex: unset;
                    margin: 25px 0;
                }

                &_link {
                    color: var(--main-app);
                    font-family: Montserrat-Regular, sans-serif;
                    font-weight: bolder;
                    text-decoration: underline;
                    cursor: pointer;
                }

                &_socials {
                    height: 25px;
                    width: 25px;
                    border-radius: 200px;
                    background: grey;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    transition: 0.3s;
                    cursor: pointer;

                    &_wrapper {
                        display: flex;
                    }

                    &:hover {
                        transform: scale(1.1);
                    }

                    & svg {
                        font-size: 15px;
                    }
                }

                &_text {
                    width: 220px;

                    @include only-xs {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    & p {
                        font-size: 15px !important;
                        color: #6c6e6f !important;
                        font-weight: bolder;
                        font-family: Montserrat-Light, sans-serif !important;
                        margin-bottom: 30px;
                    }

                    & a {
                        font-size: 16px;
                        font-family: Montserrat-Medium, sans-serif;
                        color: #005173;
                        font-weight: bolder;
                        text-decoration: underline;
                        margin: 30px 0;
                        display: block;

                        @include only-xs {
                            margin-top: 30px !important;
                            margin-bottom: 0 !important;
                        }
                    }
                }

                & h1 {
                    font-family: Montserrat-Light, sans-serif;
                    font-size: 30px;
                    color: var(--main-app);
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }
    }

    &_history {
        padding: 90px 0 0 0;

        @include only-xs {
            padding: 25px 15px;
        }

        &_empty {
            padding: 24px;
            background: #00000024;
            border-radius: 10px;
            margin-bottom: 6px;
            font-family: Montserrat-Medium, sans-serif;
            color: white;
            text-align: center;
        }

        &_list {
            height: 520px;
            padding-right: 15px;
            width: 100%;

            @include only-lg-down {
                width: 1110px;
                overflow-y: auto;
                padding-right: 0;
            }

            &_wrapper {

                @include only-xl {
                    width: $xl;
                    overflow-y: auto;
                    padding: 0 15px;
                    margin: 0 auto;
                }

                @include only-lg {
                    width: $lg;
                    overflow-y: auto;
                    padding: 0 15px;
                    margin: 0 auto;
                }

                @include only-lg-down {
                    padding: 0 0 50px 0px;
                    width: calc(100vw - 15px);
                    overflow-x: auto;
                    margin: 0 auto;
                }

                //@include only-md {
                //    padding: 0 0 50px 0px;
                //    width: $md;
                //    margin: 0 auto;
                //    overflow-x: auto;
                //}
                //
                //@include only-sm {
                //    margin: 0 auto;
                //    padding: 0 0 50px 0px;
                //    width: $sm;
                //    overflow-x: auto;
                //}
                //
                //@include only-xs {
                //    margin: 0 auto;
                //    padding: 0 0 50px 0px;
                //    width: calc(100vw - 60px);
                //    overflow-x: auto;
                //}

                &::-webkit-scrollbar {
                    width: 6px;
                    height: 7px;
                }

                &::-webkit-scrollbar-button {
                    width: 0px;
                    height: 0px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #ffffff94;
                    border: 0px none #ffffff;
                    border-radius: 50px;
                    cursor: pointer;
                }

                &::-webkit-scrollbar-track {
                    background: #4490a033;
                    border: 0px none #ffffff;
                    border-radius: 46px;
                }

                &::-webkit-scrollbar-corner {
                    background: transparent;
                }

            }

            &_indicator {
                width: 1px;
                height: 1px;
                background: none;
            }

        }

        &_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 50px;

            @include only-xs {
                flex-direction: column;
                margin-bottom: 25px;
            }

            h1 {
                font-family: Montserrat-Light, sans-serif;
                font-size: 30px;
                color: white;
                margin-bottom: 0;
            }

            &_sort {
                font-family: Montserrat-Light, sans-serif;
                font-size: 16px;
                color: white;
                margin-right: 10px;

                @include only-xs {
                    font-size: 14px;
                }

                &_wrapper {
                    display: flex;
                    align-items: center;

                    @include only-xs {
                        margin-top: 25px;
                        width: 100%;
                        justify-content: flex-end;
                        flex-direction: column;
                        gap: 10px;
                    }
                }
            }
        }
    }

    &_title {
        font-size: 30px;
        font-family: Montserrat-Regular, sans-serif;
        color: var(--main-app);
        margin-bottom: 0;
    }

    &_news {
        padding: 80px 0 55px 0;

        &_button {
            width: 180px;
            margin: 50px auto 0 auto;
        }

        @include only-xs {
            padding: 40px 0 25px 0;
        }

        &_body {
            width: 100%;
            display: flex;
            overflow-x: hidden;
        }

        &_header {
            display: flex;
            margin-bottom: 50px;

            @include only-xs {
                padding: 0 15px;
            }

            &_partials {
                display: flex;
                justify-content: flex-end;
                flex: 1;
                align-items: center;

                & svg {
                    height: 16px;
                    color: var(--main-dark);
                    cursor: pointer;

                    &:hover {
                        color: black;
                    }

                    &:last-child {
                        margin-left: 12px;
                    }
                }
            }
        }
    }
}


</style>