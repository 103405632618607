<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 44.42 42.02" style="enable-background:new 0 0 44.42 42.02;" xml:space="preserve">
<path d="M30.48,29.68L30.48,29.68c-2.95-0.53-4.31-2.28-4.58-3.47c1.12-1.1,4.64-5.04,4.74-11.27c0.06-3.37-0.75-5.92-2.39-7.59
	C26.91,6,24.97,5.23,22.48,5.06l0,0.02c-0.27-0.04-0.68-0.04-0.94,0l0-0.02C19.04,5.23,17.1,6,15.76,7.36
	c-1.64,1.66-2.44,4.22-2.39,7.59c0.1,6.22,3.62,10.17,4.74,11.27c-0.27,1.19-1.63,2.94-4.58,3.47c-2.78,0.5-7.45,1.63-7.45,3.61
	c0,0.96,0.03,2.73,0.03,2.73l0.01,0.79H37.9l0.01-0.79c0,0,0.03-1.77,0.03-2.73C37.94,31.31,33.27,30.18,30.48,29.68z M7.66,33.38
	c0.12-0.22,1.59-1.3,6.15-2.12c4.22-0.76,5.87-3.59,5.95-5.31l0.02-0.39l-0.29-0.25c-0.04-0.04-4.4-3.93-4.51-10.38
	c-0.05-2.92,0.6-5.09,1.93-6.44c1.13-1.15,2.86-1.77,5.09-1.84c2.27,0.07,3.99,0.69,5.13,1.84c1.33,1.35,1.98,3.51,1.93,6.44
	c-0.11,6.46-4.47,10.34-4.51,10.38l-0.29,0.25l0.02,0.39c0.08,1.72,1.73,4.56,5.95,5.31c4.56,0.82,6.03,1.9,6.13,2.03
	c0,0.54-0.01,1.33-0.02,1.92H7.69C7.68,34.62,7.67,33.85,7.66,33.38z"/>
<path d="M12.59,30.15H1.66c-0.01-0.42-0.01-0.94-0.02-1.28c0.18-0.21,1.38-0.98,4.73-1.58c3.39-0.61,4.72-2.9,4.78-4.3l0.02-0.37
	l-0.27-0.24c-0.03-0.03-3.42-3.09-3.51-8.07c-0.04-2.27,0.46-3.94,1.48-4.97c0.87-0.88,2.19-1.35,3.91-1.41
	c1.07,0.03,1.95,0.24,2.7,0.58c0.25-0.45,0.56-0.86,0.95-1.21c-0.91-0.48-1.98-0.78-3.25-0.87l-0.09,0c-0.17-0.01-0.38-0.02-0.55,0
	l0-0.01l-0.12,0.01c-1.99,0.13-3.55,0.75-4.62,1.84c-1.32,1.33-1.96,3.37-1.91,6.06c0.08,4.86,2.8,7.98,3.71,8.9
	c-0.21,0.79-1.16,2.15-3.49,2.57c-2.72,0.49-5.96,1.4-5.96,2.96c0,0.76,0.02,2.15,0.02,2.15l0.01,0.74h10.04
	C10.79,30.95,11.58,30.41,12.59,30.15z"/>
<path d="M31.54,30.15h10.93c0.01-0.42,0.01-0.94,0.02-1.28c-0.18-0.21-1.38-0.98-4.73-1.58c-3.39-0.61-4.72-2.9-4.78-4.3l-0.02-0.37
	l0.27-0.24c0.03-0.03,3.42-3.09,3.51-8.07c0.04-2.27-0.46-3.94-1.48-4.97c-0.87-0.88-2.19-1.35-3.91-1.41
	c-1.07,0.03-1.95,0.24-2.7,0.58c-0.25-0.45-0.56-0.86-0.95-1.21c0.91-0.48,1.98-0.78,3.25-0.87l0.09,0c0.17-0.01,0.38-0.02,0.55,0
	l0-0.01l0.12,0.01c1.99,0.13,3.55,0.75,4.62,1.84c1.32,1.33,1.96,3.37,1.91,6.06c-0.08,4.86-2.8,7.98-3.71,8.9
	c0.21,0.79,1.16,2.15,3.49,2.57c2.72,0.49,5.96,1.4,5.96,2.96c0,0.76-0.02,2.15-0.02,2.15l-0.01,0.74H33.91
	C33.34,30.95,32.55,30.41,31.54,30.15z"/>
</svg>

</template>

<script>
export default {
name: "Step2"
}
</script>

<style scoped>

</style>