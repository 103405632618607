<template>

<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 44.42 42.02" style="enable-background:new 0 0 44.42 42.02;" xml:space="preserve">
<g>
	<path d="M35.13,13.16V5.9c0-3.23-4.27-5.76-9.72-5.76s-9.72,2.53-9.72,5.76v8.05c0.67,0.25,1.09,0.42,1.53,0.89
		c0-0.26,0-0.26,0-0.48v-1.09c1.76,1.65,4.8,2.63,8.19,2.63c0.43,0,0.85-0.02,1.27-0.05c-0.82,0.86-1.29,1.86-1.29,2.95v17.59
		c0,1.87,3.44,4.58,9.06,4.74c0.15,0,0.3,0.01,0.45,0.01c3.11,0,5.88-0.91,7.5-2.48c0.72-0.7,1.11-1.47,1.11-2.18V18.81
		C43.5,15.77,39.87,13.37,35.13,13.16z M25.41,1.79c4.9,0,8.19,2.13,8.19,4.11c0,1.98-3.29,4.11-8.19,4.11
		c-4.9,0-8.19-2.13-8.19-4.11C17.21,3.91,20.51,1.79,25.41,1.79z M25.41,14.24c-4.9,0-8.19-2.13-8.19-4.11V9.04
		c1.76,1.65,4.8,2.63,8.19,2.63s6.43-0.98,8.19-2.63v1.09C33.6,12.12,30.31,14.24,25.41,14.24z M42.06,35.46
		c0,1.95-3.06,4.04-7.62,4.04s-7.62-2.09-7.62-4.04v-1.06c1.65,1.61,4.47,2.57,7.62,2.57c3.15,0,5.97-0.96,7.62-2.57V35.46z
		 M42.06,31.3c0,1.95-3.06,4.04-7.62,4.04s-7.62-2.09-7.62-4.04v-1.05c1.65,1.61,4.46,2.57,7.62,2.57s5.97-0.96,7.62-2.57V31.3z
		 M42.06,27.14c0,1.95-3.06,4.04-7.62,4.04s-7.62-2.09-7.62-4.04v-1.05c1.65,1.61,4.46,2.57,7.62,2.57s5.97-0.96,7.62-2.57V27.14z
		 M42.06,22.97c0,1.95-3.06,4.04-7.62,4.04s-7.62-2.09-7.62-4.04v-1.06c1.65,1.61,4.46,2.57,7.62,2.57s5.97-0.96,7.62-2.57V22.97z
		 M34.44,22.85c-4.56,0-7.62-2.09-7.62-4.04c0-1.95,3.06-4.04,7.62-4.04s7.62,2.09,7.62,4.04C42.06,20.76,39,22.85,34.44,22.85z"/>
	<path d="M9.38,13.13c-5.12,0-9.13,2.37-9.13,5.39v11.86c0,3.02,4.01,5.39,9.13,5.39c5.12,0,9.13-2.37,9.13-5.39V18.54
		C18.51,15.51,14.5,13.13,9.38,13.13z M9.38,22.36c-4.59,0-7.68-1.98-7.68-3.83c0-1.85,3.09-3.83,7.68-3.83
		c4.59,0,7.68,1.98,7.68,3.83C17.06,20.38,13.97,22.36,9.38,22.36z M9.38,26.32c-4.59,0-7.68-1.98-7.68-3.83v-1
		c1.66,1.53,4.5,2.44,7.68,2.44s6.02-0.91,7.68-2.44v1C17.06,24.34,13.97,26.32,9.38,26.32z M9.38,30.27
		c-4.59,0-7.68-1.98-7.68-3.83v-1c1.66,1.53,4.5,2.44,7.68,2.44s6.02-0.91,7.68-2.44v1C17.06,28.29,13.97,30.27,9.38,30.27z
		 M17.06,29.4v1c0,1.85-3.09,3.83-7.68,3.83c-4.59,0-7.68-1.98-7.68-3.83v-1c1.66,1.53,4.5,2.44,7.68,2.44
		C12.56,31.84,15.4,30.94,17.06,29.4z"/>
	<path d="M20.02,22.11l-0.22-0.05v1.7l0.13,0.03c1.37,0.28,2.82,0.46,4.3,0.53l0.18,0.01v-1.66l-0.16-0.01
		C22.73,22.58,21.31,22.4,20.02,22.11z"/>
	<path d="M20.02,17.88l-0.22-0.05v1.7l0.13,0.03c1.36,0.28,2.81,0.46,4.3,0.53l0.18,0.01v-1.65l-0.16-0.01
		C22.74,18.36,21.31,18.17,20.02,17.88z"/>
	<path d="M20.02,26.34l-0.22-0.05v1.7l0.13,0.03c1.36,0.28,2.81,0.46,4.3,0.53l0.18,0.01V26.9l-0.16-0.01
		C22.74,26.82,21.31,26.63,20.02,26.34z"/>
	<path d="M20.02,30.57l-0.22-0.05v1.69l0.12,0.03c1.38,0.3,2.83,0.49,4.31,0.56l0.18,0.01v-1.67l-0.16-0.01
		C22.73,31.04,21.31,30.86,20.02,30.57z"/>
</g>
</svg>

</template>

<script>
export default {
name: "Step3"
}
</script>

<style scoped>

</style>