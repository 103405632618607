<template>
    <div class="slicker" :style="{transform: `translateX(-${ index * 100 }%)`}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Slicker",
    props: ['name'],
    computed: {
        index() {
            return this.$store.getters.slickers[this.name]
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .slicker {
        width: 100%;
        display: flex;
        transition: 0.1s;
        flex-shrink: 0;

        @include only-xs-exchanges {
            transition: 0.3s;
        }
    }
</style>