<template>
    <div></div>
</template>

<script>
export default {
    name: "WildCard",
    created() {
        this.$router.push('/')
    }
}
</script>

<style scoped>

</style>