<template>
    <div class="locales-row">
        <p
            :class="{'locales-row_active': locale === $store.getters.locale}"
            @click="toggleLocale(locale)"
            :key="'locale_' + locale"
            v-for="locale in $store.getters.locales">{{ locale }}</p>
    </div>
</template>

<script>
export default {
    name: "LocalesRow",
    methods: {
        toggleLocale(locale) {
            if (locale !== this.$store.getters.locale) {
                this.$http('client').toggleLocale(locale)
                    .then(() => {
                        this.$router.go(-1);
                        window.location.reload();
                    })
            } else {
                this.$store.dispatch('togglePopover', null);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .locales-row {
        display: flex;

        &_active {
            color: var(--main-titles);
            text-decoration: none !important;
        }

        & p {
            text-transform: uppercase;
            font-family: Montserrat-Regular, sans-serif;
            font-size: 13px;
            margin: 0 3px 15px 3px;
            font-weight: bold;
            text-decoration: underline;
        }
    }
</style>