<template>
    <div class="mailing-body">
        <div class="mailing-body_panel">
            <div class="mailing-body_bar">
                <Prev @click.native="$router.push('/mail')"></Prev>
                <p>{{ recipient }}</p>
            </div>
        </div>
        <div class="mailing-body_history" ref="history">
            <Message v-for="msg in chat" :key="msg.id" :data="msg" />
        </div>
        <div class="mailing-body_panel">
            <div class="mailing-body_field">
                <div class="mailing-body_field_files" v-if="files.length">
                    <span>{{ files.length }}</span>
                    <b-icon icon="files" />
                </div>
                <div @click="toggleFileUploader" class="mailing-body_field_svg"><b-icon icon="paperclip"></b-icon></div>
                <input v-on:keyup.enter="onSend" v-model="message" type="text">
                <div class="mailing-body_field_btn">
                    <img @click="onSend" style="height: 45px" src="/assets/icons/chat with friend.svg" alt="">
<!--                    <ProjectButton @click.native="onSend" size="34" variant="app">-->
<!--                        <div v-html="$content('msg-send')"></div>-->
<!--                    </ProjectButton>-->
                </div>
            </div>
        </div>
        <input id="mail-file" multiple="multiple" type="file" ref="mail-file" @change="onFileChange">
    </div>
</template>

<script>
import ProjectButton from "./Buttons/ProjectButton";
import Message from "./Message";
import Prev from "./SVG/Prev";
export default {
    name: "MailingBody",
    components: {Prev, Message, ProjectButton},
    data() {
        return {
            message: '',
            files: []
        }
    },
    methods: {
        clearFields() {
            this.message = '';
            this.files = [];
        },
        toggleFileUploader() {
            this.$refs["mail-file"].click();
        },
        onSend() {
            if (this.message || this.files.length) {
                const fd = new FormData;

                if (this.files.length) {
                    this.files.forEach((file, index) => {
                        fd.append(`file${index}`, file);
                    })
                }

                if (this.dialog === 'support') {
                    fd.append('text', this.message);
                    this.$store.dispatch('sendMessageSupport', fd)
                        .then(() => this.clearFields())
                } else {
                    fd.append('message', this.message);
                    this.$store.dispatch('sendMessage', fd)
                        .then(() => {
                            const audio = new Audio('/assets/sounds/24.mp3');
                            audio.play();
                            this.clearFields();
                        })
                }
            }
        },
        onFileChange (e) {
            let files = e.target.files;
            this.files = files;
        }
    },
    computed: {
        recipient() {
            return this.$store.getters.selectedDialogRecipient
        },
        dialog() {
            return this.$store.getters.selectedDialog
        },
        chat() {
            const list = this.$store.getters.selectedDialogChat;

            return list ? list.reverse() : null
        }
    }
}
</script>

<style lang="scss">
    @import "../styles/mixins";

    #mail-file {
        display: none;
        opacity: 0;
        width: 0;
    }

    .mailing-body {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        &_bar {
            height: 60px;
            background: white;
            display: flex;
            align-items: center;
            padding: 0 15px;
            font-family: Montserrat-Medium, sans-serif;
            color: var(--main-dark);
            border-bottom: 1px solid var(--main-paper);
            box-shadow: 7px 1px 6px 0px var(--main-paper);

            & p {
                margin-top: 3px;
                margin-bottom: 0;
                line-height: 1;
            }

            & .st0 {
                color: var(--main-dark) !important;
                fill: var(--main-dark);
                cursor: pointer;

                &:hover {
                    fill: black;
                }
            }

            & svg {
                height: 15px;
                color: var(--main-dark) !important;
                fill: var(--main-dark) !important;
                margin-right: 15px;
            }
        }

        &_history {
            flex: auto;
            padding: 15px 10px 0 10px;
            display: flex;
            overflow-y: auto;
            flex-direction: column-reverse;
        }

        &_panel {

        }

        &_field {
            height: 70px;
            padding: 10px;
            display: flex;
            align-items: center;

            &_files {
                margin-right: 10px;
                display: flex;
                align-items: center;
                font-family: Montserrat-Regular, sans-serif;
                font-weight: bold;
                font-size: 14px;

                & span {
                    margin-right: 5px;
                    color: var(--main-dark);
                }
            }

            &_svg {
                background: #e4e4e4;
                height: 45px;
                width: 45px;
                border-radius: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                flex-shrink: 0;

                &:hover {
                    background: var(--main-app);

                    & svg {
                        color: white !important;
                    }
                }
            }

            &_btn {
                cursor: pointer;

                @include only-xs {
                    width: auto;
                }

                & button {

                    @include only-xs {
                        font-size: 8px !important;
                    }
                }
            }

            & svg {
                font-size: 20px;
            }

            & input {
                width: 100%;
                height: 34px;
                margin: 0 15px;
                border: 1px solid #e4e4e4;
                padding-left: 15px;
                font-family: Montserrat-Regular, sans-serif;
                font-size: 15px;

                &:focus {
                    border: 1px solid transparent;
                    outline: 1px solid var(--main-app);
                    box-shadow: 0 0 2px 2px var(--main-app);
                }
            }

            & button {
                width: 150px;
                height: 34px;
                background: var(--main-app);
                border-radius: 200px;
                border: none;
                outline: none;
                color: white;
            }
        }
    }
</style>