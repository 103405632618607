const state = {
    progress: true
}
const mutations = {
    toggleProcess(state, payload) {
        state.progress = payload;
    }
}
const actions = {
    toggleProcess({commit}, payload) {
        commit('toggleProcess')
    }
}
const getters = {
    process: state => state.progress
}

export default {
    state,
    mutations,
    actions,
    getters
}