import Repository from "../Repository";

export default {
    login(credentials) {
        return Repository.post(`auth/login`, credentials)
    },
    logout() {
        return Repository.post(`auth/logout`)
    },
    signUp(data) {
        return Repository.post(`auth/register`, data)
    },
    recoverPassword(data) {
        return Repository.post(`auth/reset`, data)
    }
}
