var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project-button_wrapper"},[(_vm.to)?_c('router-link',{attrs:{"to":{name: _vm.to}}},[_c('button',{staticClass:"project-button",class:_vm.getClass(),style:({
                background: `var(${_vm.variant})`,
                height: _vm.size ? `${_vm.size}px` : '',
                'border-radius': _vm.radius ? `${_vm.radius}px` : ''}),attrs:{"form":_vm.form,"type":_vm.type}},[_vm._t("default")],2)]):_c('button',{staticClass:"project-button",class:_vm.getClass(),style:({
            background: `var(${_vm.variant})`,
            height: _vm.size ? `${_vm.size}px` : '',
            'border-radius': _vm.radius ? `${_vm.radius}px` : ''}),attrs:{"form":_vm.form,"type":_vm.type}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }