<template>
    <transition name="fade">
        <div v-if="!!counter" class="counter">{{ counter }}</div>
    </transition>
</template>

<script>
export default {
    name: "Counter",
    computed: {
        counter() {
            const counters = this.$store.getters.counters;

            if (counters) {
                let unreads = 0;
                for (let key in counters) {
                    unreads = unreads + counters[key];
                }

                return unreads;
            } else {
                return 0
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .counter {
        width: 15px;
        height: 15px;
        border-radius: 200px;
        background: red;
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: white;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>