<template>
    <div style="height: 100%">
        <Home v-if="isAuth"></Home>
        <Landing v-if="isAuth === false"></Landing>
    </div>
</template>

<script>
import Landing from "@/views/AUTH/Landing";
import Home from "./MAIN/Home";
export default {
    name: "Root",
    components: {Home, Landing},
    computed: {
        isAuth() {
            return this.$store.getters.isAuth
        }
    }
}
</script>

<style scoped>

</style>