<template>
    <div class="project-button_wrapper">
        <router-link :to="{name: to}" v-if="to">
            <button
                :form="form"
                :type="type"
                :class="getClass()"
                :style="{
                    background: `var(${variant})`,
                    height: size ? `${size}px` : '',
                    'border-radius': radius ? `${radius}px` : ''}"
                class="project-button">
                <slot></slot>
            </button>
        </router-link>

        <button
            v-else
            :form="form"
            :type="type"
            :class="getClass()"
            :style="{
                background: `var(${variant})`,
                height: size ? `${size}px` : '',
                'border-radius': radius ? `${radius}px` : ''}"
            class="project-button">
            <slot></slot>
        </button>

    </div>
</template>

<script>
export default {
    name: "ProjectButton",
    props: ['variant', 'size', 'radius', 'to', 'type', 'form'],
    methods: {
        getClass() {
            let obj = {};
            this.variant ? obj[`project-button_${ this.variant }`] = true : '';

            return obj
        }
    }
}
</script>

<style lang="scss" scoped>
    .project-button {
        border-radius: 200px;
        border: none;
        color: white;
        font-family: Montserrat-Medium, sans-serif;
        //font-size: 13px;
        font-size: 15px;
        width: 100%;
        height: 50px;
        text-transform: uppercase;
        transition: 0.3s;

        &_secondary {
            background: var(--main-titles_secondary);
            color: white;

            &:hover {
                background: #51b69b;
            }
        }

        &_green {
            //background: var(--main-titles);
            background: var(--main-result-success);
            color: white;

            &:hover {
                background: #68ba1e;
            }
        }

        &_lightGreen {
            background: white;
            border: 2px solid #14ca9d;
            color: #14ca9d;

            &:hover {
                background: #14ca9d;
                color: white;
            }
        }

        &_app-light {
            border: 2px solid var(--main-app);
            background: white;
            color: var(--main-app);

            &:hover {
                background: var(--main-app);
                color: white;
            }
        }

        &_lime {
            background: #acdb4f;

            &:hover {
                background: #82a839;
            }
        }

        &_orange {
            background: #ff6300;

            &:hover {
                background: #b94a04;
            }
        }

        &_lightBlue {
            background: var(--main-light-blue);

            &:hover {
                background: #2989a1;
            }
        }

        &_transparent {
            background: none;
            border: 2px solid white;
            color: white;

            &:hover {
                background: white;
                color: black;
            }
        }

        &_paper {
            background: silver;

            &:hover {
                background: var(--main-dark-paper);
            }
        }

        &_app {
            background: var(--main-app);

            &:hover {
                background: #6dc61d;
            }
        }

        &_wrapper {
            margin: 8px 0;

            & a {
                text-decoration: none;
            }
        }
    }
</style>