<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512.014 512.014" style="enable-background:new 0 0 512.014 512.014;" xml:space="preserve">
<g>
	<g>
		<path d="M124.672,280.604c-18.283-17.323-91.392-22.933-113.365-24.235c-3.072-0.043-5.824,0.875-7.957,2.88
			C1.216,261.255,0,264.071,0,267.015v192c0,5.888,4.779,10.667,10.667,10.667h64c4.608,0,8.704-2.987,10.133-7.36
			c1.557-4.779,38.315-117.589,43.157-173.056C128.235,286.023,127.04,282.823,124.672,280.604z M66.88,448.348H21.333V278.45
			c34.283,2.709,71.275,8.597,84.715,15.125C100.395,340.295,74.816,423.303,66.88,448.348z"/>
	</g>
</g>
<g>
	<g>
		<path d="M510.891,411.548c-14.827-29.632-47.616-44.715-79.744-36.672l-81.728,20.437c-5.717,1.429-9.195,7.211-7.765,12.928
			c1.429,5.717,7.211,9.216,12.928,7.765l81.728-20.395c18.645-4.651,37.632,1.877,49.387,16.128l-105.749,48.085
			c-89.685,36.757-92.779,35.584-131.243,21.077l-160-53.333c-5.611-1.856-11.627,1.152-13.483,6.741
			c-1.856,5.589,1.152,11.627,6.741,13.483l159.595,53.184c17.941,6.763,30.315,10.688,44.224,10.688
			c21.632,0,46.976-9.451,102.656-32.299l117.333-53.333c2.624-1.195,4.672-3.413,5.632-6.144
			C512.363,417.138,512.192,414.151,510.891,411.548z"/>
	</g>
</g>
<g>
	<g>
		<path d="M311.637,341.938l-192-42.667c-5.696-1.173-11.456,2.368-12.736,8.107c-1.259,5.76,2.347,11.456,8.107,12.736
			l191.723,42.603c23.637,5.909,33.131,15.211,34.432,34.688l-20.48,5.12c-7.595,1.899-15.659,2.112-23.381,0.555l-92.544-18.517
			c-5.867-1.216-11.392,2.581-12.544,8.363c-1.152,5.781,2.581,11.392,8.363,12.544l92.565,18.517
			c4.8,0.96,9.728,1.429,14.635,1.429c6.101,0,12.224-0.747,18.091-2.219l28.715-7.168c4.757-1.195,8.085-5.461,8.085-10.347v-3.371
			C362.667,369.372,347.029,350.77,311.637,341.938z"/>
	</g>
</g>
<g>
	<g>
		<path d="M360.213,132.21c-2.027-2.453-5.035-3.861-8.213-3.861h-85.333c-3.136,0-6.101,1.387-8.128,3.776
			c-3.925,4.629-96.341,114.176-119.147,173.397c-2.112,5.483,0.619,11.648,6.123,13.781c5.44,2.112,11.648-0.619,13.781-6.123
			c19.243-50.005,95.808-143.595,112.363-163.499h75.307c36.928,45.227,122.368,159.04,122.368,202.667
			c0,10.347-1.109,20.565-3.285,30.357c-1.259,5.76,2.347,11.456,8.107,12.736c0.768,0.171,1.557,0.256,2.325,0.256
			c4.885,0,9.28-3.371,10.389-8.341c2.517-11.307,3.797-23.083,3.797-35.008C490.667,291.655,373.568,148.359,360.213,132.21z"/>
	</g>
</g>
<g>
	<g>
		<path d="M394.667,128.348H224c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667h170.667
			c5.888,0,10.667-4.779,10.667-10.667S400.555,128.348,394.667,128.348z"/>
	</g>
</g>
<g>
	<g>
		<path d="M445.333,14.62c-3.008-3.413-8.683-7.488-18.496-7.488c-21.44,0-55.531,20.736-72,31.659
			c-12.16-17.28-30.187-38.443-45.504-38.443c-15.317,0-33.344,21.163-45.504,38.464c-16.469-10.923-50.56-31.659-72-31.659
			c-9.835,0-15.488,4.075-18.496,7.488c-4.843,5.483-7.04,12.608-6.336,20.608c3.435,39.488,83.861,104.789,93.013,112.107
			c1.877,1.493,4.245,2.325,6.656,2.325H352c2.411,0,4.779-0.832,6.656-2.347c9.152-7.296,89.579-72.597,93.013-112.107
			C452.373,27.228,450.176,20.103,445.333,14.62z M430.4,33.394c-1.941,22.251-50.219,68.949-82.176,94.955h-77.803
			c-31.936-26.005-80.235-72.725-82.176-94.976c-0.256-3.115,0.683-4.181,0.896-4.523c0.171-0.107,0.981-0.384,2.624-0.384
			c15.573,0,50.368,20.779,68.629,33.877c2.411,1.707,5.333,2.325,8.213,1.813c2.88-0.555,5.397-2.24,6.976-4.693
			c12.096-18.731,28.309-36.757,33.429-37.803c5.653,1.045,21.867,19.072,33.984,37.803c1.6,2.453,4.117,4.16,6.976,4.693
			c2.901,0.512,5.845-0.107,8.213-1.813c18.283-13.099,53.077-33.877,68.629-33.877c1.6,0,2.432,0.277,2.496,0.277
			C429.717,29.212,430.677,30.279,430.4,33.394z"/>
	</g>
</g>
<g>
	<g>
		<path d="M311.552,299.25l-2.347-0.448c-21.717-4.011-31.851-10.709-31.851-21.12c0-11.563,14.656-21.333,32.021-21.333
			c16.704,0,31.061,9.067,32,20.181c0.491,5.867,5.803,10.389,11.52,9.728c5.867-0.491,10.219-5.653,9.728-11.52
			c-1.92-22.656-24.811-39.723-53.269-39.723c-29.419,0-53.355,19.136-53.355,42.667c0,21.909,16.576,36.075,51.2,42.453
			l2.325,0.427c21.696,4.011,31.808,10.709,31.808,21.12c0,5.973-3.84,10.453-7.061,13.163c-4.523,3.776-5.12,10.517-1.344,15.019
			c2.112,2.517,5.141,3.819,8.192,3.819c2.411,0,4.843-0.811,6.869-2.475c9.472-7.936,14.699-18.411,14.699-29.504
			C362.688,319.772,346.112,305.628,311.552,299.25z"/>
	</g>
</g>
<g>
	<g>
		<path d="M309.333,213.682c-5.888,0-10.667,4.779-10.667,10.667v117.333c0,5.888,4.779,10.667,10.667,10.667
			S320,347.57,320,341.682V224.348C320,218.46,315.221,213.682,309.333,213.682z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

</template>

<script>
export default {
name: "Step4"
}
</script>

<style scoped>

</style>