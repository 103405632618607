import Vue from 'vue'
import Vuex from 'vuex'
import user from "./modules/user"
import _static from "@/store/modules/static";
import popovers from "@/store/modules/popovers";
import modals from "@/store/modules/modals";
import site_settings from "@/store/modules/site_settings";
import menu from "@/store/modules/menu";
import slicker from "@/store/modules/slicker";
import exchanges from "@/store/modules/exchanges";
import mail from "@/store/modules/mail";
import sidebars from "./modules/sidebars";
import process from "./modules/process"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        _static,
        popovers,
        modals,
        site_settings,
        menu,
        slicker,
        exchanges,
        mail,
        sidebars,
        process,
    }
})
