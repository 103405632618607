<template>
    <div class="heading-wrapper" @mouseenter="destroyTimer" @mouseleave="handleHeader">
        <div class="heading" :class="{'heading-closed': isHeaderClosed, 'heading-filled': $route.name === 'contract'}">
            <div class="heading-body">
                <UserHeader v-if="isAuth" />
                <GuestHeader v-if="!isAuth" />
            </div>
        </div>
    </div>
</template>

<script>
import UserHeader from "./headers/UserHeader";
import GuestHeader from "./headers/GuestHeader";
export default {
    name: "Heading",
    components: {GuestHeader, UserHeader},
    data() {
        return {
            isHeaderClosed: false,
            headerToggleTimer: null
        }
    },
    mounted() {
      console.log('Heading ready');
      console.log(this.user);
    },
    computed: {
        activePopover() {
            return this.$store.getters.activePopover
        },
        isAuth() {
          return this.$store.getters.isAuth
        },
        user() {
          return this.$store.getters.user
        }
    },
    watch: {
        activePopover(val) {
            if (val) {
                this.handleHeader();
            }
        }
    },
    methods: {
        destroyTimer() {
            this.isHeaderClosed = false;
            clearTimeout(this.headerToggleTimer);
        },
        handleHeader() {
            const activePopover = !!this.activePopover;
            const scrollCords = window.scrollY;
            const activeSidebar = !!this.$store.getters.activeSidebar;
            const breakPoint = 760;
            const timeOut = 2000;


            this.destroyTimer();

            if (activePopover || scrollCords < breakPoint || activeSidebar) {
                this.isHeaderClosed = false
            } else {
                this.headerToggleTimer = setTimeout(() => this.isHeaderClosed = true, timeOut);
            }

        }
    },
    created() {
        window.addEventListener('scroll', () => this.handleHeader());
    }
}
</script>

<style lang="scss">
    @import "../../styles/variables";
    @import "../../styles/mixins";
    @import "../../styles/z-indexes";

    .heading {
        height: $standartHeaderHeight;
        background-color: rgba(255,255,255,0.9);
        box-shadow: 2px 2px 20px 0px #c0c0c066;
        width: 100%;
        transition: 0.3s;

        &-filled {
            background: white !important;
        }

        &-wrapper {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            z-index: $headingZindex;
        }

        &-closed {
            transform: translateY(-#{$standartHeaderHeight});
        }

        &-body {
            display: flex;
            align-items: center;
            height: 100%;
        }

        &-logo {
            height: 35px;
        }
    }
</style>