<template>
        <div
            :style="{'border': isOpened ? 'none' : '2px solid #ffffff66'}"
            class="history-sort"
            :class="{'history-sort_opened': isOpened}"
            @click.capture="!isOpened ? isOpened = true : ''"
            v-on-clickaway="away">

                <span>{{ $content(`sort_${selected.sort}`) }}</span>
                <b-icon :icon="isOpened ? 'chevron-up' : 'chevron-down'"></b-icon>
                <div class="history-sort_dd" v-if="isOpened">

                    <h3 v-html="$content('sort_date') + ':'"></h3>
                    <p @click="onSelect({sort: 'date', asc: true})">{{ $content('sort_date_true') }}</p>
                    <p @click="onSelect({sort: 'date', asc: false})">{{ $content('sort_date_false') }}</p>

                    <h3 v-html="$content('sort_balance') + ':'"></h3>
                    <p @click="onSelect({sort: 'balance', asc: true})">{{ $content('sort_balance_true') }}</p>
                    <p @click="onSelect({sort: 'balance', asc: false})">{{ $content('sort_balance_false') }}</p>

                </div>

        </div>

</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
export default {
    name: "HistorySort",
    mixins: [ clickaway ],
    data() {
        return {
            sorts: ['date'],
            selected: null,
            isOpened: false
        }
    },
    methods: {
        onSelect(item) {
            this.selected = item;
            this.isOpened = false;
            this.$emit('sort', item)
        },
        away() {
            this.isOpened = false;
        }
    },
    created() {
        this.selected = {sort: 'date', asc: false};
    }
}
</script>

<style lang="scss" scoped>
    .history-sort {
        border-radius: 200px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px;
        font-family: Montserrat-Medium, sans-serif;
        font-size: 16px;
        color: white;
        min-width: 200px;
        position: relative;

        &_opened {
            background: white;
            border-radius: 20px 20px 0 0;

            & span {
                color: var(--main-light-green);
            }

            & svg {
                color: var(--main-dark) !important;
            }
        }

        &:hover {
            background: white;
            cursor: pointer;

            & span {
                color: var(--main-dark) !important;
            }

            & svg {
                color: var(--main-dark);
            }
        }

        &_dd {
            background: white;
            position: absolute;
            width: 100%;
            left: 0;
            top: 100%;
            border-radius: 0 0 20px 20px;
            color: var(--main-dark);
            padding: 0px 18px 10px 18px;

            & h3 {
                font-size: 16px;
                color: var(--main-orange);
                margin-bottom: 10px;
                margin-top: 10px;
            }

            & p {
                margin-bottom: 0px;
                font-size: 14px;

                &:hover {
                    color: var(--main-light-green);
                }
            }
        }

        & svg {
            font-size: 13px;
            color: white;
            margin-left: 15px;
        }
    }
</style>