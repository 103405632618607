<template>
    <div class="user-avatar">
        <b-avatar :size="size" :src="image"></b-avatar>
    </div>
</template>

<script>
export default {
    name: "Avatar",
    props: ['size'],
    computed: {
        image() {
            return this.imageSelector(this.$store.getters.user.user_photo);
            // return this.imageSelector(this.$store.getters.user.avatar);
        }
    }
}
</script>

<style scoped>

</style>