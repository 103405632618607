<template>
    <Page>
        <div id="services" class="services-page">
            <FullVwContainer>
                <div class="services-page_list">

                <Package @selected="onSelect" :pack="pack" v-for="pack in packages" :key="`serv_${pack.id}`" />

<!--                    <div-->
<!--                        :class="{'services-page_item_bought': pack.status === 'bought'}"-->
<!--                        class="services-page_item"-->
<!--                        v-for="pack in packages"-->
<!--                        :key="`serv_${ pack.name }`">-->
<!--                        <div class="services-page_item_name">-->
<!--                            <div class="services-page_item_name_label">-->
<!--                                {{ pack.name }}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="services-page_item_text" v-html="pack.description"></div>-->
<!--                        <h4 class="services-page_item_price">{{ pack.price }} $</h4>-->
<!--                        <ProjectButton-->
<!--                            v-if="pack.status !== 'bought'"-->
<!--                            style="margin-bottom: 32px"-->
<!--                            @click.native="onSelect(pack)"-->
<!--                            variant="app-light">-->
<!--                            <div class="w-100" v-html="$content('landing_service_button')"></div>-->
<!--                        </ProjectButton>-->
<!--                        <div class="services-page_item_info" v-if="pack.status === 'bought'">-->
<!--                            <h1 v-html="$content('purchased')"></h1>-->
<!--                            <div v-html="pack.user_inv"></div>-->
<!--                        </div>-->
<!--                    </div>-->

                </div>
            </FullVwContainer>
        </div>
        <Footer />
    </Page>
</template>

<script>
import Page from "@/components/Page";
import FullVwContainer from "@/components/Wrappers/FullVwContainer";
import ProjectButton from "@/components/Buttons/ProjectButton";
import Footer from "../../components/Footer";
import Package from "../../components/Package";

export default {
    name: "Shop",
    components: {Package, Footer, ProjectButton, FullVwContainer, Page},
    data() {
        return {
            packages: null
        }
    },
    methods: {
        getPackages() {
            this.$http('shop').getPackages()
                .then(res => this.packages = this.$payloadHandler(res).result)
        },
        onSelect(pack) {
            switch (pack.status) {
                case 'available_universal':
                    this.$modal.open('PayMethod', {id: pack.id})
                    break;
                case 'available_only_wallet':
                    this.$modal.open('PackageBuy', {operation: 'buy', id: pack.id})
                    break;
                case 'available_only_ps':
                    this.$modal.open('Operation', {type: 'buy', id: pack.id, width: '409px',})
                    break;
                case 'not_available':
                    this.$modal.notify({key: 'not_available_package', type: 'fail'})
                    break;
            }
        }
    },
    created() {
        this.getPackages();
        this.$root.$on('packagePurchased', () => this.getPackages());
        this.$root.$on('methodSelected', (e) => {
            console.log(e)
            switch (e.method) {
                case 'PS':
                    this.$modal.open('Operation', {type: 'buy', id: e.id, width: '409px'});
                    break;
                case 'Wallet':
                    this.$modal.open('PackageBuy', {operation: 'buy', id: e.id});
                    break;
            }
        })
    },
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";
    @import "../../styles/variables";

    .services-page {
        background: var(--main-modal), url("/assets/img/services.jpg");
        background-size: cover;
        min-height: calc(100% - #{$standartHeaderHeight});
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Montserrat-Medium, sans-serif;
        padding-bottom: 100px;

        &_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-top: 140px;

            @include only-lg-down {
                gap: 80px;
            }

            @include only-xs {
                padding-top: 40px;
            }
        }
    }
</style>